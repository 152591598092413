<footer>
    <div class="container-fluid p-l-0">
        <div class="row">
            <div class="offset-2 col-lg-10">
                <p>
                <img style="position: relative;"
                width="auto" height="50px;" src="../../../assets/images/salonza/sidebar-logo.png" alt="">
                   &copy; smosify  2023. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>