<!-- ======= Header ======= -->
<header id="header" class="fixed-top ">
  <!-- <div class="container-fluid d-flex align-items-center justify-content-between"> -->
  <div class="container" style="position:relative">
    <!-- <h1 class="logo float-left"><a href="/">Watchfuleye</a></h1> -->
    <!-- Uncomment below if you prefer to use an image logo -->
    <a href="/watchfuleye.in/#/watchfuleye/home" class="logo"><img src="./assets/images/img/logo.png" alt=""
        class="img-fluid"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
            </button>
    <a href="/watchfuleye.in/#/authentication/signin" target="_self" *ngIf="user_data == null"
      class="get-started-btn scrollto float-right">Login</a>
      
    <ul class="nav navbar-nav navbar-right" *ngIf="user_data != null">

        <li class="dropdown user_profile">
            <a href="#" onClick="return false;" data-toggle="dropdown" role="button" class="pt-0">
              <img src={{userImg}} class="rounded-circle" width="32" height="32" alt="User">
            </a>
            <ul class="dropdown-menu" style="margin-top:0px !important;">
              <li class="body">
                <ul class="user_dw_menu">
                  <li>
                    <a (click)="profileEdit()">
                      <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Profile
                    </a>
                  </li>
                  <!-- <li>
                    <a href="#" onClick="return false;">
                      <mat-icon aria-hidden="false" class="mr-2">feedback</mat-icon>Feedback
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick="return false;">
                      <mat-icon aria-hidden="false" class="mr-2">help</mat-icon>Help
                    </a>
                  </li> -->
                  <li>
                    <!-- <a routerLink="/authentication/signin" onClick="return false;">
                      <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                    </a> -->
                    <a (click)="logout()">
                      <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
        </li>
    </ul>

    <nav  class="collapse navbar-collapse nav-menu  d-lg-block float-right" id="navbarSupportedContent">
      <ul>
          <li *ngIf="user_data != null">
              <a style="color: #006a67;" (click)="goToDashboard()">Dashboard</a>
          </li>
        <li><a href="/watchfuleye.in/#/watchfuleye/home" >Home</a></li>
        <li class="drop-down">
          <a href="javascript:void(0);">About us</a>
          <ul>
            <li><a href="/watchfuleye.in/#/watchfuleye/aboutus">About us</a> </li>
            <li><a href="/watchfuleye.in/#/watchfuleye/team">Team</a> </li>
            <li><a href="/watchfuleye.in/#/watchfuleye/advisory-board">Advisory Board</a> </li>
          </ul>
        </li>
        <li class="drop-down">
          <a href="javascript:void(0);">Services</a>
          <ul>
            <li><a href="/watchfuleye.in/#/watchfuleye/healthcare">Healthcare Services</a></li>
            <li><a href="/watchfuleye.in/#/watchfuleye/emergency">Emergency Services</a> </li>
            <li><a href="/watchfuleye.in/#/watchfuleye/convenience">Convenience Services</a></li>
            <li><a href="/watchfuleye.in/#/watchfuleye/volunteer">Volunteers Services</a> </li>
          </ul>
        </li>
        <li><a href="/watchfuleye.in/#/watchfuleye/plan">Plan</a></li>

      </ul>
    </nav><!-- .nav-menu -->


    <!-- <a href="/login/" class="get-started-btn scrollto float-right">Login</a> -->

  </div>
</header><!-- End Header -->
<!-- <div class="container" style="position:relative;">
    <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" href="https://themeforest.net/user/ig_design/portfolio" target="_blank"><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/logo.svg" alt=""></a>	
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
           <ul class="navbar-nav ml-auto py-4 py-md-0">
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4 active">
                 <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Home</a>
                 <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                    <a class="dropdown-item" href="#">Another action</a>
                 </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                 <a class="nav-link" href="#">Portfolio</a>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                 <a class="nav-link" href="#">Agency</a>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                 <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Services</a>
                 <div class="dropdown-menu">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                    <a class="dropdown-item" href="#">Another action</a>
                 </div>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                 <a class="nav-link" href="#">Journal</a>
              </li>
              <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                 <a class="nav-link" href="#">Contact</a>
              </li>
           </ul>
        </div>
     </nav>
</div> -->
<span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=BmRwCW27bLMKrrF3DKHIPSftC25cOphDv04jAH5TY3Pli5kEcldWGqaXOXKc"></script></span>