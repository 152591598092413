<div class="container">
  <!-- <div [innerHTML]="panelData"> -->
    <h3 mat-dialog-title>Close Call</h3>
  <div>
    <!-- <input type="text" > -->
    <form class="m-4" [formGroup]="closeCallForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Group</mat-label>
            <mat-select formControlName="group" required>
              <mat-option [value]="'CONTACTED'">
                CONTACTED
              </mat-option>
              <mat-option [value]="'NOT CONTACTED'">
                NOT CONTACTED
              </mat-option>
            </mat-select>
            <mat-error *ngIf="closeCallForm.get('group').hasError('required')">
              Group is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Disposition</mat-label>
            <mat-select formControlName="disposition">
              <mat-option *ngFor="let disposition of dispositions" [value]="disposition.name">
                {{disposition.displayname}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="closeCallForm.get('disposition').hasError('required')">
              Disposition is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Callback Duration</mat-label>
            <!-- <input matInput formControlName="callBackDuration" placeholder="callback duration"> -->
            <mat-select formControlName="callBackDuration" required>
              <mat-option [value]="'CUSTOM'">
                CUSTOM
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
            <mat-label>Callback Time</mat-label>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <mat-form-field class="example-full-width mb-3">
                  <input matInput [matDatepicker]="picker1" [min]="minDate" formControlName="callBackDate"/>
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <mat-form-field class="example-full-width mb-3">
                  <input matInput formControlName="callBackTime" [ngModel]="strTime" aria-label="default time" [format]="12" [ngxTimepicker]="defaultValue"
                      [value]='strTime'/>
                    <ngx-material-timepicker #defaultValue></ngx-material-timepicker>
                </mat-form-field>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Remarks</mat-label>
            <textarea matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="7" formControlName="remarks" required></textarea>
            <mat-error *ngIf="closeCallForm.get('remarks').hasError('required')">
              Remarks is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
          <button class="mr-3" [disabled]="!closeCallForm.valid " mat-raised-button color="primary">Close Call</button>
        </div>
      </div>
    </form>
  </div>
</div>
