<app-page-loader></app-page-loader>
<!-- <h3 *ngIf="auth.isLogin">{{ message | async | json }}</h3> -->
<!-- Old Idel Code -->
<!-- <ngx-idle-timeout *ngIf="idleTimer && auth.isLogin" [allowedIdleTimeInSeconds]="1740" [message]="message"
    [warningTimeInSeconds]="60"></ngx-idle-timeout> -->

<!-- <app-header *ngIf="!_router.url.includes('/authentication')"></app-header>
<app-sidebar *ngIf="!_router.url.includes('/authentication')"></app-sidebar> -->
<app-header *ngIf="auth.isLogin && partnerRole =='partner' || partnerRole =='admin'"></app-header>

<app-sidebar *ngIf="auth.isLogin && isAdminVerfiedOrNot == true && isDeletedOrNot == !true && partnerRole =='partner'">
</app-sidebar>

<app-sidebar *ngIf="auth.isLogin && isAdminVerfiedOrNot == true && isDeletedOrNot == true && partnerRole == 'admin'">
</app-sidebar>
<!-- <app-watchfulheader *ngIf="(_router.url.includes('/watchfuleye')) || (!auth.isLogin)"></app-watchfulheader> -->
<app-watchfulheader *ngIf="(_router.url.includes('/watchfuleye')) "></app-watchfulheader>

<!-- staff login changes -->
<!-- <app-right-sidebar></app-right-sidebar> -->
<app-header *ngIf="auth.isLogin "></app-header>
<app-sidebar  *ngIf="auth.isLogin "></app-sidebar>
<router-outlet></router-outlet>
<!-- <div *ngIf="message;">
    <h1>{{ message.notification.title }}</h1>
    <h2>{{ message.notification.body }}</h2>
</div>
<div *ngIf="!message;">
    No Message Arrived
</div> -->
<app-footer *ngIf="auth.isLogin && partnerRole =='partner' || partnerRole =='admin'"></app-footer>
<!-- <app-watchfulfooter *ngIf="_router.url.includes('/watchfuleye/')"></app-watchfulfooter> -->


<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h6 id="dialog-child-name" class="modal-title">You Have Been Idle More Than 15 Min!!!</h6>
                <button type="button" data-bs-dismiss="modal" class="btn-close close pull-right" aria-label="Close"
                    (click)="hideChildModal()">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{idleState}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-toggle="button" aria-pressed="false"
                    autocomplete="off" (click)="logout()" data-bs-dismiss="modal">Logout</button>
                <button type="button" class="btn btn-success" data-toggle="button" aria-pressed="false"
                    autocomplete="off" (click)="stay()">Stay</button>
            </div>
        </div>
    </div>
</div>