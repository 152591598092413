<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-4 col-md-6">
          <div class="footer-info">
            <h3>Watchfuleye</h3>
            <!-- <p>B - 25, First Floor,
                Okhla Industrial Area, <br>
                Phase-2, New Delhi - 110020, INDIA</p>
            <p> -->
            <p>
              B - 25, First Floor, Okhla Industrial Area, <br>
              Phase-2, New Delhi - 110020, INDIA<br><br>
              <strong>Phone:</strong> 011-46854999<br>
              <strong>Email:</strong> info@watchfuleye.in<br>
            </p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li> -->
            <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/aboutus">About us</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="service-detail.html">Services</a></li> -->
            <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/volunteer">Volunteers</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/blog">Blogs</a></li> -->
            
            <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/contactus">Contact us</a></li>
            <!-- <li><i class="bx bx-chevron-right"></i> <a href="products.html">Products</a></li> -->
            <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/privacy-policy">Privacy Policy</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/watchfuleye.in/#/watchfuleye/career">Career</a></li>
          </ul>
        </div>

        <div class="col-lg-4 col-md-6 footer-links">
          <h4>Download App</h4>
          <!-- <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul> -->
          <a href="#"><span class="appDownload googleplay"></span></a>
          <a href="#"><span class="appDownload appStore"></span></a>
        </div>

        <div class="col-lg-4 col-md-6 footer-newsletter" style="display: none;">
          <h4>Our Newsletter</h4>
          <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
          <form action="" method="post">
            <input type="email" name="email"><input type="submit" value="Subscribe">
          </form>

        </div>

      </div>
    </div>
  </div>

  <div class="container">
    <div class="copyright">
      &copy; Copyright <strong><span>Watchfuleye</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
    </div>
  </div>
</footer><!-- End Footer -->
