import { Role } from "./role";
import { MemberUser, User } from "./user";
import { Response } from "./response";
import { Injectable } from "@angular/core";
import { of, throwError, BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public partnerSubject: BehaviorSubject<any>;
  public user: Observable<User>;
  // Create Subject To Detect The User Is Login Or Not
  private userLoggedIn = new Subject<boolean>();
  headers = new HttpHeaders().set("Content-Type", "application/json");
  isLogin = false;
  isLoginMember = false;
  roleAs: string;
  // users: User[] = [
  //   {
  //     id: 1,
  //     img: "assets/images/user/admin.jpg",
  //     username: "admin",
  //     password: "admin",
  //     mobile: "1234567891",
  //     email: "a@a.com",
  //     firstName: "Sarah",
  //     lastName: "Smith",
  //     role: Role.Admin,
  //     message: "",
  //     status: "",
  //   },
  //   {
  //     id: 2,
  //     img: "assets/images/user/doctor.jpg",
  //     username: "doctor",
  //     password: "doctor",
  //     mobile: "1234567891",
  //     email: "a@a.com",
  //     firstName: "Ashton",
  //     lastName: "Cox",
  //     role: Role.Doctor,
  //     message: "",
  //     status: "",fv
  //   },
  //   {
  //     id: 3,
  //     img: "assets/images/user/patient.jpg",
  //     username: "patient",
  //     password: "patient",
  //     mobile: "1234567891",
  //     email: "a@a.com",
  //     firstName: "Cara",
  //     lastName: "Stevens",
  //     role: Role.Patient,
  //     message: "",
  //     status: "",
  //   },
  // ];

  constructor(private http: HttpClient, private router: Router) {
    try {
      this.userSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem("user"))
      );
      this.partnerSubject = new BehaviorSubject<any>(
        JSON.parse(localStorage.getItem("user"))
      );
      this.user = this.userSubject.asObservable();

      this.userLoggedIn.next(false);
    }
    catch (ex) {
      localStorage.clear();
      this.userLoggedIn.next(false);
    }
  }

  // Fuction Expression, Recieveing Value On Login/onSubmit From Partner, Admin, Staff.
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  // Fuction Expression Get/Recieveing Value/Type On Login/onSubmit As Observable.
  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  login(username: string, password: string) {
    return this.http
      .post<User>(`${environment.apiUrl}/user/login`, { username, password })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("USERIMG", user.profileimage);
            const lastName = user.lastName != null && user.lastName != undefined ? user.lastName : "";
            localStorage.setItem(
              "FULLNAME",
              user.firstName + " " + lastName
            );
            this.isLogin = true;
            this.userSubject.next(user);
            return { success: this.isLogin, role: this.roleAs, user: user };
          }
          else return { success: false };

        })
      );

    // const user = this.users.find(
    //   (x) => x.username === uname && x.password === pwd
    // );

    // if (user) {
    //   this.roleAs = user.role;
    //   localStorage.setItem("STATE", "true");
    //   localStorage.setItem("ROLE", user.role);
    //   localStorage.setItem("USERIMG", user.img);
    //   localStorage.setItem("FULLNAME", user.firstName + " " + user.lastName);
    //   this.isLogin = true;
    // } else {
    //   this.roleAs = "";
    //   this.isLogin = false;
    //   localStorage.setItem("STATE", "false");
    // }
    // return of({ success: this.isLogin, role: this.roleAs });
  }

  verifyOtp(mobile: string, otp: number) {
    return this.http
      .post<Response>(`${environment.apiUrl}/user/verifyOtp`, { mobile, otp })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  verifyStaffOtp(mobile: string, otp: number) {
    return this.http
      .post<Response>(`${environment.apiUrl}/staff/verifyOtp`, { mobile, otp })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  verifyOtpPartner(mobile: string, otp: number) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/verifyOtp`, { mobile, otp, oldNum: mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Verifing OTP Member During SignUp.
  verifyOtpMember(mobile: any, otp: any) {
    return this.http.post<Response>(`${environment.apiUrl}/member/verifyOtpApp`, { mobile: mobile, email: otp }).pipe(map((res) => {
      console.log(res);
      if (res.status == "error")
        return { success: false, message: res.message };
      else return { success: true, message: res.message };
    })
    );
  }

  verifyOtpPartnerForMail(email: string, otp: number, mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/verifyOtpEmail`, { email, otp, mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  resendOTP(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/user/resendOTP`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  PartnersendOtpLogin(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/sendOtpLogin`, { mobile: mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else {
            localStorage.setItem("p_email", res.data);
            return { success: true, message: res.message };
          }
        })
      );
  }

  forgotPasswordOTP(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/forgotPasswordOTP`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  forgotPasswordOTPs(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/staff/forgotPasswordOTP`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  resendOTPStaff(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/staff/resendOTP`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  resendOTPPartner(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/resendOTP`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member During SignUp.
  resendOTPMember(mobile: string, email: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/resendOtpMember`, { mobile, email })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member During SignUp.
  loginViaOTPEmail(email: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/loginViaOtp`, { email })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member During SignUp.
  loginViaOTPMobile(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/loginViaOtp`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member Email During Forget Password Verify.
  resendOTPMemberDuringForgetVerifyOTPEmail(email: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/resendOtpMemberEmail`, { email })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member Email During Forget Password Verify.
  resendOtpMemberUpdateWhenTryWithLoginVia(email: any, mobile: any) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/resendOtpMemberUpdate`, { email: email, mobile: mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Resending For Member Mobile During Forget Password Verify.
  resendOTPMemberDuringForgetVerifyOTPMobile(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/resendOTPMemberMobile`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Setting New Password For Member Through Mail. 
  setPasswordForMemberMail(email, password) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/changeUserPasswordEmail`, { email: email, password: password })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Setting New Password For Member Through Mobile.
  setPasswordForMemberMobile(mobile, password) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/changeUserPasswordMobile`, { mobile: mobile, password: password })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Sending For Member Forget Password Email.
  ForgetPasswordOTPForEmail(email: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/resendOtpMemberEmail`, { email })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is OTP Sending For Member Forget Password Mobile.
  ForgetPasswordOTPForMobile(mobile: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/sendOtpMobileMember`, { mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Verify OTP For Email Forget Password.
  verifyOtpMemberForForgetPasswordEmail(email: object) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/verifyOtpEmailClient`, { data: email })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Verify OTP For Mobile Forget Password.
  verifyOtpMemberForForgetPasswordMobile(mobile: object) {
    return this.http
      .post<Response>(`${environment.apiUrl}/member/verifyOtpMobile`, { data: mobile })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // Here Is Verify OTP And Login For Mobile Login Via OTP.
  verifyOtpMemberForLoginViaOTPMobile(mobile: object) {
    return this.http
      .post<User>(`${environment.apiUrl}/member/VerifyOtpMobileLoginViaOtp`, { mobile: mobile['mobile'], otp: mobile['otp'] })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("member", JSON.stringify(user));
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("MEMBER-STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("MEMBERIMG", user.profileimage);
            localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
            // this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            return {
              success: true,
              role: this.roleAs,
              user: user
            };
          }
          else return {
            success: false,
            message: user.message
          };
        })
      );
  }

  // Here Is Verify OTP And Login For Email Login Via OTP.
  verifyOtpMemberForLoginViaOTPEmail(email: object) {
    return this.http
      .post<User>(`${environment.apiUrl}/member/VerifyOtpEmailLoginViaOtp`, { email: email['email'], otp: email['otp'] })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("member", JSON.stringify(user));
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("MEMBER-STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("MEMBERIMG", user.profileimage);
            localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
            // this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            return {
              success: true,
              role: this.roleAs,
              user: user
            };
          }
          else return {
            success: false,
            message: user.message
          };
        })
      );
  }

  resendOTPPartnerForMail(email: string, partnerName: string) {
    return this.http
      .post<Response>(`${environment.apiUrl}/partner/resendOTPEmail`, { email, partnerName })
      .pipe(
        map((res) => {
          console.log(res);
          if (res.status == "error")
            return { success: false, message: res.message };
          else return { success: true, message: res.message };
        })
      );
  }

  // adminlogin(username: string, password: string) {
  //   return this.http
  //     .post<User>(`${environment.apiUrl}/admin/login`, { username, password })
  //     .pipe(
  //       map((user) => {
  //         if (user.status == 'success') {
  //           this.roleAs = user.role;
  //           localStorage.setItem("user", JSON.stringify(user));
  //           localStorage.setItem("STATE", "true");
  //           localStorage.setItem("ROLE", user.role);
  //           localStorage.setItem("USERIMG", user.profileimage);
  //           localStorage.setItem(
  //             "FULLNAME",
  //             user.firstName + " " + user.lastName
  //           );
  //           this.isLogin = true;
  //           this.userSubject.next(user);
  //           return { success: this.isLogin, role: this.roleAs, user: user };
  //         }
  //         else return { success: false };

  //       })
  //     );
  // }

  staffLogin(username: string, password: string) {
    return this.http
      .post<User>(`${environment.apiUrl}/staff/login`, { username, password })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("USERIMG", user.profileimage);
            localStorage.setItem(
              "FULLNAME",
              user.firstName + " " + user.lastName
            );
            this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            this.userSubject.next(user);
            return { success: true, role: this.roleAs, user: user };
          } else if (user.status == 'not_admin_verified') {
            return { success: 'not_admin_verified' }
          }
          else return { success: false };

        })
      );
  }

  getPartnerCurrencySettings(country = "India") {

    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    return this.http.post(`${environment.apiUrl}/partner/getCurrencyData`, { country: country }, { headers: headers })

  }

  partnerLogin(username: string, password: string) {
    return this.http.post<User>(`${environment.partnerapiUrl}/partner/loginPartner`, { username, password }).pipe(map((user) => {
      if (user.status == 'success') {
        this.roleAs = user.role;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("STATE", "true");
        localStorage.setItem("ROLE", user.role);
        localStorage.setItem("USERIMG", user.profileimage);
        localStorage.setItem(
          "FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName
          //  user.firstName + " " + user.lastName
        );
        this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
        let myCountry = user.currentlocation && user.currentlocation.country ? user.currentlocation.country : "India"
        this.getPartnerCurrencySettings(myCountry).subscribe({
          next: (result: any) => {
            let currencydata = result.data[0];
            localStorage.setItem("partnerCurrency", JSON.stringify(currencydata));
          }
        });
        this.userSubject.next(user);
        this.partnerSubject.next(user);
        return { success: true, role: this.roleAs, user: user };
      }
      else if (user.status == 'not_admin_verified') {
        return { success: 'not_admin_verified' }
      } else if (user.status == 'varified_partner_Deleted') {
        return { success: 'varified_partner_Deleted' }
      }
      else if (user.status == "email_notVerified") {
        return { success: 'email_notVerified' }
      }
      else if (user.status == "mobile_notVerified") {
        return { success: 'mobile_notVerified' }
      }
      else return { success: false };
    })
    );
  }

  // When We Login From Admin Side.
  partnerLoginFromAdmin(obj: any) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    return this.http.post<User>(`${environment.partnerapiUrl}/partner/loginPartnerfromAdmin`, { mobileNo: obj.mobile }, { headers: headers }).pipe(map((user) => {
      if (user.status == 'success') {
        this.roleAs = user.role;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("STATE", "true");
        localStorage.setItem("ROLE", user.role);
        localStorage.setItem("USERIMG", user.profileimage);
        localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
        this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
        let myCountry = user.currentlocation && user.currentlocation.country ? user.currentlocation.country : "India"
        this.getPartnerCurrencySettings(myCountry).subscribe({
          next: (result: any) => {
            let currencydata = result.data[0];
            localStorage.setItem("partnerCurrency", JSON.stringify(currencydata));
          }
        });
        this.userSubject.next(user);
        this.partnerSubject.next(user);
        return { success: true, role: this.roleAs, user: user };
      }
      else if (user.status == 'not_admin_verified') {
        return { success: 'not_admin_verified' }
      } else if (user.status == 'varified_partner_Deleted') {
        return { success: 'varified_partner_Deleted' }
      }
      else if (user.status == "email_notVerified") {
        return { success: 'email_notVerified' }
      }
      else if (user.status == "mobile_notVerified") {
        return { success: 'mobile_notVerified' }
      }
      else return { success: false };
    })
    );
  }

  // When We Login From Admin Side.
  partnerLoginAfterOTPVerify(obj: any) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    return this.http.post<User>(`${environment.partnerapiUrl}/partner/loginPartnerOnSignUp`, { mobile: obj.mobile }, { headers: headers }).pipe(map((user) => {
      if (user.status == 'success') {
        this.roleAs = user.role;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("STATE", "true");
        localStorage.setItem("ROLE", user.role);
        localStorage.setItem("USERIMG", user.profileimage);
        localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
        this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
        let myCountry = user.currentlocation && user.currentlocation.country ? user.currentlocation.country : "India"
        this.getPartnerCurrencySettings(myCountry).subscribe({
          next: (result: any) => {
            let currencydata = result.data[0];
            localStorage.setItem("partnerCurrency", JSON.stringify(currencydata));
          }
        });
        this.userSubject.next(user);
        this.partnerSubject.next(user);
        return { success: true, role: this.roleAs, user: user };
      }
      else if (user.status == 'not_admin_verified') {
        return { success: 'not_admin_verified' }
      } else if (user.status == 'varified_partner_Deleted') {
        return { success: 'varified_partner_Deleted' }
      }
      else if (user.status == "email_notVerified") {
        return { success: 'email_notVerified' }
      }
      else if (user.status == "mobile_notVerified") {
        return { success: 'mobile_notVerified' }
      }
      else return { success: false };
    })
    );
  }

  adminlogin(username: string, password: string) {
    return this.http
      .post<User>(`${environment.apiUrl}/admin/login`, { username, password })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("USERIMG", user.profileimage);
            localStorage.setItem(
              "FULLNAME",
              user.firstName + " " + user.lastName
            );
            this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            let myCountry = user.currentlocation && user.currentlocation.country ? user.currentlocation.country : "India"

            this.getPartnerCurrencySettings(myCountry)
              .subscribe({
                next: (result: any) => {

                  let currencydata = result.data[0];
                  localStorage.setItem("partnerCurrency", JSON.stringify(currencydata));
                }
              });
            // this.isLogin = true;
            this.userSubject.next(user);
            this.partnerSubject.next(user);
            return { success: this.isLogin, role: this.roleAs, user: user };
          }
          else if (user.status == 'not_admin_verified') {
            return { success: 'not_admin_verified' }
          } else if (user.status == 'varified_partner_Deleted') {
            return { success: 'varified_partner_Deleted' }
          }
          else if (user.status == "email_notVerified") {
            return { success: 'email_notVerified' }
          }
          else if (user.status == "mobile_notVerified") {
            return { success: 'mobile_notVerified' }
          }
          else return { success: false };

        })
      );
  }


  // This is for gmail login 

  socialLoginPartner(ltype: string, username: string, gid: string) {
    return this.http
      .post<User>(`${environment.partnerapiUrl}/partner/socialLoginPartner`, { ltype, username, gid })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("USERIMG", user.profileimage);
            localStorage.setItem(
              "FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName
              //  user.firstName + " " + user.lastName
            );
            this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            this.userSubject.next(user);
            this.partnerSubject.next(user);
            return { success: true, role: this.roleAs, user: user };
          }
          else if (user.status == 'not_admin_verified') {
            return { success: 'not_admin_verified' }
          } else if (user.status == 'varified_partner_Deleted') {
            return { success: 'varified_partner_Deleted' }
          }
          else if (user.status == "email_notVerified") {
            return { success: 'email_notVerified' }
          }
          else if (user.status == "mobile_notVerified") {
            return { success: 'mobile_notVerified' }
          }
          else return { success: false };
        })
      );
  }

  // This is for gmail login 

  socialLogin(ltype: string, username: string, gid: string) {
    return this.http
      .post<User>(`${environment.apiUrl}/member/socialLogin`, { ltype, username, gid })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("member", JSON.stringify(user));
            localStorage.setItem("MEMBER-STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("MEMBERIMG", user.profileimage);
            localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
            this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            return { success: true, role: this.roleAs, user: user };
          }
          else return { success: false };
        })
      );
  }

  // This Is For Login Member.
  memberLogin(username: string, password: string) {
    return this.http
      .post<User>(`${environment.apiUrl}/member/loginMember`, { username, password })
      .pipe(
        map((user) => {
          if (user.status == 'success') {
            this.roleAs = user.role;
            localStorage.setItem("member", JSON.stringify(user));
            localStorage.setItem("MEMBER-STATE", "true");
            localStorage.setItem("ROLE", user.role);
            localStorage.setItem("MEMBERIMG", user.profileimage);
            localStorage.setItem("FULLNAME", user.lastName ? user.firstName + " " + user.lastName : user.firstName);
            this.isLogin = user.message == "Otp Sent to your Mobile Number!" ? false : true;
            return { success: true, role: this.roleAs, user: user };
          }
          else return { success: false };
        })
      );
  }

  signup(user: User) {
    user.firstName = user.username.split(" ")[0];
    user.lastName = user.username.split(" ")[1];
    console.log(user);
    console.log(`${environment.apiUrl}`);
    return this.http.post<Response>(`${environment.apiUrl}/user/createUser`, { user }).pipe(
      map((res) => {
        console.log(res);
        if (res.status == "error")
          return { success: false, message: res.message };
        else return { success: true, message: res.message };
      })
    );;
  }

  // This Is For SignUp Partner.
  signupPartner(partner: User) {
    partner.firstName = partner.username.split(" ")[0];
    partner.lastName = partner.username.split(" ")[1];
    // console.log(partner);
    // console.log(`${environment.apiUrl}`);
    return this.http.post<Response>(`${environment.apiUrl}/partner/createPartner`, { partner }).pipe(
      map((res) => {
        // console.log(res);
        if (res.status == "error")
          return { success: false, message: res.message };
        else return { success: true, message: res.message };
      })
    );;
  }

  // This Is For SignUp Member.
  signupMember(member: MemberUser) {
    member.firstName = member.firstName;
    member.lastName = member.lastName;
    member.DOB = member.date;
    member.gender = member.gender;
    member.mobile = member.mobile;
    member.email = member.email;
    member.password = member.password;
    member.confirmpassword = member.cpassword;

    return this.http.post<Response>(`${environment.apiUrl}/member/createUserApp`, { data: member }).pipe(map((res) => {
      if (res.status == "error")
        return { success: false, message: res.message };
      else return { success: true, message: res.message };
    })
    );
  }

  setPartnerPassword(username, password) {

    return this.http.post<Response>(`${environment.apiUrl}/partner/setPartnerPassword`, { username, password }).pipe(
      map((res) => {
        console.log(res);
        if (res.status == "error")
          return { success: false, message: res.message };
        else return { success: true, message: res.message };
      })
    );;
  }
  getAllIsPublishedPartner(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.http.post(`${environment.apiUrl}/partner/isPublishedPartner`, { id: id }, { headers: headers })
  }
  // getAllunPublishedPartner(id) {
  //   let headers = new HttpHeaders({
  //     'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
  //   })
  //   return this.http.post(`${environment.apiUrl}/partner/unPublishedPartner`, { id:id }, { headers: headers })
  // }
  getPartnerDataId(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.http.post(`${environment.apiUrl}/partner/getPartnerDataId`, { id: id }, { headers: headers })
  }
  setStaffPassword(username, password) {

    return this.http.post<Response>(`${environment.apiUrl}/staff/setStaffPassword`, { username, password }).pipe(
      map((res) => {
        console.log(res);
        if (res.status == "error")
          return { success: false, message: res.message };
        else return { success: true, message: res.message };
      })
    );;
  }

  logout() {
    this.isLogin = false;
    this.roleAs = "";
    localStorage.setItem("STATE", "false");
    localStorage.setItem("ROLE", "");
    localStorage.setItem("FULLNAME", "");
    localStorage.setItem("USERIMG", "");
    localStorage.setItem("user", null);
    return of({ success: this.isLogin, role: "" });
  }

  // When We Click On Logout Button.
  logoutMember() {
    this.isLoginMember = false;
    this.roleAs = "";
    localStorage.setItem("MEMBER-STATE", "false");
    localStorage.setItem("ROLE", "");
    localStorage.setItem("FULLNAME", "");
    localStorage.setItem("MEMBERIMG", "");
    localStorage.removeItem("member");
    localStorage.removeItem("m_mobile");
    localStorage.removeItem("id");
    sessionStorage.removeItem("count");
    sessionStorage.removeItem("userAddress")
    sessionStorage.removeItem("count")
    return of({ success: this.isLoginMember, role: "" });
  }

  isLoggedIn() {
    const loggedIn = localStorage.getItem("STATE");
    if (loggedIn === "true") {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    return this.isLogin;
  }

  isLoggedInForMember() {
    const loggedIn = localStorage.getItem("MEMBER-STATE");
    if (loggedIn === "true") {
      this.isLoginMember = true;
    } else {
      this.isLoginMember = false;
    }
    return this.isLoginMember;
  }

  // Refresh Token And Stored In localStorage User Object
  tokenRefresh(mobile) {
    return this.http.post<any>(`${environment.apiUrl}/app/getNewTokenUser`, { mobile: mobile }).pipe(
      map((res) => {
        console.log(res);
        if (res.status == "success") {
          let token = res
          // create object Variable
          let localData = {};
          // Stored Local Storage Data Into Object Type Variable
          localData = JSON.parse(localStorage.getItem('user'))
          // Add New Refresh Token Into LocalStorage
          localData['token'] = res.token;
          localStorage.setItem('user', JSON.stringify(localData))
        }
        else if (res.status == "error") {
          console.log(res.message);
        }
      })
    );;
  }

  getRole() {
    return localStorage.getItem("ROLE");
  }

  getUserFullName() {
    return localStorage.getItem("FULLNAME");
  }

  getUserImg() {
    return `${environment.bucketUrl}` + localStorage.getItem("USERIMG");
  }

  getUserId() {
    return localStorage.getItem("user") != null && localStorage.getItem("user") != "" ? (JSON.parse(localStorage.getItem("user")))._id : null;
  }

  activatePartner(mob) {
    return this.http.post(`${environment.apiUrl}/partner/activeStatusPartner`, { mobile: mob }, {});
  }
}
