import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
//import { FormBuilder, FormGroup, Validators,FormArray } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "app-call-dial",
  templateUrl: "./call-dial.component.html",
  styleUrls: ["./call-dial.component.sass"],
})
export class CallDialComponent implements OnInit {
  callDialForm: FormGroup;
  // callType: 'conference' | 'manual' = 'manual';
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<CallDialComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.callDialForm = this.fb.group({
      mobile: ["", Validators.required],
      callType: ["manual"],
    });
  }

  ngOnInit(): void {}

  onSubmit() {
    let userData = JSON.parse(localStorage.getItem("user"));
    console.log(this.callDialForm.value);
    localStorage.setItem("callType", this.callDialForm.value.callType);
    localStorage.setItem("manualCall", this.callDialForm.value.mobile);
    this.dialogRef.close(this.callDialForm.value);
  }
}
