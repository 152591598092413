import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
//import { FormBuilder, FormGroup, Validators,FormArray } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
@Component({
  selector: "app-call-dialog",
  templateUrl: "./call-dialog.component.html",
  styleUrls: ["./call-dialog.component.sass"],
})
export class CallDialogComponent implements OnInit {
  dataSource = {};
  initLine: string;
  panelData: string;
  trustedUrl: any;
  callForm: FormGroup;
  resultCode: any;
  showForm: any;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<CallDialogComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.callForm = this.fb.group({
      terminal: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.dataSource = this.data;
    console.log(this.dataSource);
    localStorage.setItem("crmUrl", null);
    this.initLine = this.data.initLine;
    let userData = JSON.parse(localStorage.getItem("user"));
    // this.httpClient.get(`http://122.176.29.12:9001/panel?agent_login_id=9811635908&agent_terminal_id=4001`,{responseType: 'text'}).subscribe(
    //   data => {
    //     console.log(data);
    //     //this.panelData = data;
    //   },
    //   (error: HttpErrorResponse) => {
    //     console.log(error.name + ' ' + error.message);
    //   }
    // );

    // this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://122.176.29.12:9001/panel?agent_login_id=9811635908&agent_terminal_id=4001');
    //  this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`http://122.176.29.12:9001/panel?agent_login_id=${userData.mobile}&agent_terminal_id=4001`);
    console.log(this.trustedUrl);

    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)
          this.resultCode = result.ResultCode;
          if (this.resultCode == "-1") {
            this.showForm = true;
            localStorage.setItem("userTerminalId", " ");
          } else {
            this.showForm = false;
            let terminalData = localStorage.getItem("userTerminalId");
            if (terminalData == null || terminalData == "") {
              this.showForm = true;
            } else {
              this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${terminalData}`
              );
              localStorage.setItem(
                "crmUrl",
                `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${terminalData}`
              );
            }
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    localStorage.setItem("headerCall", "true");
  }
  onSubmit() {
    let userData = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("userTerminalId", this.callForm.value.terminal);
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${this.callForm.value.terminal}`
    );
    localStorage.setItem(
      "crmUrl",
      `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${this.callForm.value.terminal}`
    );
    console.log(this.trustedUrl);
  }
}
