<div class="addContainer paylink">
    <div mat-dialog-content>
        <div class="d-flex" *ngIf="checkoutscreen">
            <div class="col-lg-8">
                <div *ngIf="addServiceScreen">
                    <h3>Booking Details</h3>
                    <mat-card style="display:flex;flex-direction:row;justify-content: space-between;"
                        class="example-card" *ngFor="let item of checkoutData.eventArr; let i=index ">
                        <mat-card-header>
                            <mat-card-title style="text-transform: capitalize;">{{i+1}}. {{item.subServiceName ?
                                item.subServiceName : item.packageName}}</mat-card-title>
                            <mat-card-subtitle>{{item.duration}} min with {{item.staffName}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <!-- flex-wrap:nowrap; justify-content: flex-start;display: flex; -->
                            <ul class="flex"
                                style="flex-direction:row;justify-content: flex-start;display: flex;width:100%; ">

                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput [(ngModel)]="item.price" readonly>
                                    </mat-form-field>
                                </li>
                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Staff</mat-label>
                                        <input matInput [(ngModel)]="item.staffName" readonly>
                                    </mat-form-field>
                                </li>


                            </ul>

                        </mat-card-content>
                        <!-- <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                        </mat-card-actions> -->
                    </mat-card>
                    <hr>
                    <div class="row">
                        <div class="col-lg-4">
                            <!-- <a href="#">Add new service</a> -->
                        </div>
                        <div class="col-lg-8">
                            <ul class="">
                                <li
                                    style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Total <span class="pull-right"> {{checkoutData.totalCost}}</span>
                                </li>
                                <!-- <li 
                                style="font-size:18px; color:#766326; padding:10px 5px;
                                border-bottom:1px solid #ccc" (click)="tipFun()">
                                    Tip <span class="pull-right">{{balance}}</span>
                                </li> -->
                                <!-- <li *ngIf="tipForm">
                                    <form [formGroup]="checkoutForm">
                                        <div  formArrayName="serviceForm"
                                        *ngFor="let creds of checkoutForm.controls.serviceForm['controls']; let i = index">
                                            <div [formGroupName]="i" >
                                               <div style="display:flex;flex-direction:row;">
                                                <mat-form-field class="example-full-width mb-0" appearance="outline">
                                                    <mat-label>Staff</mat-label>
                                                    
                                               
                                                <mat-select formControlName="staff" 
                                                required>
                                                <mat-option *ngFor="let cat of staffData" 
                                                [value]="cat._id">
                                                  {{cat.staffName}}
                                                </mat-option>
                                  
                                              </mat-select>
                                            </mat-form-field>
                                                <mat-form-field class="example-full-width mb-0" appearance="outline">
                                                    <mat-label>Tip Amount</mat-label>
                                                    <input matInput formControlName="price"  >
                                                </mat-form-field>
        
                                                <button type="button" *ngIf="i>0" mat-mini-fab color="warn"
                                                    (click)="remoTime(i)" matSuffix>
                                                    <mat-icon>remove_circle_outline</mat-icon>
                                                </button>
                                                <button type="button" mat-mini-fab color="primary" 
                                                *ngIf="i==checkoutForm.controls.serviceForm['controls'].length-1"
                                                (click)="addTime(i)"
                                                    matSuffix>
                                                    <mat-icon>add_circle_outline</mat-icon>
                                                </button>
                                               
                                                </div>
                                               
                                            </div>
                                        </div>
                                        <button mat-raised-button class="example-full-width "
                                        type="submit" (click)="tipSave()"
                                       ><span class="2x">Save</span></button>
                                    </form>
                                </li> -->
                                <li *ngIf="voucherAddedRow" style="font-size:18px; color:#766326; padding:10px 5px;
                                border-bottom:1px solid #ccc">
                                    Redeem Voucher Amount <span class="pull-right">{{redeemAmount}}</span>
                                </li>
                                <li *ngIf="MembershipAddedRow" style="font-size:18px; color:#766326; padding:10px 5px;
                                border-bottom:1px solid #ccc">
                                    Redeem Membership Amount <span class="pull-right">{{serviceCost}}</span>
                                </li>
                                <li *ngIf="coinsAddedRow" style="font-size:18px; color:#766326; padding:10px 5px;
                                border-bottom:1px solid #ccc">
                                    Redeem Coins Amount <span class="pull-right">{{Coins}}</span>
                                </li>
                                <li
                                    style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Balance <span class="pull-right"> {{newTotal}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="addVoucherScreen">
                    <h3>Voucher Details</h3>
                    <mat-card style="display:flex;flex-direction:row;justify-content: space-between;"
                        class="example-card" *ngFor="let item of checkoutData.voucherArray; let i=index ">
                        <mat-card-header>
                            <mat-card-title style="text-transform: capitalize;">{{i+1}}. {{item.name}}</mat-card-title>
                            <mat-card-subtitle>{{item.validForMonths}} Days Validity on all {{item.voucherType}}
                            </mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <!-- flex-wrap:nowrap; justify-content: flex-start;display: flex; -->
                            <ul class="flex"
                                style="flex-direction:row;justify-content: flex-start;display: flex;width:100%; ">

                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput [(ngModel)]="item.retailPrice" readonly>
                                    </mat-form-field>
                                </li>
                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Voucher Type</mat-label>
                                        <input matInput [(ngModel)]="item.voucherType" readonly>
                                    </mat-form-field>
                                </li>
                            </ul>
                        </mat-card-content>
                    </mat-card>
                    <hr>
                    <div class="row">
                        <div class="col-lg-4">
                            <!-- <a href="#">Add new service</a> -->
                        </div>
                        <div class="col-lg-8">
                            <ul class="">
                                <li
                                    style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Total <span class="pull-right"> {{checkoutData.totalCost}}</span>
                                </li>
                                <!-- <li style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Balance <span class="pull-right"> {{newTotal}}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="addProductScreen">
                    <h3>Product Details</h3>
                    <mat-card style="display:flex;flex-direction:row;justify-content: space-between;"
                        class="example-card" *ngFor="let item of checkoutData.productArray; let i=index ">
                        <mat-card-header>
                            <mat-card-title style="text-transform: capitalize;">{{i+1}}. {{item.productName}}
                            </mat-card-title>
                            <mat-card-subtitle>Brand: {{item.brand}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <!-- flex-wrap:nowrap; justify-content: flex-start;display: flex; -->
                            <ul class="flex"
                                style="flex-direction:row;justify-content: flex-start;display: flex;width:100%; ">

                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput [(ngModel)]="item.retailPrice" readonly>
                                    </mat-form-field>
                                </li>
                                <!-- <li class="mr-2">
                                
                                <mat-form-field class="example-full-width " appearance="outline">
                                    <mat-label></mat-label>
                                    <input matInput [(ngModel)]="item.voucherType" readonly >
                                </mat-form-field>
                            </li>  -->


                            </ul>

                        </mat-card-content>
                        <!-- <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                        </mat-card-actions> -->
                    </mat-card>
                    <hr>
                    <div class="row">
                        <div class="col-lg-4">
                            <!-- <a href="#">Add new service</a> -->
                        </div>
                        <div class="col-lg-8">
                            <ul class="">
                                <li
                                    style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Total <span class="pull-right"> {{checkoutData.totalCost}}</span>
                                </li>
                                <!-- <li style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Balance <span class="pull-right"> {{newTotal}}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div *ngIf="addMembershipScreen">
                    <mat-card style="display:flex;flex-direction:row;justify-content: space-between;"
                        class="example-card" *ngFor="let item of checkoutData.voucherArray; let i=index ">
                        <mat-card-header>
                            <mat-card-title style="text-transform: capitalize;">{{i+1}}. {{item.name}}</mat-card-title>
                            <mat-card-subtitle>Sessions: {{item.session}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <!-- flex-wrap:nowrap; justify-content: flex-start;display: flex; -->
                            <ul class="flex"
                                style="flex-direction:row;justify-content: flex-start;display: flex;width:100%; ">

                                <li class="mr-2">
                                    <!-- <strong>Quantity</strong> -->
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Price</mat-label>
                                        <input matInput [(ngModel)]="item.retailPrice" readonly>
                                    </mat-form-field>
                                </li>
                                <li class="mr-2">
                                    <mat-form-field class="example-full-width " appearance="outline">
                                        <mat-label>Membership Type </mat-label>
                                        <input matInput [(ngModel)]="item.voucherType" readonly>
                                    </mat-form-field>
                                </li>

                            </ul>

                        </mat-card-content>
                        <!-- <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                        </mat-card-actions> -->
                    </mat-card>
                    <hr>
                    <div class="row">
                        <div class="col-lg-4">
                            <!-- <a href="#">Add new service</a> -->
                        </div>
                        <div class="col-lg-8">
                            <ul class="">
                                <li
                                    style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Total <span class="pull-right"> {{checkoutData.totalCost}}</span>
                                </li>
                                <!-- <li style="font-size:18px; color:#766326; padding:10px 5px;border-bottom:1px solid #ccc">
                                    Balance <span class="pull-right"> {{checkoutData.newTotal}}</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <h3 style="text-transform: capitalize;">{{checkoutData.clientName}}</h3>
                <button mat-raised-button class="mt-5 example-full-width " type="button"><span
                        class="2x">{{newTotal}}</span></button>
                <hr>
                <button mat-raised-button color="primary" class="mr-2 mb-2 col-lg-5" type="button"
                    (click)="proceedPayment()">Pay Now</button>
            </div>

        </div>


        <div class="d-flex" *ngIf="invoicescreen">
            <div class="invoice">
                <h3>Invoice # {{invoiceData.srNo}}</h3>
                <h6>{{invoiceData.createdAt | date: 'EEEE, MMMM d, y'}}</h6>
                <ul>
                    <div *ngIf="invoiceForService">
                        <li *ngFor="let itm of invoiceData.eventArr; let i=index ">
                            <span>
                                <small>Item {{i+1}}</small>
                                <h6>{{itm.subServiceName ? itm.subServiceName : itm.packageName}}</h6>
                                <p>{{itm.startDate | date: 'dd/MM/yyyy hh:mm:a' }}</p>
                            </span>
                            <span>
                                {{itm.price}}
                            </span>
                        </li>
                        <li>
                            <span>
                                <h6>Tip</h6>
                            </span>
                            <span>
                                {{invoiceData.tipAmount}}
                            </span>
                        </li>
                    </div>
                    <div *ngIf="invoiceForVoucher">
                        <li *ngFor="let itm of invoiceData.eventArr; let i=index ">
                            <span>
                                <small>Voucher {{i+1}}</small>
                                <h6>{{itm.name}}</h6>
                                <!-- <p>{{itm.createdAt | date: 'dd/MM/yyyy hh:mm:a' }}</p> -->
                                <p>Voucher Code:- {{itm.voucherCode}}</p>
                            </span>
                            <span>
                                {{itm.retailPrice}}
                            </span>
                        </li>
                    </div>
                    <div *ngIf="invoiceForProduct">
                        <li *ngFor="let itm of invoiceData.eventArr; let i=index ">
                            <span>
                                <small>Product {{i+1}}</small>
                                <h6>{{itm.productName}}</h6>
                                <!-- <p>{{itm.createdAt | date: 'dd/MM/yyyy hh:mm:a' }}</p> -->
                                <p>Brand:{{itm.brand}}</p>
                            </span>
                            <span>
                                {{itm.retailPrice}}
                            </span>
                        </li>
                    </div>

                    <div *ngIf="invoiceForMember">
                        <li *ngFor="let itm of invoiceData.voucherArray; let i=index ">
                            <span>
                                <small>Voucher {{i+1}}</small>
                                <h6>{{itm.name}}</h6>
                                <!-- <p>{{itm.createdAt | date: 'dd/MM/yyyy hh:mm:a' }}</p> -->
                                <p>Voucher Code:- {{itm.voucherCode}}</p>
                            </span>
                            <span>
                                {{itm.retailPrice}}
                            </span>
                        </li>
                    </div>
                    <li>
                        <span>
                            <h6>Subtotal</h6>
                        </span>
                        <span>
                            {{invoiceData.subTotal}}
                        </span>
                    </li>
                    <li>
                        <span>
                            <h6>Total</h6>
                        </span>
                        <span>
                            {{invoiceData.total}}
                        </span>
                    </li>
                    <div *ngIf="invoiceData.paymentData[0].voucherDetails != undefined ">
                        <li *ngIf="invoiceData.paymentData[0].voucherDetails.length>0">
                            <div *ngFor="let v of invoiceData.paymentData[0].voucherDetails">
                                <span>
                                    <h6>Pay by voucher</h6>
                                    <p>Voucher Code: {{v.voucherCode}}</p>
                                </span>
                                <span>
                                    {{v.voucherAmount}}
                                </span>
                            </div>
                        </li>
                    </div>
                    <!-- <li>
                        <span>
                            <h6>Cash</h6>
                            <p>{{invoiceData.createdAt | date: 'dd/MM/yyyy'}}</p>
                        </span>
                        <span>
                            {{invoiceData.paymentData[0].cashAmount}}
                        </span>
                    </li> -->
                    <li>
                        <span>
                            <h6>Balance</h6>
                        </span>
                        <span>
                            0
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>