import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { json } from "ngx-custom-validators/src/app/json/validator";
import { Observable, of, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  private readonly API_URL = "assets/data/appointment.json";
  private readonly user_data = JSON.parse(localStorage.getItem("user"));

  private readonly headers = new HttpHeaders({
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
  });

  private readonly role = this.user_data.role;

  constructor(private http: HttpClient, private router: Router) {}
  cache = {};

  getAllappointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCurrentUpcomingBooking`,
      { id: id },
      { headers: headers }
    );
  }
  getAllCountofServiceAndPackage(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getBookingCountPartner`,
      { id: this.user_data._id },
      { headers: headers }
    );
  }

  getAllfutureAppointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getFutureUpcomingBooking`,
      { id: id },
      { headers: headers }
    );
  }
  getPartnerInvoiceById(_id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getInvoiceByBookingId`,
      { id: _id },
      { headers: headers }
    );
  }

  getAllCompletedAppointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCompleteBookingPartner`,
      { id: id },
      { headers: headers }
    );
  }

  getCancelBooking(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCancelBookingUpdate`,
      { id: id },
      { headers: headers }
    );
  }

  FilterAppointment(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role === "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/searchAppointmentPartner`,
        { partnerId: obj.id, srNo: obj.srNo },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/searchAppointmentPartner`,
        { partnerId: obj.id, srNo: obj.srNo },
        { headers: headers }
      );
    }
  }

  getAppointByAppointmentId(srNo) {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getAppointmentDataCard`,
        { srNo: srNo },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getAppointmentDataCard`,
        { srNo: srNo },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getAppointmentDataCard`,
        { srNo: srNo },
        { headers: headers }
      );
    }
  }

  // getPackage(v){
  //   let headers = new HttpHeaders({
  //     'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
  //     //'Content-type': 'multipart/form-data'
  //   })

  //   //  return this.httpClient.post(`${environment.partnerapiUrl}/admin/getAllPackages`,{},{headers: headers})
  //   return this.http.post(`${environment.partnerapiUrl}/partner/getAllPackagesByPartnerId`,{id:v}, { headers: headers });

  // }

  // getService(v){
  //   let headers = new HttpHeaders({
  //     'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
  //     //'Content-type': 'multipart/form-data'
  //   })

  //     return this.http.post(`${environment.partnerapiUrl}/admin/getServicesByPartnerId`,{partnerId:v},{headers: headers})
  // }

  // getAllStaffsForPartner(obj) {

  //   let headers = new HttpHeaders({
  //     'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
  //     //'Content-type': 'multipart/form-data'
  //   })

  //   const user = JSON.parse(localStorage.getItem('user'))

  //  return this.http.post<any>(`${environment.partnerapiUrl}/partner/getPartnerStaff`,obj,{headers:headers})

  // }

  getNoShowAppointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getNoShowBookingsPartner

      `,
        { id: id },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getNoShowBookingsPartner

      `,
        { id: id },
        { headers: headers }
      );
    }
  }

  StartService(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/startAppointmentEvent`,
        { id: id },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/startAppointmentEvent`,
        { id: id },
        { headers: headers }
      );
    } else this.role == "partner";
    return this.http.post(
      `${environment.partnerapiUrl}/partner/startAppointmentEvent`,
      { id: id },
      { headers: headers }
    );
  }

  getAllStaffsForPartner(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/getPartnerStaff`,
        { partnerId: id },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/getPartnerStaff`,
        { partnerId: id },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/getPartnerStaff`,
        { partnerId: id },
        { headers: headers }
      );
    }
  }

  getAllCurrentappointmentByStatusInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
 
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getCurrentUpcomingBookingByStatus`,
        {paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
  }
  getFutureappointmentByStatusInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getFutureUpcomingBookingByStatus`,
        {paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
  }

  getcompletedappointmentByStatusInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getCompleteBookingPartnerByStatus`,
        {paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
  }

  getCancelappointmentByStatusInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/admin/getCancelBookingUpdateByStatus`,
      {paymentStatus: obj.paymentStatus },
      { headers: headers }
    );
  }
  getNoshowappointmentByStatusInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/admin/getNoShowBookingsPartnerByStatus`,
      {paymentStatus: obj.paymentStatus },
      { headers: headers }
    );
  }

  getAllappointmentByStatus(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getCurrentUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getCurrentUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getCurrentUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    }
  }
  getcompletedappointmentByStatus(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getCompleteBookingPartnerByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getCompleteBookingPartnerByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getCompleteBookingPartnerByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    }
  }
  getFutureappointmentByStatus(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/getFutureUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getFutureUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getFutureUpcomingBookingByStatus`,
        { id: obj.id, paymentStatus: obj.paymentStatus },
        { headers: headers }
      );
    }
  }

  getCancelappointmentByStatus(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCancelBookingUpdateByStatus`,
      { id: obj.id, paymentStatus: obj.paymentStatus },
      { headers: headers }
    );
  }
  getNoshowappointmentByStatus(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/getNoShowBookingsPartnerByStatus`,
      { id: obj.id, paymentStatus: obj.paymentStatus },
      { headers: headers }
    );
  }
  getCurrentAppointmentByStaffInAdmin(obj){
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/admin/getCurrentUpcomingBookingByStaff`,
      { staffId: obj.staffId},
      { headers: headers }
    );
  }
  getFutureAppointmentByStaffInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/admin/getFutureUpcomingBookingByStaff`,
      { staffId: obj.staffId },
      { headers: headers }
    );
  }
  getCompletedappointmentByStaffInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/admin/getCompleteBookingPartnerByStaff`,
      { staffId: obj.staffId},
      { headers: headers }
    );
  }
  getCancelappointmentByStaffInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/admin/getCancelBookingUpdateByStaff`,
      { staffId: obj.staffId},
      { headers: headers }
    );
  }
  getNoShowappointmentByStaffInAdmin(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/admin/getNoShowBookingsPartnerByStaff`,
      { staffId: obj.staffId},
      { headers: headers }
    );
  }

  getCurrentAppointmentByStaff(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/partner/getCurrentUpcomingBookingByStaff`,
      { staffId: obj.staffId , id: obj.id },
      { headers: headers }
    );
  }
  getFutureAppointmentByStaff(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/partner/getFutureUpcomingBookingByStaff`,
      { staffId: obj.staffId, id: obj.id },
      { headers: headers }
    );
  }
  getCompletedappointmentByStaff(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/partner/getCompleteBookingPartnerByStaff`,
      { staffId: obj.staffId, id: obj.id },
      { headers: headers }
    );
  }
  getCancelappointmentByStaff(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/partner/getCancelBookingUpdateByStaff`,
      { staffId: obj.staffId, id: obj.id },
      { headers: headers }
    );
  }
  getNoShowappointmentByStaff(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post<any>(
      `${environment.partnerapiUrl}/partner/getNoShowBookingsPartnerByStaff`,
      { staffId: obj.staffId, id: obj.id },
      { headers: headers }
    );
  }

  getFilterCompletedAppointmentByDate(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/filterCompleteBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCompleteBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCompleteBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    }
  }

  getFilterFutureAppointmentByDate(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/filterFutureBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterFutureBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterFutureBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    }
  }
  getFilterUpcomingAppointmentByDate(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/filterCurrentBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCurrentBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCurrentBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    }
  }
  getFilterCancelAppointmentByDate(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/filterCancelBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCancelBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterCancelBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    }
  }
  getFilterNoShowAppointmentByDate(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/admin/filterNoShowBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else if (this.role == "partner") {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterNoShowBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    } else {
      return this.http.post<any>(
        `${environment.partnerapiUrl}/partner/filterNoShowBookingPartnerByDate`,
        { data: obj },
        { headers: headers }
      );
    }
  }
  private newUser = new BehaviorSubject<any>({});
  // Setting Data To Subject
  setNewUserInfo(user: any) {
    this.newUser.next(user);
  }
  // Getting Data As Observable And Subscribe On Main Page.
  getNewUserInfo() {
    return this.newUser.asObservable();
  }
  private newData = new BehaviorSubject<any>({});

  sendinfirmation(appointmentsingleData: any) {
    this.newData.next(appointmentsingleData);
  }

  getinformation() {
    return this.newData.asObservable();
  }

  private changeStatus = new BehaviorSubject<any>({});

  SendchangeStatus(Status: any) {
    this.changeStatus.next(Status);
  }

  getChangedStatus() {
    return this.changeStatus.asObservable();
  }

  completenoshowappointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/completeNoShowBooking`,
      { bookingId: id },
      { headers: this.headers }
    );
  }

  completeAllPaidnoshowappointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/noShowBulkCompletePartner`,
      { bookings: id },
      { headers: this.headers }
    );
  }

  getBookingsStatus() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getAllBookingStatus`,
      {},
      { headers: this.headers }
    );
  }

  async getDropdownBookingStatus() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return await this.http
      .post(
        `${environment.partnerapiUrl}/partner/getAllBookingStatus`,
        {},
        { headers: this.headers }
      )
      .toPromise();
  }

  async getDropdownBasedOnAppointment(srNo) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return await this.http
      .post(
        `${environment.partnerapiUrl}/partner/getDropdownBasedOnAppointment`,
        { srNo: srNo },
        { headers: this.headers }
      )
      .toPromise();
  }

  FilterAppointmentByMobileNo(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.partnerapiUrl}/partner/searchAppointmentPartnerMob`,
      { partnerId: obj.id, mobileNo: obj.mobileno },
      { headers: headers }
    );
  }

  getBookingDataBasedOnClientNo(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getBookingDataBasedOnClient`,
      { partnerId: obj.id, clientId: obj.clientId },
      { headers: this.headers }
    );
  }
  getRefundApply(obj) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    return this.http.post(`${environment.partnerapiUrl}/partner/addRefundTransactions`, { data: obj }, { headers: headers })
  }

  ReachedAppointmentStatus(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/reachedEvent`,
        { id: id },
        { headers: this.headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/reachedEvent`,
        { id: id },
        { headers: this.headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/reachedEvent`,
        { id: id },
        { headers: this.headers }
      );
    }
  }
  pendingAppointmentStatus(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    if (this.role == "admin") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/pendingEvent`,
        { id: id },
        { headers: this.headers }
      );
    } else if (this.role == "partner") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/pendingEvent`,
        { id: id },
        { headers: this.headers }
      );
    } else {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/pendingEvent`,
        { id: id },
        { headers: this.headers }
      );
    }
  }

  getAppointmentsDataByFilter(params) {
    return this.http.post(
      `${environment.partnerapiUrl}/admin/filterDataAppointmentAdmin`,
      { data: params },
      { headers: this.headers }
    );
  }

  getAllStaffList() {
    return this.http.post(`${environment.apiUrl}/admin/getPartnerStaff`, {}, {
      headers: this.headers,
    });
  }

  setLocalStorage(key: any, val: any) {
    //if(this.cache[key])
    this.cache[key] = val;
  }

  getLocalStorage(key: any): Observable<any> {
    return of(this.cache[key]);
  }


  //http://localhost:3001/partner/getBookingDataById


  checkoutdropdownappointment(id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
  
      return this.http.post(
        `${environment.partnerapiUrl}/partner/getBookingDataById`,
        { id: id },
        { headers: this.headers }
      );
   
  }
}
