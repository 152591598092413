import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  imageForNotify: string = '../../assets/smosifyWeb/img/salona-logo.png'
  logoForNotify: string = '../../assets/smosifyWeb/img/logo.png'
  badgeForNotify: string = '../../assets/images/favicon.ico'
  currentMessage = new BehaviorSubject(null);
  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  mobileNumber: any = {};
  deviceToken: any;

  constructor(private angularFireMessaging: AngularFireMessaging, private http: HttpClient, public router: Router, public matDialog: MatDialog) {
    this.mobileNumber = JSON.parse(localStorage.getItem('user'));
    this.angularFireMessaging.messages.subscribe(
      (msgings: any) => {
        msgings.onMessage = msgings.onMessage.bind(msgings);
        msgings.onTokenRefresh = msgings.onTokenRefresh.bind(msgings);
      });
  }

  // Browser will ask to user for permission to receive notification. After permission is granted by the user, 
  // firebase will return a token that can use as a reference to send a notification to the browser.
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.deviceToken = token;
        if (this.deviceToken != undefined) {
          this.tokenSubscribe();
        }
      });
  }

  // Calling Api
  SaveToken() {
    this.mobileNumber = JSON.parse(localStorage.getItem('user'));
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.http.post(`${environment.apiUrl}/partner/savetoken`, { deviceToken: this.deviceToken, deviceType: "Web", mobileNo: this.mobileNumber.mobile }, { headers: headers })
  }

  // Subscribing Data From Api
  tokenSubscribe() {
    this.SaveToken().subscribe({
      next: (result: any) => {
        // console.log(result);
      },
      error: (err) => {
        // console.log(err);
      }
    });
  }

  // This function will be triggered when a new message has received.
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (msg) => {
        this.currentMessage.next(msg);
        this.showNotification(msg);
      });
  } as

  // Custom Notification Template.
  showNotification(payload: any) {
    let notify_data = payload['notification'];
    let title = notify_data['title'];
    let options = {
      body: notify_data['body'],
      // icon: notify_data['image'],
      icon: this.logoForNotify,
      badge: this.badgeForNotify,
      // image: notify_data['image'],
      image: this.imageForNotify,
    };
    let notify: Notification = new Notification(title, options)
    notify.addEventListener('click', (event) => {
      event.preventDefault();
      this.matDialog.closeAll();
      this.router.navigate(["/partner/appointment/viewappointment"]);
    });
    // onshow = (event) => {};
    // notify.onshow = (event) => {
    //   event.preventDefault();
    //   this.matDialog.closeAll();
    //   this.router.navigate(["/partner/appointment/viewappointment"]);
    //   // window.location.href = "/salonza/#/partner/appointment/viewappointment";
    // }
  }
}
