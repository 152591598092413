<div class="container">
  <!-- <div [innerHTML]="panelData"> -->
  <div>
    <!-- <input type="text" > -->
    <form *ngIf="showForm" class="m-4" [formGroup]="callForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">

        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Terminal Id</mat-label>
            <input matInput formControlName="terminal" placeholder="4001" required>
          </mat-form-field>

        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-3">

          <button class="mr-3" [disabled]="!callForm.valid " mat-raised-button color="primary">Go</button>
        </div>
      </div>
    </form>

    <iframe *ngIf="trustedUrl != null || trustedUrl != undefined" class="e2e-iframe-trusted-src" width="100%"
      height="500" [src]="trustedUrl"></iframe>

  </div>
</div>
