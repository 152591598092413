import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Staff } from './staff.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Subject } from 'rxjs/Subject';
@Injectable()
export class StaffService {
  private readonly API_URL = 'assets/data/staff.json';
  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  private readonly role = this.user_data.role;
  private messageSource = new BehaviorSubject('default');
  currentMessage = this.messageSource.asObservable();

  
  dataChange: BehaviorSubject<Staff[]> = new BehaviorSubject<Staff[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  private readonly headers = new HttpHeaders({
    'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    //'Content-type': 'multipart/form-data'
  })
  constructor(private httpClient: HttpClient) { }

  get data(): Staff[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  /** CRUD METHODS */
  getAllStaffs(): void {
    if (this.user_data == "admin")
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getStaffs`, {}, { headers: this.headers }).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    else {
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getStaffs`, {}, { headers: this.headers }).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    }
  }

  getAllStaffsForPartner(): void {
    const user = JSON.parse(localStorage.getItem('user'))
    if (this.user_data.role == "partner") {
      this.httpClient.post<any>(`${environment.apiUrl}/partner/getPartnerStaff`, { partnerId: user._id }, { headers: this.headers }).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    } else if (this.user_data.role == "admin") {
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getPartnerStaff`, {}, { headers: this.headers }).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    }
    else {
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getPartnerStaff`, {}, { headers: this.headers }).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    }
  }

  getAllStaffsForPartnerAdmin(dt): void {
    const user = JSON.parse(localStorage.getItem('user'))
    this.httpClient.post<any>(`${environment.apiUrl}/partner/getPartnerStaff`, { partnerId: dt }, { headers: this.headers }).subscribe(
      data => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  // DEMO ONLY, you can find working methods below
  addStaff(staff: Staff): void {
    this.dialogData = staff;
  }

  updateStaff(id: any, staffObj: any) {
    if (this.role == "admin") {
      return this.httpClient.post(`${environment.apiUrl}/admin/updateStaff`, { id: id, data: staffObj }, { headers: this.headers })
    }
    else {
      return this.httpClient.post(`${environment.apiUrl}/admin/updateStaff`, { id: id, data: staffObj }, { headers: this.headers })
    }
  }

  deleteStaff(id) {
    if (this.role == "admin") {
      return this.httpClient.post(`${environment.apiUrl}/admin/deleteStaff`, { id: id }, { headers: this.headers });
    }
    else {
      return this.httpClient.post(`${environment.apiUrl}/admin/deleteStaff`, { id: id }, { headers: this.headers });
    }
  }

  deleteStaffForPartner(id) {
    if (this.role == "partner") {
      return this.httpClient.post(`${environment.apiUrl}/partner/deletePartnerStaff`, { id: id, partnerId: this.user_data._id }, { headers: this.headers });
    } else if (this.role == "admin") {
      return this.httpClient.post(`${environment.apiUrl}/admin/deletePartnerStaff`, { id: id }, { headers: this.headers });
    }
    else {
      return this.httpClient.post(`${environment.apiUrl}/admin/deletePartnerStaff`, { id: id }, { headers: this.headers });
    }
  }
  
}
