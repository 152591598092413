import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/shared/security/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-watchfulheader',
  templateUrl: './watchfulheader.component.html',
  styleUrls: ['./watchfulheader.component.sass']
})
export class WatchfulheaderComponent implements OnInit {
  user_detail : any;
  userImg: string;
  user_data : any;
  constructor(
    private authService: AuthService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.userImg = this.authService.getUserImg();
    const userRole = this.authService.getRole();
    this.user_data = JSON.parse(localStorage.getItem('user'));
    if(this.user_data != null){
      console.log('Watchful header' + this.user_data);
    }
  }

  
  profileEdit() {
    if (this.router.url.includes("/watchfuleye/")) {
       this.router.navigate(["/admin/edit-profile"]);
   }
   else if(this.router.url.includes("/staff/")) {
     this.router.navigate(["/staff/my-profile"]);
   }
   else {
     this.router.navigate(["/patient/my-profile"]);
   }
 }

 logout() {
  console.log("calling logout");
  if (this.router.url.includes("/watchfuleye/") || this.router.url.includes("/authentication/")) {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.user_data = null;
        this.router.navigate(["/authentication/signin"]);
      }
    });
  } 
  // else {
  //   this.authService.logout().subscribe((res) => {
  //     if (!res.success) {
  //       this.router.navigate(["/authentication/signin"]);
  //     }
  //   });
  // }
}
goToDashboard(){
  this.router.navigate(["/patient/dashboard"]);
}
//navigate routes
// debugger;
// home(){
//   this.router.navigate(["/watchfuleye/home"]);
// }  
// aboutus(){
//   this.router.navigate(["/watchfuleye/aboutus"]);
// }

}
