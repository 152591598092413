import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-sessionmodel',
  templateUrl: './sessionmodel.component.html',
  styleUrls: ['./sessionmodel.component.sass']
})
export class SessionmodelComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
   
  }

  onClose(){
    this.bsModalRef.hide();
  }

}
