import { Component, OnInit, ViewChild, TemplateRef, ElementRef, AfterViewInit, Inject, Renderer2 } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation, Location } from "@angular/common";
import { AuthService } from "./shared/security/auth.service";
import * as AOS from 'aos';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { JwtHelperService } from "@auth0/angular-jwt";
import { SessionmodelComponent } from "./layout/sessionmodel/sessionmodel.component";
import { environment } from "../environments/environment";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { NotificationService } from "./ServicesNotification/notification.service";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from '@angular/material/dialog';
import { DialogboxComponent } from "./partner/signin/dialogbox/dialogbox.component";

// import { E } from "@angular/cdk/keycodes";
const document: any = window.document;


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {

  currentUrl: string;
  public isLoggedIn: false;
  partnerActiveInactive = {};
  partnerRole: any
  titleNotification = 'Push-notification';
  message: any;
  // show: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  public modalRef: BsModalRef;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  role: any;
  expToken: any;
  tokenPayload: any;
  expirationDate: any;
  isTokenBooleanValue: boolean;
  fullTokenObject: any = {};
  setIdelTime = environment.idelTimeInSeconds;
  SetTimeOut = environment.timeOutInSeconds;
  isAdminVerfiedOrNot: boolean;
  isDeletedOrNot: boolean;

  constructor(
    public _router: Router,
    @Inject(DOCUMENT) private document: Document,
    location: PlatformLocation,
    private renderer: Renderer2,
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private idle: Idle,
    private dialog: MatDialog,
    private keepalive: Keepalive,
    private router: Router,
    private modalService: BsModalService,
    private jwtHelper: JwtHelperService,
    private notificationService: NotificationService,
    private httpClient: HttpClient
  ) {




    let userData = JSON.parse(localStorage.getItem('user'));
    if (userData != null) {
      this.expToken = userData.token;
    }
    else {
      this.expToken = "";
    }

    if(document.location.href.includes('admin.smosify.com') && this.expToken =="")
    {
      this.router.navigate(['/admin/authentication/signin']);
    }
    else if (document.location.href.includes('partners.smosify.com/partner'))
    {
      console.log("partner");
    }
    else if (document.location.href.includes('partners.smosify.com'))
    {
      this.router.navigate(['/partner/']);
    }

    //  // Calling Services For Requesting To Allow Notification.
    //  this.notificationService.requestPermission();

    //  // Calling Here, This function will be triggered when a new message has received.
    //  this.notificationService.receiveMessage();

    //  // Showing Output To Template.
    //  this.message = this.notificationService.currentMessage;

    // this.GetTokenDecoded();
    // this.getTokenExpirationDate();
    // this.role = this.auth.getRole();


    // sets an idle timeout of 14 Min, for testing purposes.
    idle.setIdle(this.setIdelTime);

    // sets a timeout period of 60 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.SetTimeOut);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle, You have arrived before 60 seconds, What would you like to do!!'
      // console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.role = localStorage.getItem('ROLE');
      this.timedOut = true;
      this.idleState = 'Timed out!';
      this.childModal.hide();
      this.spinner.show();
      // localStorage.clear();
      // console.log(this.idleState);
      if (this.role == "partner") {
        // this.router.navigate(['/partner/authentication/signin']);
        // this.reloadThePage();
        this.logout();
      }
      else if (this.role == "admin") {
        // this.router.navigate(['/admin/authentication/signin']);
        // this.reloadThePage();
        this.logout();
      }
      else {
        // this.router.navigate(['/staff/authentication/signin']);
        // this.reloadThePage();
        this.logout();
      }
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.auth.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    });


    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        let adminUrl = routerEvent.url.includes('/admin/');
        let partnerUrl = routerEvent.url.includes('/partner/');
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
        if (this.currentUrl) {
          let userData = JSON.parse(localStorage.getItem('user'));
          if (partnerUrl == true) {
            if (userData == null) {
              this.partnerRole = "partner"
              this.isAdminVerfiedOrNot = true
              this.isDeletedOrNot = false
            }
            else {
              this.partnerRole = userData.role
              this.isAdminVerfiedOrNot = userData.isAdminVerified
              this.isDeletedOrNot = userData.isDeleted

              if (this.isAdminVerfiedOrNot == !true || this.isDeletedOrNot == true) {
                this.callSidemenuCollapse();
              }
              else {
                this.renderer.removeClass(this.document.body, "side-closed");
                this.renderer.removeClass(this.document.body, "submenu-closed");
              }
            }

            // if (this.isAdminVerfiedOrNot == !true || this.isDeletedOrNot == true) {
            //   this.dialog.open(DialogboxComponent, {
            //     height: 'auto',
            //     width: 'auto',
            //     position: {
            //       top: '5px',
            //     }
            //   });
            // }
            // this.partnerActiveInactive = this.auth.partnerSubject;
            // Calling Services For Requesting To Allow Notification.
            this.notificationService.requestPermission();

            // Calling Here, This function will be triggered when a new message has received.
            this.notificationService.receiveMessage();

            // Showing Output To Template.
            this.message = this.notificationService.currentMessage;
          }
          else if (adminUrl == true) {
            if (this.currentUrl == 'signin') {
              this.partnerRole = ''
              return
            }
            this.isAdminVerfiedOrNot = true;
            this.isDeletedOrNot = true;
            this.partnerRole = 'admin'
          }
          if (userData != null) {
            this.expToken = userData.token;
          }
          else {
            this.expToken = "";
          }
          this.GetTokenDecoded();
        }
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    AOS.init();

    // Calling Services For Requesting To Allow Notification.
    this.notificationService.requestPermission();

    // Calling Here, This function will be triggered when a new message has received.
    this.notificationService.receiveMessage();

    // Showing Output To Template.
    this.message = this.notificationService.currentMessage;
  }


  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  // logout() {
  //   this.role = localStorage.getItem('ROLE');
  //   this.spinner.show();
  //   this.childModal.hide();
  //   localStorage.clear();
  //   this.auth.setUserLoggedIn(false);
  //   if (this.role == "partner" && this.router.url.includes("/partner/")) {
  //     this.router.navigate(['/partner/authentication/signin']);
  //     // location.reload();
  //   }
  //   else if (this.role == "admin" && this.router.url.includes("/admin/")) {
  //     this.router.navigate(['/admin/authentication/signin']);
  //     // location.reload();
  //   }
  //   else {
  //     this.router.navigate(['/staff/authentication/signin']);
  //     // location.reload();
  //   }
  // }

  logout() {
    let userData = JSON.parse(localStorage.getItem("user")) != null ? JSON.parse(localStorage.getItem("user")) : JSON.parse(localStorage.getItem("member"));
    this.spinner.show();
    this.childModal.hide();
    // localStorage.clear();
    this.auth.setUserLoggedIn(false);
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {

          if (result.ResultCode == "-1") {
            console.log("Nothing to logout");
          } else {
            this.httpClient
              .get(
                `${environment.crmUrl}/api/cti/logout?hostId=${userData.mobile}&tspltoken=${result.ResultString}`
              )
              .subscribe({
                next: (result: any) => {
                  if (result.ResultCode == "0") {
                    console.log("Log out successfully!");
                  }
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    localStorage.clear();
    if (this.router.url.includes("/admin/")) {
      this.auth.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/admin/authentication/signin"]);
        }
      });
    }
    else if (this.router.url.includes("/staff/")) {
      this.auth.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/staff/authentication/signin"]);
        }
      });
    }
    else {
      this.auth.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/partner/authentication/signin"]);
        }
      });
    }
  }

  // After Logout reload The Page
  // reloadThePage() {
  //   location.reload();
  // }

  // Finding The Difference Between Expired Time And Current Time
  GetTokenDecoded() {
    if (this.expToken != "") {
      this.checkIfExpired();
      // It's Gives Us Object Of Token Data
      // let data = this.jwtHelper.decodeToken(this.expToken);
      this.fullTokenObject = this.jwtHelper.decodeToken(this.expToken);
      this.expirationDate = this.jwtHelper.getTokenExpirationDate(this.expToken);
      let expirationDate = this.expirationDate;
      expirationDate.setMinutes(expirationDate.getMinutes() - 1);
      let expDate = expirationDate.getTime();
      let currentDateAndTime = new Date().getTime();
      let exp = expDate - currentDateAndTime;
      if (!exp) {
        // this.showModal();
        return true
      }
      else {
        // this.showModal();
        return false
      }
    }
  }

  // Verify If Token Expired Given True: Boolean Value
  isAuthenticated(): boolean {
    // this.isTokenBooleanValue = this.jwtHelper.isTokenExpired(this.expToken);
    return this.isTokenBooleanValue;
  }

  callEverySeconds() {
    let intervalTime = setInterval(() => {
      this.GetTokenDecoded();
      if (this.isTokenBooleanValue == true) {
        clearInterval(intervalTime)
      }
    }, 1000)
  }

  // Refresh Token Api Called
  refreshTokenIfExpired() {
    let mobileNumber = this.fullTokenObject.mobile;
    this.auth.tokenRefresh(mobileNumber).subscribe({
      next: (res: any) => {
        if (res.status == "success") {
          return true
        }
        else if (res.status == "error") {
          this.logout();
          return false
        }
      }
    });
  }


  // If True Just Logout The User
  checkIfExpired() {
    this.isAuthenticated();
    if (this.isTokenBooleanValue == true) {
      this.refreshTokenIfExpired();
      // this.showModal();
      // this.logout();
    }
    else if (this.isTokenBooleanValue == false) {
      return
    }
  }

  // After Load Component Completely Calling Function
  ngAfterViewInit(): void {
    this.callEverySeconds();
  }

  // this is our model code
  public showModal(): void {
    this.modalRef = this.modalService.show(SessionmodelComponent, {
      animated: true,
      backdrop: 'static',
      class: 'modal-md'
    });
  }

  callSidemenuCollapse() {
    this.renderer.addClass(this.document.body, "side-closed");
    this.renderer.addClass(this.document.body, "submenu-closed");
    // const hasClass = this.document.body.classList.contains("side-closed");
    // if (hasClass) {
    //   this.renderer.removeClass(this.document.body, "side-closed");
    //   this.renderer.removeClass(this.document.body, "submenu-closed");
    // } else {
    //   this.renderer.addClass(this.document.body, "side-closed");
    //   this.renderer.addClass(this.document.body, "submenu-closed");
    // }
  }
}
