import { Injectable } from "@angular/core";

import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { json } from "ngx-custom-validators/src/app/json/validator";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  private readonly headersWithoutToken = new HttpHeaders({});

  private messageSource = new BehaviorSubject("default");
  currentMessage = this.messageSource.asObservable();
  private getHttpHeader = (contentType) => {
    let authToken = "";
    if (localStorage.getItem("user") != null) {
      authToken = "Bearer " + JSON.parse(localStorage.getItem("user")).token;
    }
    return {
      headers: new HttpHeaders({
        "Content-Type": contentType,
        Authorization: authToken,
      }),
    };
  };
  private readonly user_data = JSON.parse(localStorage.getItem("user"));
  private readonly headers = new HttpHeaders({
    Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
  });
  private readonly role = this.user_data.role;
  partnerId = this.user_data._id;
  constructor(private http: HttpClient, private router: Router) {}

  getlookupByType(type) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.http.post(
      `${environment.apiUrl}/api/getlookupByType`,
      { type: type },
      { headers: headers }
    );
  }

  getService() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.http.post(
      `${environment.apiUrl}/admin/getServices`,
      {},
      { headers: headers }
    );
  }

  getPartnerByDataid(_id: any) {
    let header = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.http.post(
      `${environment.apiUrl}/admin/getPartnerByid`,
      { id: _id },
      { headers: header }
    );
    //   http://localhost:3000/partner/getPartnerDataId
  }

  updatePartners(partnerObj: any, files: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    const formdata = new FormData();
    formdata.append("data", JSON.stringify(partnerObj));
    for (var i = 0; i < files.length; i++) {
      if (files[i].fileType == "image") {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = "image" + "." + fileExt;
        formdata.append("file", files[i], fileNewName);
      } else if (files[i].fileType == "salonImage") {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = "salonImage" + "." + fileExt;
        formdata.append("file", files[i], fileNewName);
      } else if (files[i].fileType == "video") {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = "video" + "." + fileExt;
        formdata.append("file", files[i], fileNewName);
      } else {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = files[i].fileType + "." + fileExt;
        formdata.append("file", files[i], fileNewName);
      }
    }

    return this.http.post(
      `${environment.apiUrl}/partner/updatePartnerNew`,
      formdata,
      { headers: headers }
    );
    // http://localhost:3000/partner/updatePartner
  }

  getFilteredService(ser) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/admin/searchServiceNameFilter`,
      { serviceName: ser },
      { headers: headers }
    );
  }

  getServiceCtegory() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/getAllServicePartnerCategory`,
      {},
      { headers: headers }
    );
  }

  verifyEmailWithOTP(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/partnerEmailVerfication`,
      { data: obj },
      { headers: headers }
    );
  }

  checkOTPMatching(email, mobile, otp) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/verifyOtpEmail`,
      { email: email, mobile: mobile, otp: otp },
      { headers: headers }
    );
  }

  verifyMobileWithOTP(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/sendOtpPartner`,
      { mobile: obj.mobile, username: obj.mobile },
      { headers: headers }
    );
  }

  checkMobileOTPMatching(mobile, oldNum, otp) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/verifyOtp`,
      { mobile: mobile, oldNum: oldNum, otp: otp },
      { headers: headers }
    );
  }

  getAddress(mob) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    return this.http.post(
      `${environment.apiUrl}/partner/searchParentPartnerFilterName`,
      { name: mob },
      { headers: headers }
    );
  }

  getPartner(obj) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.http.post(
      `${environment.apiUrl}/partner/searchPartnerByMobile`,
      { mobile: obj.mobile },
      { headers: headers }
    );
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeMessageMobile(message: string) {
    this.messageSource.next(message);
  }

  deleteImages(fileName, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.apiUrl}/partner/deleteSalonImgPartnerNew`,
      { fileName: fileName, partnerId: id },
      { headers: headers }
    );
  }

  deleteVideos(fileName, id) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.apiUrl}/partner/deleteSalonVideoImgPartner`,
      { fileName: fileName, partnerId: id },
      { headers: headers }
    );
  }

  private updatePartnerProfile = new Subject<any>(); //need to create a subject

  sendUpdate(data: string) {
    //the component that wants to update something, calls this fn
    this.updatePartnerProfile.next({ loginPartner: data }); //next() will feed the value in Subject
  }

  getUpdate(): Observable<any> {
    //the receiver component calls this function
    return this.updatePartnerProfile.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  getCurrencySign() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.apiUrl}/partner/getCurrencyData`,
      { country: "India" },
      { headers: headers }
    );
  }

  getOnlyPackageAppointmentData(_id, search) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });
    return this.http.post(
      `${environment.apiUrl}/partner/getPackageBookingPartner`,
      { id: _id, searchText: search },
      { headers: headers }
    );
  }

  saveMakeUpArtistData(obj, files) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    const formData = new FormData();
    formData.append("data", JSON.stringify(obj));
    for (var i = 0; i < files.length; i++) {
      if (files[i].fileType == "image") {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = "image" + "." + fileExt;
        formData.append("file", files[i], fileNewName);
      } else if (files[i].fileType == "video") {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = "video" + "." + fileExt;
        formData.append("file", files[i], fileNewName);
      } else {
        var fileExt = files[i].name.split(".")[1];
        var fileNewName = files[i].fileType + "." + fileExt;
        formData.append("file", files[i], fileNewName);
      }
    }
    return this.http.post(
      `${environment.apiUrl}/partner/createPartnerAlbum`,
      formData,
      { headers: headers }
    );
  }

  uploadMasterServiceData(file: File, serviceType) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    });
    let partnerDetail = {
      partnerId: JSON.parse(localStorage.getItem("user"))._id,
      partnerName: JSON.parse(localStorage.getItem("user")).partnerName,
      partnerMob: JSON.parse(localStorage.getItem("user")).mobile,
    };
    const formdata = new FormData();
    formdata.append("data", JSON.stringify(partnerDetail));

    formdata.append("file", file);
    if (serviceType == "Services") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/saveServiceCsvPartner`,
        formdata,
        { headers: headers }
      );
    } else if (serviceType == "Products") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/saveProductCsvPartner`,
        formdata,
        { headers: headers }
      );
    } else if (serviceType == "Clients") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/saveClientCsvPartner`,
        formdata,
        { headers: headers }
      );
    } else if (serviceType == "Voucher") {
      return this.http.post(
        `${environment.partnerapiUrl}/partner/saveVoucherCsvPartner`,
        formdata,
        { headers: headers }
      );
    } else if (serviceType == "Package") {
      return this.http.post(
        `${environment.partnerapiUrl}/admin/addSupplierProduct`,
        formdata,
        { headers: headers }
      );
    }
  }

  downloadTemplatecsv() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCsvServices`,
      { id: this.partnerId },
      { headers: headers }
    );
  }

  excelTemplatecsv() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCsvVoucher`,
      { id: this.partnerId },
      { headers: headers }
    );
  }

  excelDownloadcsv() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCsvProduct`,
      { id: this.partnerId },
      { headers: headers }
    );
  }

  excelClientDownloadcsv() {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    return this.http.post(
      `${environment.partnerapiUrl}/partner/getCsvClient`,
      { id: this.partnerId },
      { headers: headers }
    );
  }
}
