<div class="container">
  <!-- <div [innerHTML]="panelData"> -->
  <h3 mat-dialog-title>Call Dial (Without 0 Prefix Manual Call. With 0 Prefix Con Call)</h3>
  <div>
    <!-- <input type="text" > -->
    <form class="m-4" [formGroup]="callDialForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">

        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
            <!-- <label class="example-margin">Align:</label>
            <mat-radio-group>
              <mat-radio-button class="example-margin" value="manual">Manual Call</mat-radio-button>
              <mat-radio-button class="example-margin" value="conference">Conference Call
              </mat-radio-button>
            </mat-radio-group> -->
            <input type="radio" value="manual" formControlName="callType"> Manual Call
            <input type="radio" value="conference" formControlName="callType"> Conference Call
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">

        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
          <mat-form-field class="example-full-width mb-3" appearance="fill">
            <mat-label>Mobile</mat-label>
            <input matInput formControlName="mobile" placeholder="09267980180" required>
            <mat-error *ngIf="callDialForm.get('mobile').hasError('required')">
              Mobile Number is required! (09267980180 Always Enter mobile number with 0 prefix)
            </mat-error>
          </mat-form-field>

        </div>
        <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 mb-3">
          <button class="mr-3" [disabled]="!callDialForm.valid " mat-raised-button color="primary">Go</button>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
          <button class="mr-3" [disabled]="!callDialForm.valid " mat-raised-button color="primary">Close Call</button>
        </div>
      </div> -->
    </form>
  </div>
</div>
