import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CalendarService } from 'src/app/admin/bookcalender/calendar.service';
import { PaymentserviceService } from 'src/app/admin/bookcalender/paymentservice.service';

@Component({
  selector: 'app-paymentlink',
  templateUrl: './paymentlink.component.html',
  styleUrls: ['./paymentlink.component.sass']
})
export class PaymentlinkComponent implements OnInit {

  idToGetData: any;
  requestFor: any;
  checkoutData: any = {};
  addServiceScreen: any = false;
  addVoucherScreen: any = false;
  addProductScreen: any = false;
  addMembershipScreen: any = false;
  newTotal: any;
  orderId: any;
  amount: any;
  checkoutscreen: any = true;
  invoicescreen: any = false;
  invoiceForService: any;
  invoiceForVoucher: any;
  invoiceForProduct: any;
  invoiceForMember: any;
  invoiceData: any;
  payType: any;
  redeemAmount: any = 0;
  Coins: any = 0;
  serviceCost: any = 0;
  voucherAddedRow: any = false;
  MembershipAddedRow: any = false;
  coinsAddedRow: any = false



  constructor(private route: ActivatedRoute, private http: HttpClient, private snackBar: MatSnackBar,
    private calendarService: CalendarService, private winRef: PaymentserviceService,) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.idToGetData = params.id;
      this.requestFor = params.type;
    })

    if (this.idToGetData != undefined) {
      if (this.requestFor == 'voucher') {
        this.http.post(`${environment.apiUrl}/partner/getSellVoucherById`, { id: this.idToGetData }).subscribe({
          next: (res: any) => {
            if (res.status == 'success') {
              this.checkoutData = res.data;
              this.newTotal = this.checkoutData.totalCost
              this.addVoucherScreen = true;
              console.log(this.checkoutData);
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      }

      else if (this.requestFor == 'product') {
        this.http.post(`${environment.apiUrl}/partner/getSellproductById`, { id: this.idToGetData }).subscribe({
          next: (res: any) => {
            if (res.status == 'success') {
              this.checkoutData = res.data;
              this.newTotal = this.checkoutData.totalCost
              this.addProductScreen = true;
              console.log(this.checkoutData);
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      }
      else if (this.requestFor == 'membership') {
        this.http.post(`${environment.apiUrl}/partner/getSellMemberVoucherById`, { id: this.idToGetData }).subscribe({
          next: (res: any) => {
            if (res.status == 'success') {
              this.checkoutData = res.data;
              this.newTotal = this.checkoutData.totalCost
              this.addMembershipScreen = true;
              console.log(this.checkoutData);
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      }

      else {
        this.http.post(`${environment.apiUrl}/partner/getAppointmentBookingById`, { id: this.idToGetData }).subscribe({
          next: (res: any) => {
            if (res.status == 'success') {
              this.checkoutData = res.data[0];
              this.addServiceScreen = true;
              console.log(this.checkoutData);
              let paymentData = this.checkoutData.paymentData
              if (paymentData.length> 0) {
                this.redeemAmount;
                let paymentArrr = this.checkoutData.paymentData
                const voucherObj = paymentArrr.find((x) => x.payType == "Voucher");
                if (voucherObj != undefined || null) {
                  this.redeemAmount = voucherObj.voucherAmount;
                  this.voucherAddedRow = true;
                }
          
                const membershipObj = paymentArrr.find((x) => x.payType == "memberVoucher");
                if (membershipObj != undefined || null) {
                  this.serviceCost = membershipObj.voucherAmount;
                  this.MembershipAddedRow = true;
                }
          
          
                const coinsObj = paymentArrr.find((x) => x.payType == "Coins");
                if (coinsObj != undefined || null) {
                  this.Coins = coinsObj.coinAmount;
                  this.coinsAddedRow = true;
                }
          
                 let totalPrice = this.checkoutData.totalCost;
          
                this.newTotal =  totalPrice - (this.serviceCost + this.redeemAmount + this.Coins);
          
              }
            }
          },
          error: (err) => {
            console.log(err);
          }
        })
      }
    }


    this.http.post(`${environment.apiUrl}/api/getlookupByType`, { type: "App Payment Type" }).subscribe({
      next: (result: any) => {
        this.payType = result.data[0] != null ? result.data[0].details : [];
        console.log(this.payType, "typePay")
      }
    });
  }



  proceedPayment() {
    let amt = this.checkoutData.remainingCost * 100;
    this.http.post(`${environment.apiUrl}/razorpay/createOrderIdRazorpay`, {

      name: this.checkoutData.clientName,
      amount: amt
    }, {}).subscribe({
      next: (res: any) => {
        console.log(res);
        if (res.success) {
          this.orderId = res.succ.id;
          this.amount = res.succ.amount;
          //   this.fn = res.succ.receipt;
          this.payWithRazor(this.orderId, this.amount);
        } else {
          this.showNotification("snackbar-success", "Error in payment", "top", "center");
        }

      },
      error: (err) => {
        console.log(err);
      }
    })
  }

  payWithRazor(val, am) {
    //Number(am)
    const options: any = {
      key: `${environment.razorPay_Key}`,
      amount: Number(am), // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'Smosify', // company name or product name
      description: '',  // product description
      image: 'https://livestatic.novopay.in/resources/img/nodeapp/img/Logo_NP.jpg', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      response.amount = options.amount / 100;
      response.currency = options.currency;
      //  // call your backend api to verify payment signature & capture transaction custId orderNo
      let pmtData = [];
      pmtData.push(response);
      if (this.checkoutData.requestFor == 'voucher') {

        let obj = {
          id: this.checkoutData._id,
          paymentDetails: pmtData
        }

        let dt = {
          partnerId: this.checkoutData.partnerId,
          clientId: this.checkoutData.clientId,
          partnerName: this.checkoutData.partnerName,
          eventArr: this.checkoutData.voucherArray,
          total: parseInt(this.checkoutData.totalCost),

          subTotal: parseInt(this.checkoutData.totalCost),
          cash: parseInt(obj.paymentDetails[0].cashAmount),
          paymentData: obj.paymentDetails,
          bookingName: this.checkoutData.requestFor,
          clientName: this.checkoutData.clientName,
          paymentType: "Online",
          clientEmail: this.checkoutData.clientEmail,
          partnerEmail: this.checkoutData.partnerEmail,

        }

        console.log(obj);
        console.log(dt);
        this.calendarService.updatePaymentForVoucher(obj, dt).subscribe({
          next: (res: any) => {
            console.log(res);

            this.showNotification("snackbar-success", "Payment done successfully!!", "top", "center");
            this.checkoutscreen = false;
            this.invoicescreen = true;
            this.invoiceForService = true;
            this.invoiceData = res.data;

          },
          error: (err: any) => {
            this.showNotification("snackbar-warn", err.message, "top", "center");
          }
        })

      }
      else if (this.checkoutData.requestFor == 'product') {
        let obj = {
          id: this.checkoutData._id,
          paymentDetails: pmtData
        }

        let dt = {
          partnerId: this.checkoutData.partnerId,
          clientId: this.checkoutData.clientId,
          partnerName: this.checkoutData.partnerName,
          eventArr: this.checkoutData.productArray,
          total: parseInt(this.checkoutData.totalCost),

          subTotal: parseInt(this.checkoutData.totalCost),
          cash: parseInt(obj.paymentDetails[0].cashAmount),
          paymentData: obj.paymentDetails,
          bookingName: this.checkoutData.requestFor,
          clientName: this.checkoutData.clientName,
          paymentType: "Online",
          clientEmail: this.checkoutData.clientEmail,
          partnerEmail: this.checkoutData.partnerEmail,


        }

        console.log(obj);
        console.log(dt);
        this.calendarService.updatePaymentForProduct(obj, dt).subscribe({
          next: (res: any) => {
            console.log(res);

            this.showNotification("snackbar-success", "Payment done successfully!!", "top", "center");
            this.checkoutscreen = false;
            this.invoicescreen = true;
            this.invoiceForProduct = true;
            this.invoiceData = res.data;

          },
          error: (err: any) => {
            this.showNotification("snackbar-warn", err.message, "top", "center");
          }
        })
      }

      else if (this.checkoutData.requestFor == 'membership') {

        let obj = {
          id: this.checkoutData._id,
          paymentDetails: pmtData
        }

        let dt = {
          partnerId: this.checkoutData.partnerId,
          clientId: this.checkoutData.clientId,
          partnerName: this.checkoutData.partnerName,
          eventArr: this.checkoutData.voucherArray,
          total: parseInt(this.checkoutData.totalCost),

          subTotal: parseInt(this.checkoutData.totalCost),
          cash: parseInt(obj.paymentDetails[0].cashAmount),
          paymentData: obj.paymentDetails,
          bookingName: this.checkoutData.requestFor,
          clientName: this.checkoutData.clientName,
          paymentType: "Online",
          clientEmail: this.checkoutData.clientEmail,
          partnerEmail: this.checkoutData.partnerEmail,

        }

        console.log(obj);
        console.log(dt);
        this.calendarService.updatePaymentForMember(obj, dt).subscribe({
          next: (res: any) => {
            console.log(res);
            this.showNotification("snackbar-success", "Payment done successfully!!", "top", "center");
            this.checkoutscreen = false;
            this.invoicescreen = true;
            this.invoiceForService = false;
            this.invoiceData = res.data;
            this.invoiceForMember = true

          },
          error: (err: any) => {
            this.showNotification("snackbar-warn", err.message, "top", "center");
          }
        })

      }
      else {
        let newpay = pmtData[0];
        //newpay.cashAmount= 0,
        // newpay.voucherDetails=this.saveRedeemVoucher,
        // newpay.tipDetails = this.checkoutForm.value.serviceForm,
        // newpay.tipAmount = this.balance
        pmtData[0].payType = this.payType[1].name;
        let obj = {
          id: this.checkoutData._id,
          paymentDetails: pmtData,
        }
        let dt = {
          partnerId: this.checkoutData.partnerId,
          clientId: this.checkoutData.clientId,
          partnerName: this.checkoutData.partnerName,
          eventArr: this.checkoutData.eventArr,
          total: parseInt(this.checkoutData.totalCost),
          // tip: obj.paymentDetails[0].tipDetails,
          // tipAmount: parseInt(this.balance),
          subTotal: parseInt(this.checkoutData.totalCost),
          // cash:parseInt(obj.paymentDetails[0].cashAmount),
          paymentData: pmtData,
          bookingName: this.checkoutData.requestFor,
          clientName: this.checkoutData.clientName,
          // voucherDetails:obj.paymentDetails[0].voucherDetails,
          paymentType: "Online",
          clientEmail: this.checkoutData.clientEmail,
          partnerEmail: this.checkoutData.partnerEmail,
        }

        console.log(obj);
        console.log(dt);
        this.calendarService.updatePayment(obj, dt).subscribe({
          next: (res: any) => {
            console.log(res);

            this.showNotification("snackbar-success", "Payment done successfully!!", "top", "center");
            this.checkoutscreen = false;
            this.invoicescreen = true;
            this.invoiceForService = true
            this.invoiceData = res.data;

          },
          error: (err: any) => {
            this.showNotification("snackbar-warn", err.message, "top", "center");
          }
        })
      }

    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }



  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

}
