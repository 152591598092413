<!-- <div id="modal-content-wrapper">
  <header id="modal-header">
    <h1 id="modal-title">{{ modalData.title }}</h1>
  </header>
  <section id="modal-body">
    <p>{{ modalData.description }}</p>
  </section>
  <footer id="modal-footer">
    <button mat-raised-button id="modal-action-button" (click)="actionFunction()">
      {{ modalData.actionButtonText }}
    </button>
    <button mat-raised-button id="modal-cancel-button" (click)="closeModal()">
      Close
    </button>
  </footer>
</div> -->

<h1 mat-dialog-title>{{ modalData.title }}</h1>
<div mat-dialog-content>{{ modalData.description }}</div>
<div></div>
<div></div>
<div mat-dialog-actions>
  <button class="mr-3 btn primary" (click)="actionFunction()">
    {{ modalData.actionButtonText }}
  </button>
  <button class="btn warn" id="modal-cancel-button" (click)="closeModal()">
    Close
  </button>
</div>
