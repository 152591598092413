import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Doctors } from './doctors.model';
import { HttpClient, HttpErrorResponse ,HttpHeaders} from '@angular/common/http';
import { environment } from "src/environments/environment";
@Injectable()
export class DoctorsService {
  private readonly API_URL = 'assets/data/doctors.json';
  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  dataChange: BehaviorSubject<Doctors[]> = new BehaviorSubject<Doctors[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  private readonly headers = new HttpHeaders({
    'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    //'Content-type': 'multipart/form-data'
  })
  constructor(private httpClient: HttpClient) {}
  get data(): Doctors[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllDoctorss(): void {

    if (this.user_data.role == 'admin') {
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getAllPartner`, {}, { headers: this.headers }).subscribe(data => {
        this.dataChange.next(data.data);
      },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );

    }else{
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getAllPartner`,{},{headers:this.headers}).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    }

  }

  getAllIsPublishedPartner(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.apiUrl}/partner/isPublishedPartner`, { id: id }, { headers: headers })
  }
  // DEMO ONLY, you can find working methods below
  addDoctors(doctors: Doctors): void {
    this.dialogData = doctors;
  }
  
  updateDoctors(id: any, patientObj: any) {
    return this.httpClient.post(`${environment.apiUrl}/admin/updatePartner`,{id: id, data: patientObj },{headers:this.headers})
  }
  
  deleteDoctors(id: number) {
    console.log(id);
    let data = {
      _id:id
    }
   return this.httpClient.post(`${environment.apiUrl}/admin/deletePartner`,data,{headers:this.headers})
  }

  updateDoctorStatus(id: number) {
    console.log(id);
    let data = {
      _id:id
    }
   return this.httpClient.post(`${environment.apiUrl}/admin/verifyPartnerForLogin`,{id:id},{headers:this.headers})
  }

  updateDoctorStatusDeactivate(id: number) {
    console.log(id);
    let data = {
      _id:id
    }
   return this.httpClient.post(`${environment.apiUrl}/admin/deactivatePartnerFromAdmin`,{id:id},{headers:this.headers})
  }

  getAllDeletedDoctorss(): void {
    this.httpClient.post<any>(`${environment.apiUrl}/admin/getAllDeletedPartner`,{},{headers:this.headers}).subscribe(
      data => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }

  reActivatePartner(id){
    return this.httpClient.post(`${environment.apiUrl}/admin/activateDeletedPartner`,{id:id},{headers:this.headers})
  }

  AddGrade(data , files){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    for (var i = 0; i < files.length; i++) {
      if (files[i].fileType == "image") {
        var fileExt = files[i].name.split('.')[1];
        var fileNewName = "image" + "." + fileExt;
        formData.append("file", files[i], fileNewName);
      }
      else {
        var fileExt = files[i].name.split('.')[1];
        var fileNewName = "icon" + "." + fileExt;
        formData.append("file", files[i], fileNewName);
      }
    }
    return this.httpClient.post(`${environment.apiUrl}/admin/addGradesMaster`, formData ,{headers:this.headers})
  }

  getAllGrades(){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.partnerapiUrl}/admin/getGradesMaster`,{ },{headers:this.headers})
  }
}
