import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { json } from 'ngx-custom-validators/src/app/json/validator';
//import { Client } from './client/client.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationserviceService {
  dataChange: BehaviorSubject<NotificationserviceService[]> = new BehaviorSubject<NotificationserviceService[]>([]);
  private readonly API_URL = "assets/data/appointment.json";
  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  private readonly headers = new HttpHeaders({
    'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
  });
  private readonly role = this.user_data.role;

  constructor(private httpClient: HttpClient) { }
  get data(): NotificationserviceService[] {
    return this.dataChange.value;
  }

  getNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
      return this.httpClient.post(`${environment.apiUrl}/partner/getNotifications`, { partnerId :id }, { headers: headers });
   }

 
   getEarlierNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
      return this.httpClient.post(`${environment.apiUrl}/partner/getEarlierNotification`, { id :id }, { headers: headers });
   }
   markNotificationRead(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
      return this.httpClient.post(`${environment.apiUrl}/partner/markNotificationRead`, { not_id: id }, { headers: headers });
   }
   getReadNotifications(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
      return this.httpClient.post(`${environment.apiUrl}/partner/getReadNotifications`, { partnerId: id }, { headers: headers });
   }

   getlookupByType(type){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    return this.httpClient
      .post(`${environment.apiUrl}/api/getlookupByType`,{type:type },{headers: headers});

  }
  
  FilterDataByDate(obj){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/filterNotificationByDate`,{data:obj },{headers: headers});

  }
  FilterAllNotificationByDate(obj){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/filterAllNotificationByDate`,{data:obj },{headers: headers});

  }
  FilterReadNotificatioByDate(obj){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/filterReadNotificationByDate`,{data:obj },{headers: headers});

  }
  getNotificationlookupByType(type) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/getLookupDataByType`, { type: type }, { headers: headers });
    // return this.httpClient
    //   .post(`${environment.apiUrl}/partner/getLookupDataByType`, { type: type }, { headers: headers });

  }

  getAppointmentNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/getBookingNotificationPartner`, { id: id }, { headers: headers });

  }

  getVoucherNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/getVoucherNotificationPartner`, { id: id }, { headers: headers });
    // return this.httpClient
    //   .post(`${environment.apiUrl}/partner/getVoucherNotificationPartner`, { id: id }, { headers: headers });

  }
  getMemberShipNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/getMemberVoucherNotificationPartner`, { id: id }, { headers: headers });
    // return this.httpClient
    //   .post(`${environment.apiUrl}/partner/getMemberVoucherNotificationPartner`, { id: id }, { headers: headers });

  }
  getProductNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/getProductNotificationPartner`, { id: id }, { headers: headers });

  }
  getReviewtNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/getReviewNotificationPartner`, { id: id }, { headers: headers });

  }
  getStockNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.apiUrl}/partner/getStockNotificationPartner`, { id: id }, { headers: headers });

  }
  
  MarkAsReadAll(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/readAllNotification`, { partnerId: id }, { headers: headers });

  }
  getAllPartnerNotificationsCount(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/getAllPartnerNotifications`, { id: id }, { headers: headers });

  }
  getMarkedNotification(id) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient
      .post(`${environment.partnerapiUrl}/partner/markNotificationRead`, { not_id: id }, { headers: headers });

  }

}
