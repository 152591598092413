import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Requests } from "./request.model";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
 HttpHeaders,
} from "@angular/common/http";

import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Injectable()
export class RequestService {
  private readonly API_URL = "assets/data/appointment.json";

  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  private readonly headers = new HttpHeaders({
    'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
  });
  private readonly role = this.user_data.role;

  dataChange: BehaviorSubject<Requests[]> = new BehaviorSubject<Requests[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  constructor(private httpClient: HttpClient) {}
  get data(): Requests[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllAppointments(): void {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

      this.httpClient.post<any>(`${environment.apiUrl}/admin/getServiceRequest`,{},{headers:headers}).subscribe(
        (data) => {
         // if(this.role != 'executive'){
          this.dataChange.next(data.data);
         // console.log(data.data);
         // }
          // else {
          //   this.dataChange.next(data.data.filter((y)=>y.currentWorkFlow !="1"));
          // }
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + " " + error.message);
        }
      );
  }

  getAllClosedAppointments(): void {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

      this.httpClient.post<any>(`${environment.apiUrl}/admin/getClosedServiceRequest`,{},{headers:headers}).subscribe(
        (data) => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + " " + error.message);
        }
      );
  }

  getAllAppointmentsReport(): void {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    this.httpClient.post<any>(`${environment.apiUrl}/admin/getServiceRequestReport`,{},{headers:headers}).subscribe(
      (data) => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + " " + error.message);
      }
    );
  }
  // DEMO ONLY, you can find working methods below
  addAppointment(appointment: Requests): void {
    this.dialogData = appointment;
  }
  updateAppointment(appointment: Requests): void {
    this.dialogData = appointment;
  }
  deleteAppointment(id: number): void {
    console.log(id);
  }

  getService(){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    if (this.user_data.role == "patient") {
      return this.httpClient.post(`${environment.apiUrl}/user/getServices`,{},{headers: headers})
    }
    else {
      return this.httpClient.post(`${environment.apiUrl}/user/getServices`,{},{headers: headers})
    }
  }

  getUserData(obj) {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })

    if (this.user_data.role == "patient") {
      return this.httpClient.post(`${environment.apiUrl}/user/searchPatientByMobile`, { mobile: obj.mobile }, { headers: headers })
    }
    else if (this.user_data.role == "admin"){
      return this.httpClient.post(`${environment.apiUrl}/admin/searchPatientByMobile`, { mobile: obj.mobile }, { headers: headers })
    }else{
      return this.httpClient.post(`${environment.apiUrl}/staff/searchStaffPatientByMobile`, { mobile: obj.mobile }, { headers: headers })
    }
  }

  saveRequest(reqObj:any,file:File){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })

    const formdata = new FormData();
    formdata.append('data', JSON.stringify(reqObj)) ;
    formdata.append('file', file);

    if (this.user_data.role == "patient") {
      return this.httpClient.post(`${environment.apiUrl}/user/addServiceRequest`,formdata,{headers:headers})
    }
    else if (this.user_data.role == "admin"){
      return this.httpClient.post(`${environment.apiUrl}/admin/addServiceRequest`,formdata,{headers:headers})
    }
    else{
      return this.httpClient.post(`${environment.apiUrl}/staff/addServiceRequest`,formdata,{headers:headers})
    }
  }

  deleteRequest(obj){
      let headers = new HttpHeaders({
        'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      })

      return this.httpClient.post(`${environment.apiUrl}/admin/deleteServiceRequest`,{id:obj},{headers:headers})
  }

  getRequestById(obj){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })

    return  this.httpClient.post(`${environment.apiUrl}/admin/getServiceRequestByid`,{id:obj},{headers:headers})
  }

  updateRequest(obj){

    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
      let dt ={
        data:obj
      }
    

    return this.httpClient.post(`${environment.apiUrl}/admin/editServiceRequest`,dt,{headers:headers});
  }

  getRequestCountByCateogry(){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })

    if(this.user_data.role=='admin'){
    //return this.httpClient.post(`${environment.apiUrl}/request/getRequestCountByCateogry`,{},{headers:headers});
    return this.httpClient.post(`${environment.apiUrl}/admin/getBookingReport`,{},{headers:headers});
    }
    else{
      return this.httpClient.post(`${environment.apiUrl}/staff/getBookingReportFromStaff`,{},{});
    }

  }

  getPartnersToAssignRequest(obj){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    let dt ={
      data:obj
    }
    return this.httpClient.post(`${environment.apiUrl}/request/getPartnersToAssignRequest`,dt,{headers:headers});
  }

  assignRequestToPartner(data: any){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.apiUrl}/request/assignRequestToPartner`,data,{headers:headers});
 
  }

  loadServicesWorkflow(){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.apiUrl}/request/loadServicesWorkflow`,{},{headers:headers});
 
  }

  updateWorkFlow(obj){

    let dt = {
      data: obj
    }

    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.apiUrl}/request/updateWorkFlow`,dt,{headers:headers});
  }

  getMobileData(){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    return this.httpClient.post(`${environment.apiUrl}/admin/getAllPatientMobile`,{},{headers:headers});

  }

  getSubServices(id:any){
    if (this.role == "patient") {
      return this.httpClient.post(`${environment.apiUrl}/user/getSubServices`,{id:id},{headers:this.headers});
    }
    else if (this.role == "admin"){
      return this.httpClient.post(`${environment.apiUrl}/admin/getSubServices`,{id:id},{headers:this.headers});
    }
    else{
      return this.httpClient.post(`${environment.apiUrl}/staff/getSubServices`,{id:id},{headers:this.headers});
    }
  }
  
  getFilteredNumber(mob){
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    if(this.role == 'admin'){
    return this.httpClient.post(`${environment.apiUrl}/admin/searchPatientFilterMobile`,{mobile:mob},{headers:headers});
    }
    else{
      return this.httpClient.post(`${environment.apiUrl}/staff/searchStaffPatientFilterMobile`,{mobile:mob},{headers:headers});
    }
  }

  getFilteredName(mob){
    return this.httpClient.post(`${environment.apiUrl}/admin/searchPatientFilterName`,{name:mob},{});
  }


  getFilteredService(ser){
    if (this.role == "patient") {
      return this.httpClient.post(`${environment.apiUrl}/user/searchServiceNameFilter`,{serviceName:ser},{headers:this.headers});
    }
    else if(this.role == "admin"){
      return this.httpClient.post(`${environment.apiUrl}/admin/searchServiceNameFilter`,{serviceName:ser},{headers:this.headers});
    }
    else{
      return this.httpClient.post(`${environment.apiUrl}/staff/searchServiceNameFilter`,{serviceName:ser},{headers:this.headers});
    }
  }

  getProductUploadPrices(obj)
  {
    return this.httpClient.post(`${environment.apiUrl}/admin/getServProdPriceBySubServId`,obj,{headers:this.headers});
  }

  getSubplan(dt){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    
    
      return this.httpClient.post(`${environment.apiUrl}/plan/getSubPlans`,{_id:dt},{headers: headers});
    
  }
  
  getSubcoupon(dt){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    
    
      return this.httpClient.post(`${environment.apiUrl}/plan/getCouponsWeb`,{_id:dt},{headers: headers});
    
  }
  getServiceProductPriceBySubServiceId(dt){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    
    
      return this.httpClient.post(`${environment.apiUrl}/admin/getServProdPriceBySubServId`,dt,{headers: headers});
    
  }

  buyPlanWithCoupon(data){

    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
   // http://localhost:3000/user/buyPlanWeb
    
      return this.httpClient.post(`${environment.apiUrl}/user/buyPlanWeb`,{data},{headers: headers});

  }


  getlookup(){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    if(this.role=='admin')
    {
    return this.httpClient.post(`${environment.apiUrl}/plan/getPlans`,{},{headers: headers});
    }
    else
    {
      return this.httpClient.post(`${environment.apiUrl}/plan/getPlansApp`,{},{headers: headers});
    }
  }

  updateFeedback(obj,remarks){

    
    let headers=new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    })
    
    return this.httpClient.post(`${environment.apiUrl}/admin/addFeedback`,{_id: obj.workflow[obj.workflow.length-1]._id,
      remark:remarks,},{headers:headers});
    //return this.httpClient.post(`${environment.apiUrl}/request/updateFeedback`,dt,{headers:headers});
  }
}
