<div class="modal-header">
    <span class="pl-2">
        <mat-icon>warning</mat-icon>
    </span>
    <h3 class="modal-title" id="exampleModalLabel">( Session Timeout ! ) </h3>
    <button type="button" class="close pull-right" (mousedown)="onClose()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h6>
        <strong>Your Are Going To Expired After 15(mins), Please Try To login Again!</strong>
    </h6>
</div>
<div class="modal-footer d-flex justify-content-right">
    <button type="button" style="background-color: #ba9f04; color: white;" class="btn btn-lg btn-block"
        (mousedown)="onClose()">Ok</button>
</div>