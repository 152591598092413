import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Patient } from './patient.model';
import { HttpClient, HttpErrorResponse , HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import * as XLSX from 'xlsx';

@Injectable()
export class PatientService {
  private readonly API_URL = 'assets/data/patient.json';
  dataChange: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  private readonly headers = new HttpHeaders({
    'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
    //'Content-type': 'multipart/form-data'
  })
  private readonly user_data = JSON.parse(localStorage.getItem('user'));
  constructor(private httpClient: HttpClient) {}
  get data(): Patient[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  /** CRUD METHODS */
  getAllPatients(): void {
    if(this.user_data.role=="admin"){
    this.httpClient.post<any>(`${environment.apiUrl}/admin/getPatients`,{},{headers:this.headers}).subscribe(
      data => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
    }
    else{
      this.httpClient.post<any>(`${environment.apiUrl}/staff/getPatients`,{},{}).subscribe(
        data => {
          this.dataChange.next(data.data);
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
    }
  }

  exportToExcel(){
    let memberData;
    if(this.user_data.role=="admin"){
      this.httpClient.post<any>(`${environment.apiUrl}/admin/getPatients`,{},{headers:this.headers}).subscribe(
        data => {
          memberData = data.data;
          console.log(memberData);
          memberData.forEach(element => {
            element.Address = element.currentlocation.googleAddress
            delete element.id;
            delete element.img;
            delete element.createdAt;
            delete element.addresses;
            delete element.currentlocation;
            delete element.kyc;
            
          });
          const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(memberData);
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          
          /* save to file */
          XLSX.writeFile(wb, 'SheetJS.xlsx');
        },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        }
      );
      }
  }

  getParentIdMember(id){
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token,
      //'Content-type': 'multipart/form-data'
    })
    let patientId;
    if(this.user_data.role == "admin"){ 
         patientId=id;
         return this.httpClient
         .post(`${environment.apiUrl}/user/getParentIdMember`,{id: patientId },{headers: headers})
    }
    if(this.user_data.role == "patient") {
       patientId = this.user_data._id;
      
      return this.httpClient
      .post(`${environment.apiUrl}/user/getParentIdMember`,{id: patientId },{headers: headers})
    }
  }
  getSubMember(): void {
    let member_row=JSON.parse(localStorage.getItem("member_row"))
    this.httpClient.post<any>(`${environment.apiUrl}/admin/getSubMember`,{member_row},{headers:this.headers}).subscribe(
      data => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }
  // DEMO ONLY, you can find working methods below
  addPatient(patient: Patient): void {
    this.dialogData = patient;
  }
  updatePatient(id: any, patientObj: any) {
    return this.httpClient.post(`${environment.apiUrl}/admin/updatePatient`,{id: id, data: patientObj },{headers:this.headers})
  }

  deletePatientDialog(id: number) {
    return;
  }

  deletePatient(id: number) {
    return this.httpClient.post(`${environment.apiUrl}/admin/deletePatient`,{id: id},{headers:this.headers})
  }
}
