import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
//import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatChipsModule } from '@angular/material/chips';

import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { SessionmodelComponent } from "./layout/sessionmodel/sessionmodel.component"
import { MatTabsModule } from "@angular/material/tabs";

// Firebase Importing Start----------------------------------------------------------------------->>
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { NotificationService } from "./ServicesNotification/notification.service";
import { environment } from "../environments/environment";
import { AsyncPipe } from '../../node_modules/@angular/common';
// Firebase Importing Ending----------------------------------------------------------------------->>

import { DynamicScriptLoaderService } from "./shared/services/dynamic-script-loader.service";
import { ConfigService } from "./shared/services/config.service";
import { CalendarService } from "../app/apps/calendar/calendar.service";
import { AppointmentService } from "./admin/appointment/viewappointment/appointment.service";
import { AppointmentsService } from "./doctor/appointments/appointments.service";
import { UpcomingAppointmentService } from "./patient/appointments/upcoming-appointment/upcoming-appointment.service";
import { PastAppointmentService } from "./patient/appointments/past-appointment/past-appointment.service";
import { DoctorsService } from "./admin/doctors/alldoctors/doctors.service";
import { StaffService } from "./admin/staff/allstaff/staff.service";
import { PatientService } from "./admin/patients/allpatients/patient.service";
import { RoomService } from "./admin/room/allroom/room.service";
import { PaymentService } from "./admin/payment/allpayment/payment.service";
import { ContactsService } from "./apps/contacts/contacts.service";
import { RightSidebarService } from "./shared/services/rightsidebar.service";
import { AuthGuard } from "./shared/security/auth.guard";
import { AuthService } from "./shared/security/auth.service";
import { RequestService } from "./admin/requests/viewrequest/request.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { WatchfulheaderComponent } from "./layout/watchfulheader/watchfulheader.component";
import { WatchfulfooterComponent } from "./layout/watchfulfooter/watchfulfooter.component";
import { CallDialogComponent } from "./layout/header/dialog/call-dialog/call-dialog.component";
import { CloseCallDialogComponent } from "./layout/header/dialog/close-call-dialog/close-call-dialog.component";
import { CallDialComponent } from "./layout/header/dialog/call-dial/call-dial.component";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { MatIconModule } from "@angular/material/icon";
import {MatCardModule} from '@angular/material/card';
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMaskModule } from "ngx-mask";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { SimpleDialogComponent } from "./ui/modal/simpleDialog.component";
import { DialogformComponent } from "./ui/modal/dialogform/dialogform.component";
import { BottomSheetOverviewExampleSheet } from "./ui/bottom-sheet/bottom-sheet.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule } from "@angular/common/http";
// import { ClickOutsideModule } from "ng-click-outside";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SocketioService } from "../app/shared/services/socketio.service";
import { ConfirmEqualValidatorDirective } from "./shared/directives/confirm-equal-validator.directive";
// import { NgxIdleTimeoutModule } from 'ngx-idle-timeout';

// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

// optional, provides moment-style pipes for date formatting
import { MomentModule } from 'angular2-moment';

// Bootstrap Modal Module
import { ModalModule } from 'ngx-bootstrap/modal';

// JWT HelperServices
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

// import { initializeApp } from "firebase/app";
// initializeApp(environment.firebase);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};
@NgModule({
  declarations: [
    FooterComponent,
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    SessionmodelComponent,
    RightSidebarComponent,
    SimpleDialogComponent,
    DialogformComponent,
    BottomSheetOverviewExampleSheet,
    ConfirmEqualValidatorDirective,
    WatchfulheaderComponent,
    WatchfulfooterComponent,
    CallDialogComponent,
    CloseCallDialogComponent,
    CallDialComponent,
  ],
  imports: [
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule.withServerTransition({ appId: 'smosify' }),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ModalModule.forRoot(),
    // AngularFireMessagingModule,
    AppRoutingModule,
    NgbModule,
    MatTabsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatSidenavModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatMenuModule,
    // ClickOutsideModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCd6G5xiORsi2GZmLy2cR_G70iltfQSno4", //"AIzaSyCTQwK2RBGxU7afri42IPo5utNVr2C3C98", //"AIzaSyCFqx1L1qygtFEWpxSnsirQfUjlAv-MZ5A",
      libraries: ["places"],
    }),
    NgxMaterialTimepickerModule.setLocale("in-IN"),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService,
    DynamicScriptLoaderService,
    ConfigService,
    RightSidebarService,
    AppointmentService,
    DoctorsService,
    StaffService,
    PatientService,
    RoomService,
    PaymentService,
    ContactsService,
    CalendarService,
    AppointmentsService,
    UpcomingAppointmentService,
    PastAppointmentService,
    RequestService,
    AuthService,
    AuthGuard,
    SocketioService,
    NotificationService,
    AsyncPipe
  ],
  entryComponents: [
    SimpleDialogComponent,
    DialogformComponent,
    BottomSheetOverviewExampleSheet,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
