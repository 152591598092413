import { Component, OnInit, Inject, NgZone, ViewChild } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../../../environments/environment";
import { Router } from "@angular/router";
//import { FormBuilder, FormGroup, Validators,FormArray } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {take} from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-close-call-dialog',
  templateUrl: './close-call-dialog.component.html',
  styleUrls: ['./close-call-dialog.component.sass']
})
export class CloseCallDialogComponent implements OnInit {
  closeCallForm: FormGroup;
  dispositions: any;
  minDate:any;
  strTime:any;
  constructor(
    private router: Router,
    private _ngZone: NgZone,
    private dateAdapter: DateAdapter<Date>,
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<CloseCallDialogComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.closeCallForm = this.fb.group({
      group: ["", Validators.required],
      disposition: ["", Validators.required],
      callBackDuration: [""],
      callBackDate: [""],
      callBackTime: [""],
      remarks: ["", Validators.required]
    });

    this.dateAdapter.setLocale('en-GB');
    this.minDate = new Date();
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  ngOnInit(): void {
    let headers = new HttpHeaders({
      'Authorization': 'Bearer '+ (JSON.parse(localStorage.getItem('user'))).token
    })
  this.httpClient.post(`${environment.apiUrl}/api/getlookupByType`,{type:'DISPOSITION' },{headers: headers}).subscribe({
    next : (result: any) => {
      this.dispositions = result.data[0]!=null?result.data[0].details:[];
    },
    error : (err) => {
      this.showNotification("snackbar-danger", err.message, "top", "center");
    }
  });

  this.getTimes();
  }

  getTimes() {
    let hrData;
    var date = new Date();
    var hours = date.getHours();
    let minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (hours.toString().length == 1) {
      hrData = "0" + hours;
    } else {
      hrData = hours;
    }
    let minutess = minutes < 10 ? '0' + minutes : minutes;
    this.strTime = hrData + ':' + minutess + ' ' + ampm;
    console.log(this.strTime);
  }

  onSubmit() {
    this.dialogRef.close(this.closeCallForm.value);
  }
}
