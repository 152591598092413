import { DOCUMENT } from "@angular/common";
import { MatTabsModule } from "@angular/material/tabs";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { RightSidebarService } from "../../shared/services/rightsidebar.service";
import { ConfigService } from "../../shared/services/config.service";
import { AuthService } from "src/app/shared/security/auth.service";
import { Router } from "@angular/router";
//import { MatDialog } from "@angular/material/dialog";
import { CallDialogComponent } from "./dialog/call-dialog/call-dialog.component";
import { CloseCallDialogComponent } from "./dialog/close-call-dialog/close-call-dialog.component";
import { CallDialComponent } from "./dialog/call-dial/call-dial.component";
import { environment } from "../../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { interval, Subscription } from "rxjs";
import { timeout } from "rxjs/operators";
import { SocketioService } from "../../shared/services/socketio.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { NotificationserviceService } from "src/app/partner/partnernotification/notificationservice.service";
import { ModalComponent } from "../modal/modal.component";
import { AppointmentService } from "src/app/partner/appointment/appointment.service";
const document: any = window.document;
const seconds = interval(1000);

//import { environment } from "src/environments/environment";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { PartnerService } from "src/app/partner/partner.service";
import { log } from "console";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  imgUrl: any = `${environment.bucketUrl}`;
  loginUser: any = localStorage.getItem("FULLNAME");
  // loginUser: any= localStorage.getItem("FullName");
  // loginUser: any
  loginPartner: any = JSON.parse(localStorage.getItem("user"));
  private subscriptionName: Subscription;
  partnerBusinessImg: any;
  localCount: Number;
  serverCount: Number;
  public config: any = {};
  crmUrl: any;
  userImg: string;
  profilepic: string;
  homePage: string;
  notificationData: any = [];
  ambulanceData: EzimaxNotification[] = [];
  allNotificationData: any = [];
  myCallData: any[];
  userRole: string;
  headerCall: any;
  newMessage: string;
  notificationCount: any;
  getAllnoticationCount : any =[];
  messageList: string[] = [];
  notificationList: EzimaxNotification[] = [];
  user_data: any = {};
  isPublishData = false;
  PartnersData: any = {};
  lotsOfTabs: any;
  notificationtype: any;
  appointmentNotification: any = [];
  voucherNotification: any = [];
  memberNotification: any = [];
  productNotification: any = [];
  reviewNotification: any = [];
  stockNotification: any = [];
  ReadAll: any;
  not_Id : any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private configService: ConfigService,
    private authService: AuthService,
    private partnerService: PartnerService,
    public dialog: MatDialog,
    public router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private socketService: SocketioService,
    private notificationservice: NotificationserviceService,
    private AppointmentService: AppointmentService
  ) {
    // subscribe to sender component messages
    this.subscriptionName = this.partnerService
      .getUpdate()
      .subscribe((data) => {
        //message contains the data sent from service
        this.loginPartner = data.loginPartner;
        this.userImg = `${this.imgUrl + data.loginPartner.image.fileName}`;
        localStorage.setItem("USERIMG", data.loginPartner.image.fileName);
      });
  }
  notifications: any[] = [
    // {
    //   userImg: "assets/images/user/user1.jpg",
    //   userName: "Sarah Smith",
    //   time: "14 mins ago",
    //   message: "Please check your mail",
    // },
  ];
  ngOnInit() {
    this.getAppointmentNotification();
    this.getVoucherNotification();
    this.getMemberShipNotification();
    this.getProductNotification();
    this.getReviewtNotification();
    this.getStockNotification();
    this.getAllPartnerNotificationsCount();
    this.lotsOfTabs = new Array(4).fill(0).map((_, index) => `Tab ${index}`);
    this.localCount = Math.floor(Math.random() * 100 + 1);
    this.serverCount = Math.floor(Math.random() * 100 + 1);
    this.config = this.configService.configData;
    this.userImg = this.authService.getUserImg();
    this.userRole = this.authService.getRole();
    this.homePage = "/";
    if (localStorage.getItem("headerCall") == "true") {
      this.headerCall = true;
    } else {
      this.headerCall = false;
    }
    let userData = JSON.parse(localStorage.getItem("user"));
    let terminalData = localStorage.getItem("userTerminalId");

    this.notificationservice
      .getNotificationlookupByType("Notification Type")
      .subscribe({
        next: (result: any) => {
          this.notificationtype = result.data;
          // console.log(this.notificationstatus)
        },
        error: (err) => {
          this.showNotification(
            "snackbar-danger",
            err.message,
            "top",
            "center"
          );
        },
      });

    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)
          if (result.ResultCode == "-1") {
            terminalData = null;
          } else {
            if (
              terminalData != null &&
              terminalData != "" &&
              terminalData != undefined
            ) {
              this.crmUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${terminalData}`
              );
            }
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    this.http
      .get(
        `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
        {}
      )
      .subscribe({
        next: (res: any) => {
          if (res.status == "success") {
            this.notificationData = res.data;
            this.ambulanceData = res.data;
            this.ambulanceData = this.ambulanceData.filter(
              (p) => p.serviceType == "Ambulance"
            );
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    this.callMemberNotification();
    this.socketService.setupSocketConnection();
    this.getAmbulanceNotification();
    this.loadNotification();

    this.socketService.getNotificationForVoucherSell().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForProductSell().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForRecorderPoint().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForComplete().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForReschedule().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForCancel().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForAdd().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForCheckout().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForClientAdd().subscribe((data) => {
      this.loadNotification();
    });

    this.socketService.getNotificationForStaffAdd().subscribe((data) => {
      this.loadNotification();
    });

    this.user_data = JSON.parse(localStorage.getItem("user"));
    const id = this.user_data._id;
    this.authService.getPartnerDataId(id).subscribe({
      next: (result: any) => {
        this.PartnersData = result;
        this.isPublishData = result.isPublished;
      },
    });
  }

  ngOnDestroy() {
    // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }

  loadNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    this.http
      .post(
        `${environment.apiUrl}/partner/getNotificationsByPartner`,
        { partnerId: user_data._id },
        { headers: headers }
      )
      .subscribe({
        next: (res: any) => {
          if (res.status == "Success") {
            this.notifications = res.data;
            // this.notificationCount = res.data.filter((dx) => {
            //   if (dx.isRead == false) {
            //     return true;
            //   }
            //   return false;
            // }).length;
            // console.log("notifications ", this.notifications);
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  playAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/ambu.wav";
    audio.load();
    audio.play();
  }

  openAmbulanceModal(message: EzimaxNotification) {
    const productId = "prod01";
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "400px";
    dialogConfig.width = "400px";
    dialogConfig.data = {
      name: "Emergency Request",
      title: "Emergency Request",
      description:
        "New Emergency Request from " +
        message.mobileNo +
        " " +
        message.firstName,
      actionButtonText: "Cancel",
      // productId: message.callnumber,
      productId: 9267980180,
    };
    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.dialog.open(ModalComponent, dialogConfig);
  }

  openRequestModal(message: EzimaxNotification) {
    const productId = "prod02";
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "400px";
    dialogConfig.width = "400px";
    dialogConfig.data = {
      name: "New Service Request",
      title: "New Service Request",
      description:
        "New Service Request from " +
        message.mobileNo +
        " " +
        message.firstName,

      actionButtonText: "Cancel",
      // productId: message.callnumber,
      productId: 9267980180,
    };
    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.dialog.open(ModalComponent, dialogConfig);
  }

  openEnquiryModal(message: EzimaxNotification) {
    const productId = "prod02";
    const dialogConfig = new MatDialogConfig();
    // The user can't close the dialog by clicking outside its body
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.height = "400px";
    dialogConfig.width = "400px";
    dialogConfig.data = {
      name: "New Enquiry",
      title: "New Enquiry",
      description: "New Enquiry from " + message.mobileNo,

      actionButtonText: "Cancel",
      // productId: message.callnumber,
      productId: 9267980180,
    };
    // https://material.angular.io/components/dialog/overview
    const modalDialog = this.dialog.open(ModalComponent, dialogConfig);
  }

  getAmbulanceNotification() {
    this.socketService
      .getAmbulanceNotification()
      .subscribe((message: EzimaxNotification) => {
        // console.log(message);
        let userData = JSON.parse(localStorage.getItem("user"));
        if (
          userData.mobile == message.hostid &&
          message.calltype != "M" &&
          (localStorage.getItem("isConf") == undefined ||
            localStorage.getItem("isConf") == "null")
        ) {
          if (message.serviceType == "Ambulance") {
            this.playAudio();
            this.openAmbulanceModal(message);
            this.showNotification(
              "snackbar-danger",
              "New Ambulance Request from " +
              message.mobileNo +
              " " +
              message.firstName,
              "top",
              "center"
            );
            this.openMember(message);
          } else if (message.notificationType == "Service Request") {
            this.openRequestModal(message);
            this.showNotification(
              "snackbar-success",
              "New  Service Request from " +
              message.mobileNo +
              " " +
              message.firstName,
              "top",
              "center"
            );
            this.openMember(message);
          } else {
            this.openEnquiryModal(message);
            this.showNotification(
              "snackbar-success",
              "New  Enquiry from " + message.mobileNo + " " + message.firstName,
              "top",
              "center"
            );
            this.openMember(message);
          }
        }

        // this.messageList.push(message);
        this.notificationList = [];

        this.notificationList.push(message);
      });
  }

  callMemberNotification() {
    setTimeout(() => {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.http
        .get(
          `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
          {}
        )
        .subscribe({
          next: (res: any) => {
            if (res.status == "success") {
              this.notificationData = res.data;
              this.ambulanceData = res.data;
              this.ambulanceData = this.ambulanceData.filter(
                (p) => p.serviceType == "Ambulance"
              );
              var myData = this.notificationData[0];
              // console.log(myData);
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    }, 1000);
  }

  showAllNotification() {
    if (this.router.url.includes("/partner/")) {
      this.router.navigate(["/partner/partnernotification/viewnotification"]);
    } else if (this.router.url.includes("/staff/")) {
      this.router.navigate(["/staff/partnernotification/viewnotification"]);
    }
  }

  ngAfterViewInit() {
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }

  openCallDialog() {
    let row = {
      name: "test",
      age: "test",
      initLine: "I am for voice call",
    };
    const dialogRef = this.dialog.open(CallDialogComponent, {
      data: row,
      width: "1024px",
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.headerCall = true;
      let userData = JSON.parse(localStorage.getItem("user"));
      let terminalData = localStorage.getItem("userTerminalId");
      this.httpClient
        .get(
          `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
        )
        .subscribe({
          next: (result: any) => {
            // console.log(result)

            if (result.ResultCode == "-1") {
              terminalData = null;
            } else {
              if (
                terminalData != null &&
                terminalData != "" &&
                terminalData != undefined
              ) {
                this.crmUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                  `${environment.crmUrl}/panel?agent_login_id=${userData.mobile}&agent_terminal_id=${terminalData}`
                );
              }
            }
          },
          error: (err) => {
            console.log(err);
          },
        });

      this.callNotification();
      this.callNotificationPartner();
    });
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar =
        !this.dataService.currentStatus._isScalar)
    );
  }

  logout() {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)
          if (result.ResultCode == "-1") {
            // console.log("Nothing to logout");
          } else {
            this.httpClient
              .get(
                `${environment.crmUrl}/api/cti/logout?hostId=${userData.mobile}&tspltoken=${result.ResultString}`
              )
              .subscribe({
                next: (result: any) => {
                  if (result.ResultCode == "0") {
                    // console.log("Log out successfully!");
                  }
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    localStorage.clear();

    // console.log("calling logout");
    if (this.router.url.includes("/admin/")) {
      this.authService.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/admin/authentication/signin"]);
        }
      });
    } else if (this.router.url.includes("/staff/")) {
      this.authService.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/staff/authentication/signin"]);
        }
      });
    } else {
      this.authService.logout().subscribe((res) => {
        if (!res.success) {
          this.router.navigate(["/partner/authentication/signin"]);
        }
      });
    }
  }

  callNotification() {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.http
      .get(
        `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
        {}
      )
      .subscribe({
        next: (res: any) => {
          if (res.status == "success") {
            this.notificationData = res.data;
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    $(".dropdown-menu.noti-fs.pullDown").toggleClass("show");
  }

  close() {
    $(".dropdown-menu.noti-fs.pullDown").removeClass("show");
  }

  callNotificationPartner() {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.http
      .get(
        `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
        {}
      )
      .subscribe({
        next: (res: any) => {
          if (res.status == "success") {
            this.notificationData = res.data;
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  closeCall(mMobile) {
    let userData = JSON.parse(localStorage.getItem("user"));
    let row = {
      name: "close call",
    };
    const dialogRef = this.dialog.open(CloseCallDialogComponent, {
      data: row,
      width: "600px",
      height: "700px",
    });

    dialogRef.afterClosed().subscribe((callClosedata) => {
      // console.log(callClosedata);
      this.httpClient
        .get(
          `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
        )
        .subscribe({
          next: (result: any) => {
            // console.log(result)
            if (result.ResultCode == "-1") {
              console.log("Nothing to Call");
            } else {
              this.http
                .get(
                  `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
                  {}
                )
                .subscribe({
                  next: (res: any) => {
                    if (
                      res.status == "success" &&
                      localStorage.getItem("isConf") != undefined &&
                      localStorage.getItem("isConf") != "null"
                    ) {
                      this.myCallData = res.data;
                      var myData = this.myCallData[0];

                      this.httpClient
                        .get(
                          //`${environment.crmUrl}/api/cti/MakeCall?dni=69020001&cli=${mMobile}&addon=&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                          `${environment.crmUrl}/api/cti/SendPbxDigits?digits=L:all&callNumber=${myData.callnumber}&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                        )
                        .subscribe({
                          next: (r: any) => {
                            localStorage.setItem("isConf", null);
                            this.httpClient
                              .get(
                                //`${environment.crmUrl}/api/cti/MakeCall?dni=69020001&cli=${mMobile}&addon=&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                                `${environment.crmUrl}/api/cti/CloseCall?disposition=${callClosedata.disposition}&nextDialTime=&remarks=${callClosedata.remarks}&callNumber=${myData.callnumber}&hostId=${userData.mobile}&extraParameters&tspltoken=${result.ResultString}`
                              )
                              .subscribe({
                                next: (result: any) => {
                                  localStorage.setItem("manualCall", null);
                                  if (result.ResultCode == "0") {
                                    console.log(
                                      "Call Closed" + myData.mobileNo
                                    );
                                    this.showNotification(
                                      "snackbar-success",
                                      "Call Closed for " + myData.mobileNo,
                                      "top",
                                      "center"
                                    );
                                  } else {
                                    this.showNotification(
                                      "snackbar-danger",
                                      "Before you close the.Call the Member or Wait for New Call!",
                                      "top",
                                      "center"
                                    );
                                  }
                                },
                              });
                          },
                        });
                    } else if (res.status == "success") {
                      this.myCallData = res.data;
                      var myData = this.myCallData[0];
                      this.httpClient
                        .get(
                          //`${environment.crmUrl}/api/cti/MakeCall?dni=69020001&cli=${mMobile}&addon=&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                          `${environment.crmUrl}/api/cti/CloseCall?disposition=${callClosedata.disposition}&nextDialTime=&remarks=${callClosedata.remarks}&callNumber=${myData.callnumber}&hostId=${userData.mobile}&extraParameters&tspltoken=${result.ResultString}`
                        )
                        .subscribe({
                          next: (result: any) => {
                            localStorage.setItem("manualCall", null);
                            if (result.ResultCode == "0") {
                              // console.log("Call Closed" + myData.mobileNo);
                              this.showNotification(
                                "snackbar-success",
                                "Call Closed for " + myData.mobileNo,
                                "top",
                                "center"
                              );
                            } else {
                              this.showNotification(
                                "snackbar-danger",
                                "Before you close the.Call the Member or Wait for New Call!",
                                "top",
                                "center"
                              );
                            }
                          },
                        });
                    }
                  },
                  error: (err) => {
                    console.log(err);
                  },
                });
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    });
  }

  callHold() {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)

          if (result.ResultCode == "-1") {
            // console.log("Nothing to Call");
          } else {
            this.http
              .get(
                `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
                {}
              )
              .subscribe({
                next: (res: any) => {
                  if (res.status == "success") {
                    this.myCallData = res.data;
                    var myData = this.myCallData[0];
                    this.httpClient
                      .get(
                        `${environment.crmUrl}/api/cti/SendPbxDigits?digits=C&callNumber=${myData.callnumber}&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                      )
                      .subscribe({
                        next: (result: any) => {
                          if (result.ResultCode == "0") {
                            // console.log("Call Closed" + myData.mobileNo);
                            this.showNotification(
                              "snackbar-success",
                              "Call is on hold for " + myData.mobileNo,
                              "top",
                              "center"
                            );
                          } else {
                            this.showNotification(
                              "snackbar-danger",
                              "Before you hold the call.Calling state must be in talk state!",
                              "top",
                              "center"
                            );
                          }
                        },
                      });
                  }
                },
                error: (err) => {
                  console.log(err);
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  callHang() {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)

          if (result.ResultCode == "-1") {
            // console.log("Nothing to Call");
          } else {
            this.http
              .get(
                `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
                {}
              )
              .subscribe({
                next: (res: any) => {
                  if (res.status == "success") {
                    this.myCallData = res.data;
                    var myData = this.myCallData[0];
                    this.httpClient
                      .get(
                        `${environment.crmUrl}/api/cti/SendPbxDigits?digits=R&callNumber=${myData.callnumber}&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                      )
                      .subscribe({
                        next: (result: any) => {
                          if (result.ResultCode == "0") {
                            // console.log("Call Hanged for " + myData.mobileNo);
                            this.showNotification(
                              "snackbar-success",
                              "Call is on Hang for " + myData.mobileNo,
                              "top",
                              "center"
                            );
                          } else {
                            this.showNotification(
                              "snackbar-danger",
                              "Before you hold the call.Calling state must be in talk state!",
                              "top",
                              "center"
                            );
                          }
                        },
                      });
                  }
                },
                error: (err) => {
                  console.log(err);
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  callManualstart(mMobile) {
    let userData = JSON.parse(localStorage.getItem("user"));
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)

          if (result.ResultCode == "-1") {
            // console.log("Nothing to Call");
          } else {
            this.httpClient
              .get(
                `${environment.crmUrl}/api/cti/MakeCall?dni=69020001&cli=${mMobile}&addon=&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
              )
              .subscribe({
                next: (result: any) => {
                  if (result.ResultCode == "0") {
                    // console.log("calling to " + mMobile);
                    this.showNotification(
                      "snackbar-success",
                      "calling to " + mMobile,
                      "top",
                      "center"
                    );
                  } else {
                    this.showNotification(
                      "snackbar-danger",
                      "First Login to the Dialer!",
                      "top",
                      "center"
                    );
                  }
                  localStorage.setItem("manualCall", null);
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    //https://crm.watchfuleye.in/api/cti/MakeCall?dni=69020001&cli=9650362242&addon=&hostId=9811635908&tspltoken=be438daa-a86a-4375-8613-55e2883d0dc7
  }

  callDial() {
    let row = {
      name: "close call",
    };
    const dialogRef = this.dialog.open(CallDialComponent, {
      data: row,
      width: "1024px",
    });

    dialogRef.afterClosed().subscribe((s) => {
      let userData = JSON.parse(localStorage.getItem("user"));
      let manualCall = localStorage.getItem("manualCall");
      if (manualCall == undefined || manualCall == "null" || manualCall == "") {
        // this.showNotification(
        //   "snackbar-danger",
        //   "Dail SIP Mobile Number not passed!",
        //   "top",
        //   "center"
        // );
        return;
      }

      if (manualCall != "" && s.callType == "manual") {
        this.callManualstart(manualCall);
        localStorage.setItem("manualCall", null);
        return;
      }

      this.httpClient
        .get(
          `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
        )
        .subscribe({
          next: (result: any) => {
            // console.log(result)

            if (result.ResultCode == "-1") {
              // console.log("Nothing to Call");
            } else {
              this.http
                .get(
                  `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
                  {}
                )
                .subscribe({
                  next: (res: any) => {
                    if (res.status == "success") {
                      this.myCallData = res.data;
                      var myData = this.myCallData[0];
                      this.httpClient
                        .get(
                          `${environment.crmUrl}/api/cti/SendPbxDigits?digits=${manualCall}&callNumber=${myData.callnumber}&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                        )
                        .subscribe({
                          next: (result: any) => {
                            if (result.ResultCode == "0") {
                              // console.log("Calling on " + manualCall);
                              this.showNotification(
                                "snackbar-success",
                                "Calling on " + manualCall,
                                "top",
                                "center"
                              );
                            } else {
                              this.showNotification(
                                "snackbar-danger",
                                "Before you Call.Calling state must be in hold state!",
                                "top",
                                "center"
                              );
                            }
                          },
                        });
                    }
                  },
                  error: (err) => {
                    console.log(err);
                  },
                });
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
    });
    this.showNotification("snackbar-success", "Call Dial!", "top", "center");
  }

  callConference() {
    let userData = JSON.parse(localStorage.getItem("user"));
    let manualCall = localStorage.getItem("manualCall");
    this.showNotification(
      "snackbar-danger",
      "Dail SIP Mobile Number not passed!",
      "top",
      "center"
    );
    this.httpClient
      .get(
        `${environment.crmUrl}/api/cti/GetAgentToken?hostId=${userData.mobile}`
      )
      .subscribe({
        next: (result: any) => {
          // console.log(result)

          if (result.ResultCode == "-1") {
            // console.log("Nothing to Call");
          } else {
            this.http
              .get(
                `${environment.apiUrl}/user/getTopCallNotification?mobile=${userData.mobile}`,
                {}
              )
              .subscribe({
                next: (res: any) => {
                  if (res.status == "success") {
                    this.myCallData = res.data;
                    var myData = this.myCallData[0];
                    this.httpClient
                      .get(
                        `${environment.crmUrl}/api/cti/SendPbxDigits?digits=C&callNumber=${myData.callnumber}&hostId=${userData.mobile}&tspltoken=${result.ResultString}`
                      )
                      .subscribe({
                        next: (result: any) => {
                          if (result.ResultCode == "0") {
                            // console.log("Conferencing Call " + manualCall);
                            this.showNotification(
                              "snackbar-success",
                              "Call Conferenced with  " + manualCall,
                              "top",
                              "center"
                            );
                            localStorage.setItem("manualCall", null);
                            localStorage.setItem("isConf", "true");
                          } else {
                            this.showNotification(
                              "snackbar-danger",
                              "Before you hold the call.Calling state must be in talk state!",
                              "top",
                              "center"
                            );
                          }
                        },
                      });
                  }
                },
                error: (err) => {
                  console.log(err);
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });

    this.showNotification(
      "snackbar-success",
      "Call Conference!",
      "top",
      "center"
    );
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  profileEdit() {
    if (this.router.url.includes("/admin/")) {
      this.router.navigate(["/admin/profile"]);
    } else if (this.router.url.includes("/staff/")) {
      this.router.navigate(["/staff/my-profile"]);
    } else if (this.router.url.includes("/partner/")) {
      //this.router.navigate(["/partner/my-profile"]);
      this.router.navigate(["/partner/setup/View-Setup"]);
    } else {
      this.router.navigate(["/patient/my-profile"]);
    }
  }

  addBeneficiary() {
    if (this.router.url.includes("/admin/")) {
      this.router.navigate(["/admin/patients/add-member"]);
    } else if (this.router.url.includes("/staff/")) {
      this.router.navigate(["/staff/add-member"]);
    } else {
      this.router.navigate(["/patient/add-member"]);
    }
  }

  redirect(not) {
    // console.log(not);
    // localStorage.setItem("notMobile", not.mobileNo);
    // localStorage.setItem("notInqMobile", not.mobileNo);
    // const isEmpty = Object.keys(not).length === 0;
    // if(!isEmpty){
    //   this.router.navigate(["/partner/calenderBooking/addAppointment"]);
    // }
    // else{
    //   this.router.navigate(["/partner/inventory/addsupplier"]);
    // }

    // if (not.notificationType == "Service Request") {
    //   if (this.router.url.includes("/admin/")) {
    //     this.router.navigate(["/admin/requests/bookRequest"]);
    //   } else if (this.router.url.includes("/staff/")) {
    //     this.router.navigate(["/staff/bookRequest"]);
    //   }
    // } else if (not.notificationType == "Enquiry") {
    //   if (this.router.url.includes("/admin/")) {
    //     this.router.navigate(["/admin/enquiry/addenquiry"]);
    //   } else if (this.router.url.includes("/staff/")) {
    //     this.router.navigate(["/staff/addenquiries"]);
    //   }
    // }

    // this.router.navigate(['/admin/requests/bookRequest']);

    let user_data = JSON.parse(localStorage.getItem("user"));
    let role = user_data.role;
    let routePrefix;

    if (role == "admin") {
      routePrefix = "admin";
    } else {
      routePrefix = "partner";
    }

    let headers = new HttpHeaders({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      //'Content-type': 'multipart/form-data'
    });

    let dt = {
      _id: not._id,
    };

    this.http
      .post(`${environment.apiUrl}/admin/updateNotificationRead`, dt, {
        headers: headers,
      })
      .subscribe({
        next: (res: any) => {
          if (res.status == "Success") {
            // let path = `${routePrefix}${not.ui_route}`;
            //    console.log(path);
            //    this.router.navigate([path]);
            if (this.router.url.includes("/partner/")) {
              this.router.navigate([
                "/partner/partnernotification/viewnotification",
              ]);
            } else if (this.router.url.includes("/staff/")) {
              this.router.navigate([
                "/staff/partnernotification/viewnotification",
              ]);
            }

            this.http
              .post(
                `${environment.apiUrl}/partner/getNotificationsByPartner`,
                { partnerId: user_data._id },
                { headers: headers }
              )
              .subscribe({
                next: (res: any) => {
                  if (res.status == "Success") {
                    // this.notificationCount = res.data.filter((dx) => {
                    //   if (dx.isRead == false) {
                    //     return true;
                    //   }
                    //   return false;
                    // }).length;
                  }
                },
                error: (err) => {
                  console.log(err);
                },
              });
          }
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  openMember(not) {
    localStorage.setItem("notMobile", not.mobileNo);
    localStorage.setItem("notInqMobile", not.mobileNo);

    if (not.notificationType == "Service Request") {
      if (this.router.url.includes("/admin/")) {
        this.router.navigate(["/admin/requests/bookRequest"]);
      } else if (this.router.url.includes("/staff/")) {
        this.router.navigate(["/staff/bookRequest"]);
      }
    } else if (not.notificationType == "Enquiry") {
      if (this.router.url.includes("/admin/")) {
        this.router.navigate(["/admin/enquiry/addenquiry"]);
      } else if (this.router.url.includes("/staff/")) {
        this.router.navigate(["/staff/addenquiries"]);
      }
    }

    // this.router.navigate(['/admin/requests/bookRequest']);
  }

  callisPublish() {
    this.user_data = JSON.parse(localStorage.getItem("user"));
    const id = this.user_data._id;
    this.authService.getAllIsPublishedPartner(id).subscribe({
      next: (result: any) => {
        if (result.status == "success") {
          this.showNotification(
            "snackbar-success",
            result.message,
            "top",
            "center"
          );
        } else {
          this.showNotification(
            "snackbar-danger",
            result.message,
            "top",
            "center"
          );
        }
      },
      error: (err) => {
        this.showNotification("snackbar-danger", err.message, "top", "center");
      },
    });
  }

  // callunPublish(){
  //   this.user_data = JSON.parse(localStorage.getItem('user'));
  //   const id = this.user_data._id;
  //   this.authService.getAllunPublishedPartner(id).subscribe({
  //     next: (result: any) => {
  //       if (result.status == "success") {
  //         this.showNotification(
  //           "snackbar-primary",
  //           result.message,
  //           "top",
  //           "center"
  //         );
  //       }else {
  //         this.showNotification(
  //           "snackbar-danger",
  //           result.message,
  //           "top",
  //           "center"
  //         );
  //       }

  //     },
  //     error: (err) => {
  //       this.showNotification("snackbar-danger", err.message, "top", "center");
  //     },
  //   });
  // }

  getAppointmentNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getAppointmentNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.appointmentNotification = res.data;

          // let Array = this.appointmentNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.appointmentNotification.length;
          // console.log("appointmentNotification ", this.notificationCount);
        
        }
      },
    });
  }

  getVoucherNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getVoucherNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.voucherNotification = res.data;
          // let Array = this.voucherNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.voucherNotification.length;
          // console.log("voucherNotification ", this.notificationCount);
        }
      },
    });
  }

  getMemberShipNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getMemberShipNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.memberNotification = res.data;
          // let Array = this.memberNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount =  Array.length
          // this.notificationCount =
          //   this.notificationCount + this.memberNotification.length;
          // console.log("memberNotification ", this.notificationCount);
        }
      },
    });
  }

  goDashbord() {
    const user_data = JSON.parse(localStorage.getItem("user"));
    // window.location.href = environment.publicsocialurl;
    if (user_data.role == "admin") {
      this.router.navigate(["admin/dashboard/main"]);
    } else if (user_data.role == "partner") {
      this.router.navigate(["/partner/dashboard"]);
    }
  }

  getProductNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getProductNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.productNotification = res.data;
          // let Array = this.productNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.productNotification.length;
          // console.log("productNotification ", this.notificationCount);
        }
      },
    });
  }

  getReviewtNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getReviewtNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.reviewNotification = res.data;
          // let Array = this.reviewNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.reviewNotification.length;
          // console.log("reviewNotification ", this.notificationCount);
        }
      },
    });
  }

  getStockNotification() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getStockNotification(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.stockNotification = res.data;
          // let Array = this.stockNotification.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.stockNotification.length;
        }
      },
    });
  }

  gotoappointment(dt) {
    
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.AppointmentService.setNewUserInfo(dt)
    this.router.navigate(["partner/appointment/viewappointment"]);
  }

  MarkAsReadAll() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.MarkAsReadAll(id).subscribe({
      next: (res: any) => {
        if (res.status == "succcess") {
          this.ReadAll = res;
        }
      },
    });
  }

  // gotoappointment(dt) {
  //   console.log("grid")
  //   this.router.navigate(["partner/appointment/viewappointment"], { queryParams: { completeTab: dt } }
  //   )
  //   localStorage.setItem('completeTab', 'dt')
  //   // this.selectedIndex = 4;
  // }


  gotovoucherSales(dt) {
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.router.navigate(["partner/sales/salevoucher"]);
  }

  gotomembershipSales(dt) {
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.router.navigate(["partner/sales/salemember"]);
  }

  gotoproductSales(dt) {
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.router.navigate(["partner/sales/saleproduct "]);
  }

  gotoReviewSales(dt) {
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.router.navigate(["partner/reviews/viewreview"]);
  }

  gotoStock(dt) {
    this.notificationservice.getMarkedNotification(dt._id).subscribe({
      next: (res: any) => {
        if (res.status == "Success") {
         console.log("marked");
         this.getAllPartnerNotificationsCount();

        }
      },
    });
    this.router.navigate(["partner/inventory/viewproduct"]);
  }

  // callunPublish(){
  //   this.user_data = JSON.parse(localStorage.getItem('user'));
  //   const id = this.user_data._id;
  //   this.authService.getAllunPublishedPartner(id).subscribe({
  //     next: (result: any) => {
  //       if (result.status == "success") {
  //         this.showNotification(
  //           "snackbar-primary",
  //           result.message,
  //           "top",
  //           "center"
  //         );
  //       }else {
  //         this.showNotification(
  //           "snackbar-danger",
  //           result.message,
  //           "top",
  //           "center"
  //         );
  //       }

  //     },
  //     error: (err) => {
  //       this.showNotification("snackbar-danger", err.message, "top", "center");
  //     },
  //   });
  // }

  /////////////////////// drop down pages route ////////////////////////
  goToVoucherPage() {
    this.router.navigate(["/partner/voucher/viewvoucher"]);
  }

  goToVoucherAdd() {
    this.router.navigate(["/partner/voucher/addvoucher"]);
  }
  goToPlanPage() {
    this.router.navigate(["/partner/membership/viewmembership"]);
  }
  goToAddPlanPage() {
    this.router.navigate(["/partner/membership/addmembership"]);
  }
  goToPackagePage() {
    this.router.navigate(["/partner/package/viewpackage"]);
  }
  goToAddPackagePage() {
    this.router.navigate(["/partner/package/addpackage"]);
  }

  goToProductsPage() {
    this.router.navigate(["/partner/inventory/viewproduct"]);
  }

  goToAddProductsPage() {
    this.router.navigate(["/partner/inventory/addproduct"]);
  }

  goToStaffPage() {
    this.router.navigate(["/partner/partnerstaff/allStaff"]);
  }

  goToAddStaffPage() {
    this.router.navigate(['partner/partnerstaff/add-staff']);
  }

  goToServiesPage() {
    this.router.navigate(["/partner/services/all-services"]);
  }
  goToAddServiesPage() {
    this.router.navigate(["/partner/services/add-services"]);
  }

  goToClientPage() {
    this.router.navigate(["/partner/client/viewClient"]);
  }

  goToAddClientPage() {
    this.router.navigate(["/partner/client/addClient"]);
  }


  getAllPartnerNotificationsCount() {
    let user_data = JSON.parse(localStorage.getItem("user"));
    const id = user_data._id;
    this.notificationservice.getAllPartnerNotificationsCount(id).subscribe({
      next: (res: any) => {
        if (res.status == "success") {
          this.getAllnoticationCount = res.data;
          this.notificationCount =this.getAllnoticationCount.length
          this.not_Id = this.getAllnoticationCount._id;
          // let Array = this.getAllnoticationCount.filter(function (item) {
          //   if (item.isRead == false) {
          //     return item
          //   }
              
          // });
          // this.notificationCount = Array.length
          // this.notificationCount =
          //   this.notificationCount + this.stockNotification.length;
        }
      },
    });
  }

}

export class EzimaxNotification {
  notificationType: String;
  mobileNo: String;
  extensionCode: String;
  agentid: String;
  hostid: String;
  callnumber: String;
  serviceid: String;
  calltype: String;
  serviceType: String;
  firstName: String;
  lastName: String;
}
