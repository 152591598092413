import { AuthGuard } from "./shared/security/auth.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Role } from "./shared/security/role";
import { WatchfuleyeModule } from "./watchfuleye/watchfuleye.module";
import { SignupComponent } from "./partner/signup/signup.component";
import { PaymentlinkComponent } from "../app/partner/paymentlink/paymentlink.component";
import { BookingtermsandconditionsComponent } from "../app/admin/bookcalender/bookingtermsandconditions/bookingtermsandconditions.component";
const routes: Routes = [
  {
    path: "partner/paymentlink/:type/:id",
    component: PaymentlinkComponent,
  },
  {
    path: "booking-cancel-terms-&-conditions",
    component: BookingtermsandconditionsComponent,
  },
  {
    path: "admin/authentication",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "admin",
    canActivate: [AuthGuard],
    data: {
      role: Role.Admin,
    },
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "doctor",
    canActivate: [AuthGuard],
    data: {
      role: Role.Doctor,
    },
    loadChildren: () =>
      import("./doctor/doctor.module").then((m) => m.DoctorModule),
  },

  {
    path: "patient",
    canActivate: [AuthGuard],
    data: {
      role: Role.Patient,
    },
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
  },
  {
    path: "staff/authentication",
    loadChildren: () =>
      import("./staff/staff.module").then((m) => m.StaffModule),
  },
  {
    path: "staff",
    canActivate: [AuthGuard],
    data: {
      // role: `${Role.Executive},${Role.Supervisor},${Role.Manager}`,
      role: `${Role.SalesZonalManager},${Role.SalesAreaManager},${Role.RelationshipManger},${Role.SalesExecutive},${Role.SupportZonalManger},${Role.SupportAreManger},${Role.SupportManagaer},${Role.SeniorHelpDeskExecutive}, ${Role.HelpDeskExecutive}`,
    },
    loadChildren: () =>
      import("./staff/staff.module").then((m) => m.StaffModule),
  },
  {
    path: "partner/authentication",
    loadChildren: () =>
      //   // import("./authentication/authentication.module").then(
      //   //   (m) => m.AuthenticationModule
      //   // ),
      import("./partner/partner.module").then((m) => m.PartnerModule),
  },
  {
    path: "feedback",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./admin/feedback.module").then((m) => m.FeedbackModule),
  },
  {
    path: "partner",
    canActivate: [AuthGuard],
    data: {
      role: `${Role.Partner}`,
    },
    loadChildren: () =>
      import("./partner/partner.module").then((m) => m.PartnerModule),
  },
  {
    path: "email",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./email/email.module").then((m) => m.EmailModule),
  },
  {
    path: "calendar",
    canActivate: [AuthGuard],
    loadChildren: () => import("./apps/apps.module").then((m) => m.AppsModule),
  },
  {
    path: "apps",
    canActivate: [AuthGuard],
    loadChildren: () => import("./apps/apps.module").then((m) => m.AppsModule),
  },
  {
    path: "widget",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./widget/widget.module").then((m) => m.WidgetModule),
  },
  {
    path: "ui",
    canActivate: [AuthGuard],
    loadChildren: () => import("./ui/ui.module").then((m) => m.UiModule),
  },
  {
    path: "forms",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./forms/forms.module").then((m) => m.FormModule),
  },
  {
    path: "tables",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./tables/tables.module").then((m) => m.TablesModule),
  },
  {
    path: "media",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./media/media.module").then((m) => m.MediaModule),
  },
  {
    path: "charts",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./charts/charts.module").then((m) => m.ChartsModule),
  },
  {
    path: "timeline",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./timeline/timeline.module").then((m) => m.TimelineModule),
  },
  {
    path: "icons",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./icons/icons.module").then((m) => m.IconsModule),
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "extra-pages",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./extra-pages/extra-pages.module").then(
        (m) => m.ExtraPagesModule
      ),
  },
  {
    path: "maps",
    canActivate: [AuthGuard],
    loadChildren: () => import("./maps/maps.module").then((m) => m.MapsModule),
  },
  {
    path: "multilevel",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./multilevel/multilevel.module").then((m) => m.MultilevelModule),
  },
  {
    path: "watchfuleye",
    loadChildren: () =>
      import("./watchfuleye/watchfuleye.module").then(
        (m) => m.WatchfuleyeModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./smosify/smosify.module").then((m) => m.SmosifyModule),
  },

  // {
  //   path: "",
  //   canActivate: [AuthGuard],
  //   data: {
  //     role: `${Role.Partner}`,
  //   },
  //   loadChildren: () =>
  //     import("./partner/partner.module").then((m) => m.PartnerModule),
  // },

  // {
  //   path: "",
  //     canActivate: [AuthGuard],
  //   data: {
  //     role: `${Role.Admin}`,
  //   },
  //   loadChildren: () =>
  //     import("./authentication/authentication.module").then(
  //       (m) => m.AuthenticationModule
  //     ),
  // },

  // {
  //   path: "",
  //   redirectTo: "smosify",
  //   pathMatch: "full",
  // },
  // {
  //   path: "",
  //   redirectTo: "authentication",
  //   pathMatch: "full",
  // },
];
@NgModule({
  //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
