export enum Role {
  All = "all",
  Admin = "admin",
  Doctor = "doctor",
  Patient = "patient",
  // Supervisor = "supervisor",
  // Manager = "manager",
  // Executive = "executive",
SalesZonalManager = "Sales Zonal Manager",
SalesAreaManager = "Sales Area Manager",
RelationshipManger = "Relationship Manger",
SalesExecutive = "Sales Executive",
SupportZonalManger = "Support Zonal Manger",
SupportAreManger = "Support Area Manger",
SupportManagaer  = "Support Managaer",
SeniorHelpDeskExecutive = "Senior Help Desk Executive",
HelpDeskExecutive = "Help Desk Executive",
Coordinator = "coordinator",
Partner = 'partner',
}
