import { Injectable } from "@angular/core";
import { io } from "socket.io-client";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

//@Injectable()
@Injectable({
  providedIn: "root",
})
export class SocketioService {
  socket;
  constructor() { }

  setupSocketConnection() {
    this.socket = io(environment.SOCKET_ENDPOINT, {
      transports: ["websocket", "polling"]
    });
    this.socket.on("connection", (data) => {
      console.log("Working " + data);
    });

    this.socket.on("callfromroute", (myrouter) => {
      console.log("Working " + myrouter);
    });

    this.socket.on("newUser", (data) => {
      console.log(data);
    });
  }

  public getAmbulanceNotification = () => {
    return Observable.create((observer) => {
      this.socket.on("ambulance-req-by-call", (myrequest) => {
        console.log(myrequest);
        observer.next(myrequest);
      });
    });
  };

  
  bookingRing(status)
  {
    if(status)
    {
      let audio = new Audio();
      if (status.statusId == 1)
      {
        
        audio.src = "../../../assets/audio/Confirmed.mp3"; 
       
      }
      else if (status.statusId == 2)
      {
        
        audio.src = "../../../assets/audio/Paid.mp3"; 
       
      }
      else if (status.statusId == 3)
      {
        
        audio.src = "../../../assets/audio/Cancelled.mp3"; 
       
      }
      audio.load();
      audio.play();
    }
  }



  public sendMessage(message) {
    this.socket.emit("new-message", message);
  }
  public getMessages = () => {
    return Observable.create((observer) => {
      this.socket.on("new-message", (message) => {
        observer.next(message);
      });
    });
  };

  public getStatusService = () => {
    return Observable.create((observer) => {
      this.socket.on('workflow-updated', (data) => {
        observer.next(data);
      });
    });
  }

  public getStatusServicePartner = () => {
    return Observable.create((observer) => {
      this.socket.on('workflow-updatedPartner', (data) => {
        observer.next(data);
      });
    });
  }

  public getStatusEnquiry = () => {
    return Observable.create((observer) => {
      this.socket.on('enquiry-workflow-updated', (data) => {
        observer.next(data);
      });
    });
  }

  public addServiceRequestAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on('addServiceRequest-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public addServiceRequestStaff = () => {
    return Observable.create((observer) => {
      this.socket.on('addServiceRequest-staff', (data) => {
        observer.next(data);
      });
    });
  }

  public addServiceRequestUser = () => {
    return Observable.create((observer) => {
      this.socket.on('addServiceRequest-user', (data) => {
        observer.next(data);
      });
    });
  }

  public UpdateStaff = () => {
    return Observable.create((observer) => {
      this.socket.on('updateStaff-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public addStaff = () => {
    return Observable.create((observer) => {
      this.socket.on('createStaff-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public addPartner = () => {
    return Observable.create((observer) => {
      if(this.socket)
      this.socket.on('createPartner-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public updatePartner = () => {
    return Observable.create((observer) => {
      this.socket.on('updatePartner-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public updatePartnerFromPartner = () => {
    return Observable.create((observer) => {
      this.socket.on('updatePartner-partner', (data) => {
        observer.next(data);
      });
    });
  }

  public AddMemberFromAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on('addPatient-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public AddMemberFromMember = () => {
    return Observable.create((observer) => {
      this.socket.on('addPatient-user', (data) => {
        observer.next(data);
      });
    });
  }

  public AssignRequestToPartner = () => {
    return Observable.create((observer) => {
      this.socket.on('assignRequestToPartner-request', (data) => {
        observer.next(data);
      });
    });
  }

  public updateSRFromPartner = () => {
    return Observable.create((observer) => {
      this.socket.on('updateSRPartner-partner', (data) => {
        observer.next(data);
      });
    });
  }

  public updateMember = () => {
    return Observable.create((observer) => {
      this.socket.on('updatePatientNew1-user', (data) => {
        observer.next(data);
      });
    });
  }

  public updateMembersipReport = () => {
    return Observable.create((observer) => {
      this.socket.on('updatePatientNew1-user', (data) => {
        observer.next(data);
      });
    });
  }

  public buyPlan = () => {
    return Observable.create((observer) => {
      this.socket.on('buyPlan-user', (data) => {
        observer.next(data);
      });
    });
  }

  public getBookingsApp = () => {
    return Observable.create((observer) => {
      if(this.socket)
      this.socket.on('updatePayment-user', (data) => {
        observer.next(data);
      });
    });
  }

  public getRefundData = () => {
    return Observable.create((observer) => {
      if(this.socket)
      this.socket.on('refundBooking', (data) => {
        observer.next(data);
      });
    });
  }
  public getNewBookingsApp = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_new_appointment', (data) => {
        this.bookingRing({"statusId":1});
        observer.next(data);
      });
    });
  }

  public uploadBulkAdmin = () => {
    return Observable.create((observer) => {
      this.socket.on('uploadBulkImage-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public uploadBulkStaff = () => {
    return Observable.create((observer) => {
      this.socket.on('uploadBulkImage-staff', (data) => {
        observer.next(data);
      });
    });
  }

  public updateRemarkForRequest = () => {
    return Observable.create((observer) => {
      this.socket.on('addFeedback-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public getServiceRequestFromApp = () => {
    return Observable.create((observer) => {
      this.socket.on('bookServiceRequest-admin', (data) => {
        observer.next(data);
      });
    });
  }

  public updatedRequestfromApp = () => {
    return Observable.create((observer) => {
      this.socket.on('updateRequestStatusApp-partner', (data) => {
        observer.next(data);
      });
    });
  }

  public getNotificationForVoucherSell = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_voucher_sell', (data) => {
        observer.next(data);
      });
    });
  }

  public getNotificationForProductSell = () => {
    return Observable.create((observer) => {
      this.socket.on('productSell_Notification', (data) => {
        observer.next(data);
      });
    });
  }

  public getNotificationForRecorderPoint = () => {
    return Observable.create((observer) => {
      this.socket.on('recorderPoint_Notification', (data) => {
        observer.next(data);
      });
    });
  }

  public getNotificationForComplete = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_complete_booking', (data) => {
        this.bookingRing({"statusId":4});
        observer.next(data);
      });
    });
  }

  public getNotificationForReschedule = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_reschedule_booking', (data) => {
        this.bookingRing({"statusId":1});
        observer.next(data);
      });
    });
  }

  public getNotificationForCancel = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_cancel_booking', (data) => {
        this.bookingRing({"statusId":3});
        observer.next(data);
      });
    });
  }

  public getNotificationForAdd = () => {
    return Observable.create((observer) => {
      if(this.socket)
      this.socket.on('notify_new_appointment', (data) => {
        this.bookingRing({"statusId":1});
        observer.next(data);
      });
    });
  }

  public getNotificationForCheckout = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_payment_appointment', (data) => {
        this.bookingRing({"statusId":2});
        observer.next(data);
      });
    });
  }

  public getNotificationForClientAdd = () => {
    return Observable.create((observer) => {
      this.socket.on('notify_new_client_added', (data) => {
        observer.next(data);
      });
    });
  }

  public getNotificationForStaffAdd = () => {
    return Observable.create((observer) => {
      if(this.socket)
      this.socket.on('notify_new_partnerStaff_added', (data) => {
        observer.next(data);
      });
    });
  }

  public getStoryView = () => {
    return Observable.create((observer) => {
      this.socket.on('viewPartnerStory-user', (data) => {
        observer.next(data);
      });
    });
  }

  public getUpdatedService = () => {
    return Observable.create((observer) => {
      this.socket.on('service_priceHike', (data) => {
        observer.next(data);
      });
    });
  }

  public getUpdatedPackage = () => {
    return Observable.create((observer) => {
      this.socket.on('package_priceHike', (data) => {
        observer.next(data);
      });
    });
  }

  public getfavouritePartner = () => {
    return Observable.create((observer) => {
      this.socket.on('favouritePartner', (data) => {
        observer.next(data);
      });
    });
  }

}
