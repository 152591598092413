import { RouteInfo } from "./sidebar.metadata";
import { Role } from "src/app/shared/security/role";
const staffRoles: string[] = [
  "Sales Zonal Manager",
  "Sales Area Manager",
  "Relationship Manger",
  "Sales Executive",
  "Support Zonal Manger",
  "Support Area Manger",
  "Support Managaer",
  "Senior Help Desk Executive",
  "Help Desk Executive",
];
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "-- Main",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["all"],
    submenu: [],
  },
  // Admin Modules
  {
    path: "",
    title: "Home",
    moduleName: "dashboard",
    iconType: "icon-home",
    icon: "home",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/dashboard/main",
        title: "Dashboard 1",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/dashboard2",
        title: "Dashboard 2",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Requests",
  //   moduleName: "Requests",
  //   iconType: "icon-target",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/requests/viewRequest",
  //       title: "View Request",
  //       moduleName: "Requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/requests/bookRequest",
  //       title: "Book Request",
  //       moduleName: "Requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/requests/closed-request",
  //       title: "Closed Request",
  //       moduleName: "Requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "Inventory",
    moduleName: "inventory",
    iconType: "icon-book-open",
    icon: "local_convenience_store",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/inventory/addorder",
        title: "Add Order",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/inventory/vieworder",
        title: "View Order",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/addissueproduct",
      //   title: "Add Issue Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/inventory/viewissueptoducts",
      //   title: "View Issue Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/inventory/addproduct",
        title: "Add Product",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/listproduct",
      //   title: "List Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/inventory/viewproduct",
        title: "View Product",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/listproduct",
      //   title: "List Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/inventory/addsupplier",
        title: "Add Supplier",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/inventory/viewsupplier",
        title: "View Supplier",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/add-grn",
      //   title: "Add GRN",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/inventory/view-grn",
      //   title: "View GRN",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Membership ",
  //   moduleName: "membership",
  //   iconType: "icon-tag",
  //   icon: "icon-tag",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/membership/addmembership",
  //       title: "Add MemberShip",
  //       moduleName: "membership",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/voucher/viewvoucher",
  //       title: "View Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  {
    path: "",
    title: "Voucher ",
    moduleName: "voucher",
    iconType: "admin-voucher-icon",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/voucher/addvoucher",
        title: "Add Voucher",
        moduleName: "voucher",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/voucher/viewvoucher",
        title: "View Voucher",
        moduleName: "voucher",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Rating-Review",
    moduleName: "RatingAndReviewModule",
    iconType: "view-rating-icon",
    icon: "face",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/rating-review/view-rating-review",
        title: "View-Rating-Review",
        moduleName: "RatingAndReviewModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["admin"],
        submenu: [],
      },
    ],
  },
  // new feedback
  {
    path: "feedback",
    title: "Feedback",
    moduleName: "FeedbackModule",
    iconType: "fa-regular fa-comment-dots",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },
  // for media gallery admin
  {
    path: "/admin/gallerydrive/mediaImages",
    title: "Media",
    moduleName: "MediadriveModule",
    iconType: "icon-media-img",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },
  // for view my booking partner admin
  {
    path: "/admin/partnerappointments/partnerclientappointments",
    title: "Partner Client Bookings",
    moduleName: "PartnerClientAppointmentModule",
    iconType: "icon-mybooking-icon",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [],
  },
  // for  Subscription Plans create
  {
    path: "",
    title: "Subscription Plan",
    moduleName: "SubscriptionPlanModule",
    iconType: "plan-icons-admin",
    icon: "face",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/plansubsription/addsubscriptionplan",
        title: "Partner Subscription Plan",
        moduleName: "SubscriptionPlanModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["admin"],
        submenu: [],
      },
      {
        path: "/admin/memberplansubsriptions/membersubscriptionplan",
        title: "Client Subscription Plan",
        moduleName: "MemberSubscriptionPlanModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["admin"],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Credit Coins ",
    moduleName: "credit-coins",
    iconType: "add-coins-icon",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/credit-coins/addcoin",
        title: "Add Coins",
        moduleName: "addcoin",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/credit-coins/viewcoins",
        title: "View Coins",
        moduleName: "viewcoins",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Analytics ",
    moduleName: "analytics",
    iconType: "icon-graph",
    icon: "icon-graph",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/analytics/viewanalytics",
        title: "View Analytics",
        moduleName: "Analytics",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Analytics ",
  //   moduleName: "analytics",
  //   iconType: "icon-tag",
  //   icon: "icon-tag",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/analytics/viewanalytics",
  //       title: "View Analytics",
  //       moduleName: "Analytics",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  {
    path: "",
    title: "Sales ",
    moduleName: "sales",
    iconType: "icon-tag",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/sales-for-admin/viewsales",
        title: "view sales",
        moduleName: "SalesForAdmin",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Package Plans",
    moduleName: "package",
    iconType: "icon-present",
    icon: "local_convenience_store",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/package/addpackage",
        title: "Add Package",
        moduleName: "package",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/package/viewpackage",
        title: "View Package",
        moduleName: "package",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Voucher",
  //   moduleName: "voucher",
  //   iconType: "material-icons-two-tone",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/voucher/addvoucher",
  //       title: "Add Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/voucher/viewvoucher",
  //       title: "View Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  // client For admin
  {
    path: "",
    title: "Client",
    moduleName: "ClientModule",
    iconType: "icon-user-follow",
    icon: "icon-user-follow",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/client/addClient",
        title: "Add Client",
        moduleName: "ClientModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/client/viewClient",
        title: " View Client ",
        moduleName: "ClientModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/partner/patients/patient-profile",
      //   title: "Member Profile",
      //   moduleName: "patients",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Membership Plans",
  //   moduleName: "Plan",
  //   iconType: "icon-lock",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     // {
  //     //   path: "/admin/plan/create-plan",
  //     //   title: "Create Plan",
  //     //   moduleName: "plan",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     {
  //       path: "/admin/plan/all-plan",
  //       title: "All Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/add-plan",
  //       title: "Add Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/patients/myplan",
  //       title: "View Member Plan",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/membershipReports",
  //       title: "Membership Reports",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/addMembershipReportData",
  //       title: "Add MembershipReport Data",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/applyCouponForMember",
  //       title: "Buy Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/staff/staff-profile",
  //     //   title: "Staff Profile",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Members",
  //   moduleName: "patients",
  //   iconType: "icon-symbol-male",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/patients/all-patients",
  //       title: "All Members",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/patients/add-patient",
  //       title: "Add Member",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/patients/edit-patient",
  //     //   title: "Edit Member",
  //     //   moduleName: "patients",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/admin/patients/patient-profile",
  //     //   title: "Member Profile",
  //     //   moduleName: "patients",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Medical Report Upload",
  //   moduleName: "medical-report",
  //   iconType: "icon-note",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     // {
  //     //   path: "/admin/medical-report/view-report",
  //     //   title: "View Upload",
  //     //   moduleName: "medical-report",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     {
  //       path: "/admin/medical-report/upload-report",
  //       title: "Upload Report",
  //       moduleName: "medical-report",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/requests/edit-request",
  //     //   title: "Edit Request",
  //     //   moduleName: "Requests",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Appointments",
  //   moduleName: "appointment",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/appointment/viewAppointment",
  //       title: "View Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/appointment/bookAppointment",
  //       title: "Book Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/appointment/edit-ppointment",
  //       title: "Edit Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "Service Product",
    moduleName: "product",
    iconType: "icon-product-admin",
    icon: "people_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/product/addProduct",
        title: "Add Product",
        moduleName: "addproduct",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/product/getProduct",
        title: "View Product",
        moduleName: "getproduct",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // booking Service
  // {
  //   path: "",
  //   title: "Book Service",
  //   moduleName: "bookingservice",
  //   iconType: "icon-link",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/bookingservice/bookservice",
  //       title: "Add BookService",
  //       moduleName: "bookservice",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  //end Booking Service
  {
    path: "",
    title: "Calender Booking",
    moduleName: "bookcalender",
    iconType: "icon-calender",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/calenderBooking/addAppointment",
        title: "Add Appointment",
        moduleName: "bookcalender",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Partner",
    moduleName: "doctors",
    iconType: "icon-support",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        // path: "/admin/doctors/allDoctors",
        path: "/admin/partners/allPartners",
        title: "All Partner",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/partners/add-partner",
        title: "Add Partner",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/partners/deletedpartner",
        title: "Deactivated Partner",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/partners/addgrade",
        title: "Partner Grade",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/partners/gradelist",
        title: "Grade List",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/doctors/doctor-profile",
      //   title: "Partner Profile",
      //   moduleName: "doctors",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Partner Staff",
  //   moduleName: "staff",
  //   iconType: "icon-user-following",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/staff/all-staff",
  //       title: "View Staff",
  //       moduleName: "staff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/staff/add-staff",
  //       title: "Add Staff",
  //       moduleName: "staff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/staff/assignstaff",
  //     //   title: "Assign Pincode",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/admin/staff/staff-profile",
  //     //   title: "Staff Profile",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  {
    path: "",
    title: "Admin Staff",
    moduleName: "adminstaff",
    iconType: "icon-user-following",
    icon: "people_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/adminstaff/addstaff",
        title: "Add Staff",
        moduleName: "adminstaff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/adminstaff/viewstaff",
        title: "View Staff",
        moduleName: "adminstaff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Room Allotment",
  //   moduleName: "room",
  //   iconType: "material-icons-two-tone",
  //   icon: "hotel",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/room/all-rooms",
  //       title: "Alloted Rooms",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/room/add-allotment",
  //       title: "New Allotment",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/room/edit-allotment",
  //       title: "Edit Allotment",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Payment",
  //   moduleName: "payment",
  //   iconType: "material-icons-two-tone",
  //   icon: "monetization_on",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/payment/all-payment",
  //       title: "All Payment",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/payment/add-payment",
  //       title: "Add Payment",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/payment/invoice",
  //       title: "Invoice",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "Services",
    moduleName: "services",
    iconType: "fa-solid fa-list-check",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/services/all-services",
        title: "View Service",
        moduleName: "services",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/services/add-services",
        title: "Add Service",
        moduleName: "services",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/services/upload-prices",
      //   title: "Upload Prices",
      //   moduleName: "services",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/services/edit-services",
      //   title: "Edit Services",
      //   moduleName: "Services",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  {
    path: "",
    title: "Offer",
    moduleName: "coupon",
    iconType: "offer-icon-admin",
    icon: "send",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      // {
      //   path: "/admin/coupon/addcoupon",
      //   title: "Add Coupon",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/coupon/viewcoupon",
      //   title: "View Coupon",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/admin/offer/addoffers",
        title: "Add Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/offer/viewoffers",
        title: "View Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/offer/partnerOffers",
        title: "Partner Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "SetUp",
    moduleName: "setup",
    iconType: "icon-trophy",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/setup/partnerComission",
        title: "Partner Comission",
        moduleName: "setup",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/setup/enquirylist",
        title: "Enquiry List",
        moduleName: "setup",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Enquiry",
  //   moduleName: "enquiry",
  //   iconType: "icon-call-in",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/enquiry/addenquiry",
  //       title: "Add Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/enquiry/getenquiry",
  //       title: "View Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/enquiry/closed-enquiries",
  //       title: "Closed Enquiries",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // BLog Modules
  {
    path: "",
    title: "Blog",
    moduleName: "blog",
    iconType: "icon-people",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/blog/add-new-blog",
        title: "Add New Blog",
        moduleName: "blog",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/blog/view-blogs",
        title: "View Blogs",
        moduleName: "blog",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  //Setting Modules
  {
    path: "",
    title: "Settings",
    moduleName: "notifications",
    iconType: "icon-settings",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["admin"],
    submenu: [
      {
        path: "/admin/setting/sendnotification",
        title: "Send Notification",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/setting/sendnotificationlist",
        title: "Notification List",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/setting/viewreports",
        title: "View Reports",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/setting/viewstatusreports",
        title: "View Status Reports",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // Doctor Modules
  {
    path: "/doctor/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctor/appointments",
    title: "Appointments",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctor/doctors",
    title: "Doctors",
    moduleName: "doctors",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctor/patients",
    title: "Patients",
    moduleName: "patients",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctor/settings",
    title: "Settings",
    moduleName: "settings",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  // Partner--------------------------------------------------------------------------------------------------->>>
  // Doctor Modules
  {
    path: "/partner/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "icon-home",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [],
  },
  // CalendarBooking For Partner
  {
    path: "/partner/calenderBooking/addAppointment",
    title: "Calendar Booking",
    moduleName: "bookcalender",
    iconType: "calender-booking-icon",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/calenderBooking/addAppointment",
      //   title: "Add Appointment",
      //   moduleName: "bookcalender",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  //quicksell for partner commenting it for 2.0
  // {
  //   path: "/partner/quicksell/newsell",
  //   title: "Quick Sell",
  //   moduleName: "QuicksellModule",
  //   iconType: "icon-basket",
  //   icon: "face",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [],
  // },
  //appointment for partner
  {
    path: "/partner/appointment/viewappointment",
    title: "Appointment",
    moduleName: "AppointmentModule",
    iconType: "icon-clock",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/appointment/viewappointment",
      //   title: " View Appointment ",
      //   moduleName: "AppointmentModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  {
    path: "/partner/mybookings/viewappointment",
    title: "My Bookings",
    moduleName: "MybookingsModule",
    iconType: "icon-mybooking-icon",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/appointment/viewappointment",
      //   title: " View Appointment ",
      //   moduleName: "AppointmentModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // for online booking
  {
    path: "/partner/onlinebooking",
    title: "Booking Settings",
    moduleName: "BookingsettingModule",
    iconType: "oline-booking-icon",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Partner Staff",
  //   moduleName: "partnerstaff",
  //   iconType: "icon-user-following",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/partnerstaff/viewstaff",
  //       title: "View Staff",
  //       moduleName: "partnerstaff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/partnerstaff/add-staff",
  //       title: "Add Staff",
  //       moduleName: "partnerstaff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/staff/assignstaff",
  //     //   title: "Assign Pincode",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/admin/staff/staff-profile",
  //     //   title: "Staff Profile",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  {
    path: "/partner/Status/addStory",
    title: "Story",
    moduleName: "StatusModule",
    iconType: "icon-feed",
    icon: "local_convenience_store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/Status/addStory",
      //   title: "Add Story",
      //   moduleName: "StatusModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/Status/viewStory",
      //   title: " View Story ",
      //   moduleName: "StatusModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  //Media drive gallery for partner
  {
    path: "/partner/media/gallery",
    title: "Media",
    moduleName: "MediaImgGalleryModule",
    iconType: "icon-media-img",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [],
  },
  //MemberShip for partner
  // {
  //   path: "",
  //   title: "Plans ",
  //   moduleName: "membership",
  //   iconType: "icon-membership",
  //   icon: "icon-membership",
  //   class: "menu-toggle membership",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/membership/addmembership",
  //       title: "Add Plans",
  //       moduleName: "membership",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/membership/viewmembership",
  //       title: "View Plans",
  //       moduleName: "membership",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  //Voucher For Partner
  // {
  //   path: "",
  //   title: "Voucher ",
  //   moduleName: "voucher",
  //   iconType: "icon-voucher",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle voucher",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/voucher/addvoucher",
  //       title: "Add Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/voucher/viewvoucher",
  //       title: "View Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  //Analytics For Partner
  {
    path: "/partner/analytics/viewanalytics",
    title: "Analytics ",
    moduleName: "analytics",
    iconType: "icon-graph",
    icon: "icon-tag",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/analytics/viewanalytics",
      //   title: "View Analytics",
      //   moduleName: "Analytics",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // clients for partner
  // {
  //   path: "",
  //   title: "Client",
  //   moduleName: "ClientModule",
  //   iconType: "icon-people",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/client/addClient",
  //       title: "Add Client",
  //       moduleName: "ClientModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/client/viewClient",
  //       title: " View Client ",
  //       moduleName: "ClientModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/partner/sales/viewsales",
    title: "Sales ",
    moduleName: "sales",
    iconType: "icon-tag",
    icon: "icon-tag",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/sales/saleview",
      //   title: "View Sales",
      //   moduleName: "Sales",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/sales/saleproduct",
      //   title: "Sold Product",
      //   moduleName: "Sales",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/sales/salevoucher",
      //   title: "Sold  Voucher",
      //   moduleName: "Sales",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/sales/salemember",
      //   title: "Sold Member",
      //   moduleName: "Sales",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/sales/paymentTransaction",
      //   title: "Payment Transaction",
      //   moduleName: "Sales",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Services",
  //   moduleName: "services",
  //   iconType: "icon-energy",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/services/all-services",
  //       title: "View Service",
  //       moduleName: "services",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/services/add-services",
  //       title: "Add Service",
  //       moduleName: "services",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/partner/services/upload-prices",
  //     //   title: "Upload Prices",
  //     //   moduleName: "services",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Package Plans",
  //   moduleName: "package",
  //   iconType: "icon-present",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/package/addpackage",
  //       title: "Add Package",
  //       moduleName: "package",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/package/viewpackage",
  //       title: "View Package",
  //       moduleName: "package",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  // Staff For Partner
  // {
  //   path: "",
  //   title: "Staff",
  //   moduleName: "staff",
  //   iconType: "icon-user-following",
  //   icon: "supervised_user_circle",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/staff/addStaff",
  //       title: "Add Staff",
  //       moduleName: "staff",
  //       iconType: "material-icons-two-tone",
  //       icon: "assignment",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: []
  //     },
  //     {
  //       path: "/partner/staff/allStaff",
  //       title: "View Staff",
  //       moduleName: "staff",
  //       iconType: "material-icons-two-tone",
  //       icon: "assignment",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: []
  //     }
  //   ],
  // },
  // BookingService For Partner
  // {
  //   path: "",
  //   title: "Book Service",
  //   moduleName: "bookingservice",
  //   iconType: "material-icons-two-tone",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/bookingservice/bookservice",
  //       title: "Add BookService",
  //       moduleName: "bookservice",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // Inventory For Partner oo
  {
    path: "/partner/inventory/inventory-shortcut",
    title: "Inventory",
    moduleName: "inventory",
    iconType: "icon-book-open",
    icon: "local_convenience_store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/inventory/addsupplier",
      //   title: "Add Supplier",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/viewsupplier",
      //   title: "View Supplier",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/add-grn",
      //   title: "Add GRN",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/view-grn",
      //   title: "View GRN",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/addissueproduct",
      //   title: "Add Issue Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/viewissueptoducts",
      //   title: "View Issue Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/addproduct",
      //   title: "Add Product",
      //   moduleName: "inventory",
      //   iconType: "icon-rocket",
      //   icon: "people_alt",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/viewproduct",
      //   title: "View Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/addorder",
      //   title: "Add Order",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/inventory/vieworder",
      //   title: "View Order",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Request",
  //   moduleName: "appointments",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/request/viewrequest",
  //       title: "View Request",
  //       moduleName: "view-request",
  //       iconType: "material-icons-two-tone",
  //       icon: "assignment",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: []
  //     },
  //     {
  //       path: "/partner/request/editrequest",
  //       title: "Edit Request",
  //       moduleName: "edit-request",
  //       iconType: "material-icons-two-tone",
  //       icon: "assignment",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: []
  //     }
  //   ],
  // },
  // Product For Partner
  // {
  //   path: "",
  //   title: "Product",
  //   moduleName: "product",
  //   iconType: "icon-rocket",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/product/addProduct",
  //       title: "Add Product",
  //       moduleName: "addproduct",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/product/getProduct",
  //       title: "Get Product",
  //       moduleName: "getproduct",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // Blog For Partner
  // {
  //   path: "",
  //   title: "Blog",
  //   moduleName: "blog",
  //   iconType: "icon-info",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/blog/add-blog",
  //       title: "Add Blog",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/blog/view-blogs",
  //       title: "View Blogs",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/blog/add-new-blog",
  //       title: "Add New Blog",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // Coupon For Partner
  {
    path: "/partner/offer/viewoffers",
    title: "Offer",
    moduleName: "coupon",
    iconType: "icon-layers",
    icon: "send",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/offer/addoffers",
      //   title: "Add Offers",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/offer/viewoffers",
      //   title: "View Offers",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // Career For Partner
  // {
  //   path: "",
  //   title: "Career",
  //   moduleName: "career",
  //   iconType: "material-icons-two-tone",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/career/addcareer",
  //       title: "Add Career",
  //       moduleName: "career",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/career/viewcareer",
  //       title: "View Career",
  //       moduleName: "career",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // Package For Partner
  // Patients For Partner
  // {
  //   path: "",
  //   title: "Members",
  //   moduleName: "patients",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/patients/all-patients",
  //       title: "All Members",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/patients/add-patient",
  //       title: "Add Member",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/patients/edit-patient",
  //       title: "Edit Member",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/partner/patients/patient-profile",
  //       title: "Member Profile",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  //Client For Partner
  {
    path: "/partner/setup/setupcontrol",
    title: "Business Setup",
    moduleName: "SetupModule",
    iconType: "icon-settings",
    icon: "local_convenience_store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["partner"],
    submenu: [
      // {
      //   path: "/partner/setup/View-Setup",
      //   title: " View Setup ",
      //   moduleName: "SetupModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/partner/setup/setupcontrol",
      //   title: " Setup Control ",
      //   moduleName: "SetupModule",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Review",
  //   moduleName: "ReviewsModule",
  //   iconType: "icon-star",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/reviews/viewreview",
  //       title: " View Review ",
  //       moduleName: "ReviewsModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Notification",
  //   moduleName: "PartnernotificationModule",
  //   iconType: "icon-info",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [
  //     {
  //       path: "/partner/partnernotification/viewnotification",
  //       title: " View Notification ",
  //       moduleName: "PartnernotificationModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // ----------------------------------------------------------------------------------------------------------------------------------------------------------->>>>>
  // {
  //   path: "/partner/doctors",
  //   title: "Doctors",
  //   moduleName: "doctors",
  //   iconType: "material-icons-two-tone",
  //   icon: "supervised_user_circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/partner/patients",
  //   title: "Patients",
  //   moduleName: "patients",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/partner/settings",
  //   title: "Settings",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/partner/my-profile",
  //   title: "My Profile",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["partner"],
  //   submenu: [],
  // },
  // Patient Modules
  {
    path: "/patient/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },
  // {
  //   path: "/patient/add-member",
  //   title: "Add Beneficiary",
  //   moduleName: "add-member",
  //   iconType: "material-icons-two-tone",
  //   icon: "group_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Requests",
  //   moduleName: "appointments",
  //   iconType: "icon-paper-clip",
  //   icon: "icon-paper-clip",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [
  //     {
  //       path: "/patient/book-request",
  //       title: "Book Request",
  //       moduleName: "requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/patient/view-request",
  //     //   title: "View Request",
  //     //   moduleName: "requests",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     {
  //       path: "/patient/appointments/view-request",
  //       title: "View Request",
  //       moduleName: "appointments",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/patient/appointments/open-request",
  //     //   title: "Open Request",
  //     //   moduleName: "appointments",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/patient/appointments/upcoming",
  //     //   title: "Upcoming Requests",
  //     //   moduleName: "appointments",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/patient/appointments/past",
  //     //   title: "Completed Requests",
  //     //   moduleName: "appointments",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  {
    path: "",
    title: "Membership",
    moduleName: "customerplan",
    iconType: "material-icons-two-tone",
    icon: "credit_card",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/customer-plan/buyplan",
        title: "Become a member",
        moduleName: "customerplan",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/customer-plan/myplan",
        title: "My Membership",
        moduleName: "customerplan",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/customer-plan/membershipReport",
        title: "Membership Report",
        moduleName: "plans",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "/patient/prescriptions",
  //   title: "Prescriptions",
  //   moduleName: "prescriptions",
  //   iconType: "material-icons-two-tone",
  //   icon: "receipt_long",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },
  {
    path: "/patient/records",
    title: "Medical Records",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },
  // {
  //   path: "/patient/billing",
  //   title: "Billing",
  //   moduleName: "records",
  //   iconType: "material-icons-two-tone",
  //   icon: "receipt",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },
  {
    path: "/patient/my-profile",
    title: "My Profile",
    moduleName: "profile",
    iconType: "material-icons-two-tone",
    icon: "person",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },
  // {
  //   path: "/apps/chat",
  //   title: "Chat",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "chat",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },
  // {
  //   path: "/patient/settings",
  //   title: "Settings",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },
  //Common Modules
  // {
  //   path: "",
  //   title: "-- Apps",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin", "partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/calendar",
  //   title: "Calendar",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "event_note",
  //   class: "",
  //   groupTitle: false,
  //   badge: "New",
  //   badgeClass: "badge bg-blue sidebar-badge float-right",
  //   role: ["admin", "partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/task",
  //   title: "Task",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "fact_check",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin", "partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/contacts",
  //   title: "Contacts",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "contacts",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin", "partner"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/chat",
  //   title: "Chat",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "chat",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin", "partner"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Email",
  //   moduleName: "email",
  //   iconType: "material-icons-two-tone",
  //   icon: "email",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin", "partner"],
  //   submenu: [
  //     {
  //       path: "/email/inbox",
  //       title: "Inbox",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/email/compose",
  //       title: "Compose",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/email/read-mail",
  //       title: "Read Email",
  //       moduleName: "email",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "More Apps",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "stars",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "4",
  //   badgeClass: "badge bg-orange sidebar-badge float-right",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/apps/dragdrop",
  //       title: "Drag & Drop",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/apps/contact-grid",
  //       title: "Contact Grid",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/apps/support",
  //       title: "Support",
  //       moduleName: "apps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Widgets",
  //   moduleName: "widget",
  //   iconType: "material-icons-two-tone",
  //   icon: "widgets",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/widget/chart-widget",
  //       title: "Chart Widget",
  //       moduleName: "widget",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/widget/data-widget",
  //       title: "Data Widget",
  //       moduleName: "widget",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "-- Components",
  //   moduleName: "",
  //   iconType: "",
  //   icon: "",
  //   class: "",
  //   groupTitle: true,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "User Interface (UI)",
  //   moduleName: "ui",
  //   iconType: "material-icons-two-tone",
  //   icon: "dvr",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/ui/alerts",
  //       title: "Alerts",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/animations",
  //       title: "Animations",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/badges",
  //       title: "Badges",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/chips",
  //       title: "Chips",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/modal",
  //       title: "Modal",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/buttons",
  //       title: "Buttons",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/expansion-panel",
  //       title: "Expansion Panel",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/bottom-sheet",
  //       title: "Bottom Sheet",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/dialogs",
  //       title: "Dialogs",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/cards",
  //       title: "Cards",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/labels",
  //       title: "Labels",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/list-group",
  //       title: "List Group",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/media-object",
  //       title: "Media Object",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/snackbar",
  //       title: "Snackbar",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/preloaders",
  //       title: "Preloaders",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/progressbars",
  //       title: "Progress Bars",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/tabs",
  //       title: "Tabs",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/typography",
  //       title: "Typography",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/ui/helper-classes",
  //       title: "Helper Classes",
  //       moduleName: "ui",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Forms",
  //   moduleName: "forms",
  //   iconType: "material-icons-two-tone",
  //   icon: "subtitles",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/forms/form-controls",
  //       title: "Form Controls",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/advance-controls",
  //       title: "Advanced Controls",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/form-example",
  //       title: "Form Examples",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/form-validation",
  //       title: "Form Validation",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/wizard",
  //       title: "Form Wizard",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/forms/editors",
  //       title: "Editors",
  //       moduleName: "forms",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Tables",
  //   moduleName: "tables",
  //   iconType: "material-icons-two-tone",
  //   icon: "view_list",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/tables/basic-tables",
  //       title: "Basic Tables",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/tables/material-tables",
  //       title: "Material Tables",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/tables/ngx-datatable",
  //       title: "ngx-datatable",
  //       moduleName: "tables",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Medias",
  //   moduleName: "media",
  //   iconType: "material-icons-two-tone",
  //   icon: "image_search",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/media/gallery",
  //       moduleName: "media",
  //       title: "Image Gallery",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/media/carousel",
  //       moduleName: "media",
  //       title: "Carousel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Charts",
  //   moduleName: "charts",
  //   iconType: "material-icons-two-tone",
  //   icon: "insert_chart",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "7",
  //   badgeClass: "badge bg-green sidebar-badge float-right",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/charts/echart",
  //       title: "Echart",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/morris",
  //       title: "Morris",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/apex",
  //       title: "Apex",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/chartjs",
  //       title: "ChartJS",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/ngx-charts",
  //       title: "Ngx-Charts",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/gauge",
  //       title: "Gauge",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/charts/sparkline",
  //       title: "Sparkline",
  //       moduleName: "charts",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Timeline",
  //   moduleName: "timeline",
  //   iconType: "material-icons-two-tone",
  //   icon: "amp_stories",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/timeline/timeline1",
  //       title: "Timeline 1",
  //       moduleName: "timeline",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/timeline/timeline2",
  //       title: "Timeline 2",
  //       moduleName: "timeline",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Icons",
  //   moduleName: "icons",
  //   iconType: "material-icons-two-tone",
  //   icon: "eco",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/icons/material",
  //       title: "Material Icons",
  //       moduleName: "icons",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/icons/font-awesome",
  //       title: "Font Awesome",
  //       moduleName: "icons",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Authentication",
  //   moduleName: "authentication",
  //   iconType: "material-icons-two-tone",
  //   icon: "supervised_user_circle",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/authentication/signin",
  //       title: "Sign In",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/signup",
  //       title: "Sign Up",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/forgot-password",
  //       title: "Forgot Password",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/locked",
  //       title: "Locked",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/page404",
  //       title: "404 - Not Found",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/authentication/page500",
  //       title: "500 - Server Error",
  //       moduleName: "authentication",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Extra Pages",
  //   moduleName: "extra-pages",
  //   iconType: "material-icons-two-tone",
  //   icon: "description",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/extra-pages/profile",
  //       title: "Profile",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/pricing",
  //       title: "Pricing",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/invoice",
  //       title: "Invoice",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/faqs",
  //       title: "Faqs",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/extra-pages/blank",
  //       title: "Blank Page",
  //       moduleName: "extra-pages",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Maps",
  //   moduleName: "maps",
  //   iconType: "material-icons-two-tone",
  //   icon: "map",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/maps/google",
  //       title: "Google Map",
  //       moduleName: "maps",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Multi level Menu",
  //   moduleName: "multilevel",
  //   iconType: "material-icons-two-tone",
  //   icon: "slideshow",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/multilevel/first1",
  //       title: "First",
  //       moduleName: "multilevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/",
  //       title: "Second",
  //       moduleName: "secondlevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-sub-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [
  //         {
  //           path: "/multilevel/secondlevel/second1",
  //           title: "Second 1",
  //           moduleName: "secondlevel",
  //           iconType: "",
  //           icon: "",
  //           class: "ml-sub-sub-menu",
  //           groupTitle: false,
  //           badge: "",
  //           badgeClass: "",
  //           role: [""],
  //           submenu: [],
  //         },
  //         {
  //           path: "/multilevel/secondlevel/second2",
  //           title: "Second 2",
  //           moduleName: "secondlevel",
  //           iconType: "",
  //           icon: "",
  //           class: "ml-sub-sub-menu",
  //           groupTitle: false,
  //           badge: "",
  //           badgeClass: "",
  //           role: [""],
  //           submenu: [],
  //         },
  //       ],
  //     },
  //     {
  //       path: "/multilevel/first3",
  //       title: "Third",
  //       moduleName: "multilevel",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/authentication/signin",
    title: "Logout",
    moduleName: "logout",
    iconType: "material-icons-two-tone",
    icon: "power_settings_new",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["all"],
    submenu: [],
  },
  /////// staff------------------------------------Adminstaff---------------
  {
    path: "/staff/dashboard",
    title: "Staff Dashboard",
    moduleName: "Staff",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    // role: ["supervisor", "executive", "manager"],
    role: [
      "Sales Zonal Manager",
      "Sales Area Manager",
      "Relationship Manger",
      "Sales Executive",
      "Support Zonal Manger",
      "Support Area Manger",
      "Support Managaer",
      "Senior Help Desk Executive",
      "Help Desk Executive",
      "Sales Area Manager",
      "Relationship Manger",
      "Sales Executive",
      "Support Zonal Manger",
      "Support Area Manger",
      "Support Managaer",
      "Senior Help Desk Executive",
      "Help Desk Executive",
      "Sales Area Manager",
      "Relationship Manger",
      "Sales Executive",
      "Support Zonal Manger",
      "Support Area Manger",
      "Support Managaer",
      "Senior Help Desk Executive",
      "Help Desk Executive",
      "Sales Area Manager",
      "Relationship Manger",
      "Sales Executive",
      "Support Zonal Manger",
      "Support Area Manger",
      "Support Managaer",
      "Senior Help Desk Executive",
      "Help Desk Executive",
    ],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Volunteers",
  //   moduleName: "volunteer",
  //   iconType: "material-icons-two-tone",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [
  //     {
  //       path: "/staff/volunteer",
  //       title: "Add Volunteers",
  //       moduleName: "volunteer",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/allvolunteer",
  //       title: "All Volunteers",
  //       moduleName: "volunteer",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  //  {
  //   path: "",
  //   title: "Requests",
  //   moduleName: "Requests",
  //   iconType: "material-icons-two-tone",
  //   icon: "send",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [
  //     {
  //       path: "/staff/viewRequest",
  //       title: "View Request",
  //       moduleName: "Requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/bookRequest",
  //       title: "Book Request",
  //       moduleName: "Requests",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/staff/edit-request",
  //     //   title: "Edit Request",
  //     //   moduleName: "Requests",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Members",
  //   moduleName: "patients",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["manager"],
  //   submenu: [
  //     {
  //       path: "/staff/allMembers",
  //       title: "All Members",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/addMembers",
  //       title: "Add Members",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Membership Plans",
  //   moduleName: "Plan",
  //   iconType: "material-icons-two-tone",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["manager"],
  //   submenu: [
  //     {
  //       path: "/staff/viewMemberPlan",
  //       title: "View Member Plan",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Medical Report Upload",
  //   moduleName: "medical-report",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [
  //     {
  //       path: "/staff/uploadReport",
  //       title: "Upload Report",
  //       moduleName: "medical-report",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "/staff/my-profile",
  //   title: "My Profile",
  //   moduleName: "profile",
  //   iconType: "material-icons-two-tone",
  //   icon: "person",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [],
  // },
  // {
  //   path: "/staff/assign",
  //   title: "Assign Pincode",
  //   moduleName: "profile",
  //   iconType: "material-icons-two-tone",
  //   icon: "supervised_user_circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [],
  // },
  // {
  //   path: "/staff/membershipReport",
  //   title: "Membership Report",
  //   moduleName: "plans",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [],
  // },
  // {
  //   path: "/staff/addmembershipReport",
  //   title: "Add Membership Report",
  //   moduleName: "plans",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [],
  // },
  // {
  //   path: "",
  //   title: "Enquiry",
  //   moduleName: "enquiry",
  //   iconType: "material-icons-two-tone",
  //   icon: "live_help",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["supervisor", "executive", "manager"],
  //   submenu: [
  //     {
  //       path: "/staff/addenquiries",
  //       title: "Add Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["supervisor", "executive", "manager"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/enquiries",
  //       title: "All Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["supervisor", "executive", "manager"],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/closed-enquiries",
  //       title: "Closed Enquiries",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: ["supervisor", "executive", "manager"],
  //       submenu: [],
  //     }
  //   ],
  // },
  {
    path: "",
    title: "Inventory",
    moduleName: "inventory",
    iconType: "icon-book-open",
    icon: "local_convenience_store",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/inventory/addorder",
        title: "Add Order",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/inventory/vieworder",
        title: "View Order",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/inventory/addproduct",
        title: "Add Product",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/listproduct",
      //   title: "List Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/staff/inventory/viewproduct",
        title: "View Product",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/inventory/listproduct",
      //   title: "List Product",
      //   moduleName: "inventory",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/staff/inventory/addsupplier",
        title: "Add Supplier",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/inventory/viewsupplier",
        title: "View Supplier",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Voucher ",
    moduleName: "voucher",
    iconType: "icon-tag",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/voucher/addvoucher",
        title: "Add Voucher",
        moduleName: "voucher",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/voucher/viewvoucher",
        title: "View Voucher",
        moduleName: "voucher",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Rating-Review",
    moduleName: "RatingAndReviewModule",
    iconType: "view-rating-icon",
    icon: "face",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/rating-review/view-rating-review",
        title: "View-Rating-Review",
        moduleName: "RatingAndReviewModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: staffRoles,
        submenu: [],
      },
    ],
  },
  // new feedback
  {
    path: "feedback",
    title: "Feedback",
    moduleName: "FeedbackModule",
    iconType: "fa-regular fa-comment-dots",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [],
  },
  // for media gallery admin
  {
    path: "/staff/gallerydrive/mediaImages",
    title: "Media",
    moduleName: "MediadriveModule",
    iconType: "icon-media-img",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [],
  },
  // for view my booking partner admin
  {
    path: "/staff/partnerappointments/partnerclientappointments",
    title: "Partner Client Bookings",
    moduleName: "PartnerClientAppointmentModule",
    iconType: "icon-mybooking-icon",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [],
  },
  // for  Subscription Plans create
  {
    path: "",
    title: "Subscription Plan",
    moduleName: "SubscriptionPlanModule",
    iconType: "plan-icons-admin",
    icon: "face",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/plansubsription/addsubscriptionplan",
        title: "Partner Subscription Plan",
        moduleName: "SubscriptionPlanModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: staffRoles,
        submenu: [],
      },
      {
        path: "/staff/memberplansubsriptions/membersubscriptionplan",
        title: "Client Subscription Plan",
        moduleName: "MemberSubscriptionPlanModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: staffRoles,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Credit Coins ",
    moduleName: "credit-coins",
    iconType: "add-coins-icon",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/credit-coins/addcoin",
        title: "Add Coins",
        moduleName: "addcoin",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/credit-coins/viewcoins",
        title: "View Coins",
        moduleName: "viewcoins",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Analytics ",
    moduleName: "analytics",
    iconType: "icon-tag",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/analytics/viewanalytics",
        title: "View Analytics",
        moduleName: "Analytics",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Analytics ",
  //   moduleName: "analytics",
  //   iconType: "icon-tag",
  //   icon: "icon-tag",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/analytics/viewanalytics",
  //       title: "View Analytics",
  //       moduleName: "Analytics",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  {
    path: "",
    title: "Sales ",
    moduleName: "sales",
    iconType: "icon-tag",
    icon: "icon-tag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/sales/saleview",
        title: "view sales",
        moduleName: "Sales",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Package Plans",
    moduleName: "package",
    iconType: "icon-present",
    icon: "local_convenience_store",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/package/addpackage",
        title: "Add Package",
        moduleName: "package",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/package/viewpackage",
        title: "View Package",
        moduleName: "package",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Voucher",
  //   moduleName: "voucher",
  //   iconType: "material-icons-two-tone",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/voucher/addvoucher",
  //       title: "Add Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/voucher/viewvoucher",
  //       title: "View Voucher",
  //       moduleName: "voucher",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ]
  // },
  // client For admin
  // {
  //   path: "",
  //   title: "Client",
  //   moduleName: "ClientModule",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: staffRoles
  //   submenu: [
  //     {
  //       path: "/staff/client/addClient",
  //       title: "Add Client",
  //       moduleName: "ClientModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/client/viewClient",
  //       title: " View Client ",
  //       moduleName: "ClientModule",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/partner/patients/patient-profile",
  //     //   title: "Member Profile",
  //     //   moduleName: "patients",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  {
    path: "",
    title: "Client",
    moduleName: "ClientModule",
    iconType: "icon-user-follow",
    icon: "icon-user-follow",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/client/addClient",
        title: "Add Client",
        moduleName: "ClientModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/client/viewClient",
        title: " View Client ",
        moduleName: "ClientModule",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/partner/patients/patient-profile",
      //   title: "Member Profile",
      //   moduleName: "patients",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Membership Plans",
  //   moduleName: "Plan",
  //   iconType: "icon-lock",
  //   icon: "local_convenience_store",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     // {
  //     //   path: "/admin/plan/create-plan",
  //     //   title: "Create Plan",
  //     //   moduleName: "plan",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     {
  //       path: "/admin/plan/all-plan",
  //       title: "All Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/add-plan",
  //       title: "Add Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/patients/myplan",
  //       title: "View Member Plan",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/membershipReports",
  //       title: "Membership Reports",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/addMembershipReportData",
  //       title: "Add MembershipReport Data",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/plan/applyCouponForMember",
  //       title: "Buy Plan",
  //       moduleName: "plan",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/staff/staff-profile",
  //     //   title: "Staff Profile",
  //     //   moduleName: "staff",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Members",
  //   moduleName: "patients",
  //   iconType: "icon-symbol-male",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/patients/all-patients",
  //       title: "All Members",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/patients/add-patient",
  //       title: "Add Member",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/patients/edit-patient",
  //     //   title: "Edit Member",
  //     //   moduleName: "patients",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     // {
  //     //   path: "/admin/patients/patient-profile",
  //     //   title: "Member Profile",
  //     //   moduleName: "patients",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Medical Report Upload",
  //   moduleName: "medical-report",
  //   iconType: "icon-note",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     // {
  //     //   path: "/admin/medical-report/view-report",
  //     //   title: "View Upload",
  //     //   moduleName: "medical-report",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //     {
  //       path: "/admin/medical-report/upload-report",
  //       title: "Upload Report",
  //       moduleName: "medical-report",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     // {
  //     //   path: "/admin/requests/edit-request",
  //     //   title: "Edit Request",
  //     //   moduleName: "Requests",
  //     //   iconType: "",
  //     //   icon: "",
  //     //   class: "ml-menu",
  //     //   groupTitle: false,
  //     //   badge: "",
  //     //   badgeClass: "",
  //     //   role: [""],
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Appointments",
  //   moduleName: "appointment",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/appointment/viewAppointment",
  //       title: "View Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/appointment/bookAppointment",
  //       title: "Book Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/appointment/edit-ppointment",
  //       title: "Edit Appointment",
  //       moduleName: "appointment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "Service Product",
    moduleName: "product",
    iconType: "icon-rocket",
    icon: "people_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/product/addProduct",
        title: "Add Product",
        moduleName: "addproduct",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/product/getProduct",
        title: "View Product",
        moduleName: "getproduct",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // booking Service
  // {
  //   path: "",
  //   title: "Book Service",
  //   moduleName: "bookingservice",
  //   iconType: "icon-link",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/bookingservice/bookservice",
  //       title: "Add BookService",
  //       moduleName: "bookservice",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  //end Booking Service
  {
    path: "/staff/calenderBooking/addAppointment",
    title: "Calender Booking",
    moduleName: "bookcalender",
    iconType: "icon-calender",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      // {
      //   path: "/staff/calenderBooking/addAppointment",
      //   title: "Add Appointment",
      //   moduleName: "bookcalender",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  {
    path: "",
    title: "Partner",
    moduleName: "doctors",
    iconType: "icon-support",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/partners/allPartners",
        title: "All Partner",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/partners/add-partner",
        title: "Add Partner",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/doctors/edit-doctor",
      //   title: "Edit Partner",
      //   moduleName: "doctors",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/doctors/doctor-profile",
      //   title: "Partner Profile",
      //   moduleName: "doctors",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  {
    path: "",
    title: "Partner Staff",
    moduleName: "staff",
    iconType: "icon-user-following",
    icon: "people_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/staff/all-staff",
        title: "View Staff",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/staff/add-staff",
        title: "Add Staff",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/staff/assignstaff",
      //   title: "Assign Pincode",
      //   moduleName: "staff",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/staff/staff-profile",
      //   title: "Staff Profile",
      //   moduleName: "staff",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  // {
  //   path: "",
  //   title: "Admin Staff",
  //   moduleName: "adminstaff",
  //   iconType: "icon-user-following",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: staffRoles
  //   submenu: [
  //     {
  //       path: "/satff/adminstaff/addstaff",
  //       title: "Add Staff",
  //       moduleName: "adminstaff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/staff/adminstaff/viewstaff",
  //       title: "View Staff",
  //       moduleName: "adminstaff",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Room Allotment",
  //   moduleName: "room",
  //   iconType: "material-icons-two-tone",
  //   icon: "hotel",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/room/all-rooms",
  //       title: "Alloted Rooms",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/room/add-allotment",
  //       title: "New Allotment",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/room/edit-allotment",
  //       title: "Edit Allotment",
  //       moduleName: "room",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Payment",
  //   moduleName: "payment",
  //   iconType: "material-icons-two-tone",
  //   icon: "monetization_on",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/payment/all-payment",
  //       title: "All Payment",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/payment/add-payment",
  //       title: "Add Payment",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/payment/invoice",
  //       title: "Invoice",
  //       moduleName: "payment",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "Services",
    moduleName: "services",
    iconType: "icon-energy",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/services/all-services",
        title: "View Services",
        moduleName: "services",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/services/add-services",
        title: "Add Service",
        moduleName: "services",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/services/upload-prices",
      //   title: "Upload Prices",
      //   moduleName: "services",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/services/edit-services",
      //   title: "Edit Services",
      //   moduleName: "Services",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },
  {
    path: "",
    title: "Offer",
    moduleName: "coupon",
    iconType: "icon-layers",
    icon: "send",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      // {
      //   path: "/admin/coupon/addcoupon",
      //   title: "Add Coupon",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/coupon/viewcoupon",
      //   title: "View Coupon",
      //   moduleName: "coupon",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/staff/offer/addoffers",
        title: "Add Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/offer/viewoffers",
        title: "View Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/offer/partnerOffers",
        title: "Partner Offers",
        moduleName: "coupon",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Career",
  //   moduleName: "career",
  //   iconType: "icon-trophy",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/career/addcareer",
  //       title: "Add Career",
  //       moduleName: "career",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/career/viewcareer",
  //       title: "View Career",
  //       moduleName: "career",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Enquiry",
  //   moduleName: "enquiry",
  //   iconType: "icon-call-in",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/enquiry/addenquiry",
  //       title: "Add Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/enquiry/getenquiry",
  //       title: "View Enquiry",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/enquiry/closed-enquiries",
  //       title: "Closed Enquiries",
  //       moduleName: "enquiry",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // BLog Modules
  // {
  //   path: "",
  //   title: "Blog",
  //   moduleName: "blog",
  //   iconType: "icon-people",
  //   icon: "work",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["admin"],
  //   submenu: [
  //     {
  //       path: "/admin/blog/add-blog",
  //       title: "Add Blog",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/blog/view-blogs",
  //       title: "View Blogs",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //     {
  //       path: "/admin/blog/add-new-blog",
  //       title: "Add New Blog",
  //       moduleName: "blog",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "",
    title: "SetUp",
    moduleName: "setup",
    iconType: "icon-trophy",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/setup/partnerComission",
        title: "Partner Comission",
        moduleName: "setup",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/setup/enquirylist",
        title: "Enquiry List",
        moduleName: "setup",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // BLog Modules
  {
    path: "",
    title: "Blog",
    moduleName: "blog",
    iconType: "icon-people",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/blog/add-new-blog",
        title: "Add New Blog",
        moduleName: "blog",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/blog/view-blogs",
        title: "View Blogs",
        moduleName: "blog",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  //Setting Modules
  {
    path: "",
    title: "Settings",
    moduleName: "notifications",
    iconType: "icon-settings",
    icon: "work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: staffRoles,
    submenu: [
      {
        path: "/staff/setting/sendnotification",
        title: "Send Notification",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/setting/sendnotificationlist",
        title: "Notification List",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/setting/viewreports",
        title: "View Reports",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/staff/setting/viewstatusreports",
        title: "View Status Reports",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
];
