import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Calendar } from './calendar.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CalendarService {
    private readonly API_URL = 'assets/data/calendar.json';
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    dataChange: BehaviorSubject<Calendar[]> = new BehaviorSubject<Calendar[]>(
        []
    );
    // Temporarily stores data from dialogs
    dialogData: any;
    constructor(private httpClient: HttpClient) { }
    get data(): Calendar[] {
        return this.dataChange.value;
    }
    getDialogData() {
        return this.dialogData;
    }
    getAllCalendars(): Observable<Calendar[]> {
        return this.httpClient.get<Calendar[]>(this.API_URL)
            .pipe(
                catchError(this.errorHandler)
            )
    }

    addUpdateCalendar(calendar: Calendar): void {
        this.dialogData = calendar;
    }
    deleteCalendar(calendar: Calendar): void {
        this.dialogData = calendar;
    }
    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
